<template>
  <div class="orm-container orm-loginPage">
    <div class="orm-row center-xs orm-mt-64">
      <div class="orm-col-xs-12 orm-relative">
        <div class="orm-login-widget text-left">
          <div class="orm-reservationHeader__logo orm-pt-0">
            <router-link
              v-if="env.VUE_APP_STANDALONE == 'true'"
              :to="{ name: 'Home' }"
              title="Home"
            >
              <div
                class="orm-reservationHeader__logo_image"
                :style="{
                  backgroundImage: 'url(' + logo + ')',
                  height: logoheight + 'px',
                }"
              ></div>
            </router-link>
            <a v-else :href="env.VUE_APP_BASE_URL"
              ><div
                class="orm-reservationHeader__logo_image"
                :style="{
                  backgroundImage: 'url(' + logo + ')',
                  height: logoheight + 'px',
                }"
              ></div
            ></a>
          </div>
          <p class="orm-login-headline">
            {{ i18n("customer/login/activate") || "Aktivierung" }}
          </p>
          <div v-if="showSuccess">
            <p>
              {{
                i18n("customer/activate/success") ||
                  "Account wurde Erfolgreich aktiviert"
              }}
            </p>

            <button
              class="orm-btn orm-login-widget-btnlistener"
              @click="submit('Home')"
            >
              {{ i18n("error/default/buttonFirstLabel") }}
            </button>
          </div>

          <div v-else-if="error">
            <p>
              {{
                i18n("customer/activate/error") ||
                  "Der Token ist abgelaufen. Aktivierung bereits durchgeführt oder Fehlerhaft."
              }}
            </p>

            <button
              class="orm-btn orm-login-widget-btnlistener"
              @click="submit('Home')"
            >
              {{ i18n("error/default/buttonFirstLabel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import stripHTML from "@/mixins/mixins";
import i18n from "@/mixins/mixins";
export default {
  name: "Activation",
  components: {},
  mixins: [i18n, stripHTML],
  setup() {
    const logoheight = process.env.VUE_APP_CUSTOMER_LOGO_HEIGHT_RESERVATION;
    const clientdata = store.getters["general/GET_CLIENT_DATA"];
    const env = process.env;
    let logo;
    // eslint-disable-next-line
    if (clientdata["reservationheader-logo"]) {
      if (clientdata["reservationheader-logo"].search("http") != "-1") {
        // eslint-disable-next-line
        logo = clientdata["reservationheader-logo"];
      } else {
        // eslint-disable-next-line
        logo =
          process.env.VUE_APP_BASE_URL + clientdata["reservationheader-logo"];
      }
    }
    return { logo, logoheight, env };
  },
  data() {
    return {
      showSuccess: false,
      error: false,
    };
  },
  async mounted() {
    await store
      .dispatch("customer/ACTIVATE", this.$route.params)
      .then((result) => {
        if (result) {
          this.showSuccess = true;
        } else {
          this.error = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    async getLoginState() {
      const isLoggedin = await store.getters["userSession/IS_LOGGEDIN"];
      if (!isLoggedin) {
        if (process.env.VUE_APP_STANDALONE == "true") {
          this.$router.push({ name: "Home" });
        } else {
          window.location.href = process.env.VUE_APP_BASE_URL;
        }
      }
    },
    submit(route) {
      if (process.env.VUE_APP_STANDALONE == "true") {
        this.$router.push({ name: route });
      } else {
        window.location.href = process.env.VUE_APP_BASE_URL;
      }
    },
  },
};
</script>
