<template>
  <Header />
  <SearchBar origin="reservation" />
  <Footer />
</template>

<script>
import { defineComponent } from "vue";

// import LocationMap from "@/components/widgets/LocationMap.vue"; // @ is an alias to /src
import SearchBar from "@/components/widgets/SearchBar.vue"; // @ is an alias to /src
import Footer from "@/components/widgets/Footer.vue"; // @ is an alias to /src
import Header from "@/components/widgets/Header.vue"; // @ is an alias to /src

export default defineComponent({
  name: "Reservation",
  components: {
    SearchBar,
    Footer,
    Header,
  },
});
</script>
