<template>
  <teleport to="body" v-if="!showContact">
    <div class="orm-html orm-contactmodal__bg">
      <div class="orm-contactmodal">
        <div class="orm-contactmodal__header" ref="resDheader">
          <div class="orm-container">
            <div class="orm-row">
              <div class="orm-col-xs-7 orm-col-sm-2">
                <div class="orm-contactmodal-user">
                  <p class="orm-bookinglist-title">
                    {{ i18n("customer/booking_list/bookingnr") }}
                  </p>
                  {{ reservation.id }}
                </div>
              </div>

              <div class="orm-col-xs-5 orm-col-sm-10 text-right">
                <DownloadLinks
                  @openContact="openContact()"
                  class="hide-md-down"
                />
                <button
                  class="orm-btn--icon orm-login-widget-btnlistener"
                  @click="closeModal()"
                >
                  <i class="fa fa-times"></i> {{ i18n("general/close") }}
                </button>
              </div>
              <div class="orm-col-xs-12 orm-mt-16 show-md-down">
                <DownloadLinks @openContact="openContact()" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="overflowAuto orm-py-16 orm-px-16"
          :style="{ height: calcHeight + 'px' }"
        >
          <ReservationView
            :reservation="reservation"
            v-if="reservation.status === 'reservation'"
            @openContact="openContact()"
          />
          <ActualView
            :reservation="reservation"
            v-if="reservation.status === 'ci_made'"
            @openContact="openContact()"
          />
          <DoneView
            :reservation="reservation"
            v-if="reservation.status === 'contract_co'"
            @openContact="openContact()"
          />
        </div>
        <div class="orm-col-xs-12 orm-contactmodal__footer" ref="resDfooter">
          <div class="text-right orm-py-16 orm-px-16">
            <p class="orm-pricetag">
              {{ i18n("customer/booking_list/price_total") }}:
              <em>{{
                convertCurrency(
                  reservation.invoice_positions.endPositions.find(
                    (x) => x.rate === "ES."
                  ).total_long_price
                )
              }}</em>
            </p>
            <small>
              {{
                reservation.invoice_positions.endPositions.find(
                  (x) => x.rate === "MWST"
                ).description
              }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <Contact
    v-if="showContact && reservation"
    :type="reservation.status"
    :reservation="reservation"
    @dismiss="dismiss()"
  />
</template>

<script>
import { defineComponent } from "vue";
import i18n from "@/mixins/mixins";
import store from "@/store";

import ReservationView from "@/components/modals/reservation_details/reservation.vue"; // @ is an alias to /src
import ActualView from "@/components/modals/reservation_details/ci_made.vue"; // @ is an alias to /src
import DoneView from "@/components/modals/reservation_details/contract_co.vue"; // @ is an alias to /src
import DownloadLinks from "@/components/modals/reservation_details/download-links.vue"; // @ is an alias to /src

import Contact from "@/components/modals/modal-contact.vue";

import { convertCurrency } from "@/constants/formater";
import stripHTML from "@/mixins/mixins";

export default defineComponent({
  name: "modal-contact",
  emits: ["dismiss"],
  components: { ReservationView, ActualView, DoneView, DownloadLinks, Contact },
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
  mixins: [i18n, stripHTML],
  setup() {
    const clientdata = store.getters["general/GET_CLIENT_DATA"];

    return {
      clientdata,
    };
  },
  data() {
    return {
      env: process.env,
      convertCurrency,
      showContact: false,
      calcHeight: 0,
    };
  },
  async mounted() {
    document.body.style.overflow = "hidden";
    console.log(this.reservation);
    this.resize();
    window.addEventListener("resize", this.resize());
  },
  unmounted() {
    document.body.style.overflow = "auto";
  },
  methods: {
    closeModal() {
      this.$emit("dismiss");
    },
    openContact() {
      this.showContact = true;
    },
    resize() {
      this.calcHeight =
        window.innerHeight -
        this.$refs.resDheader.offsetHeight -
        this.$refs.resDfooter.offsetHeight -
        32;
    },
    dismiss() {
      this.showContact = !this.showContact;
    },
  },
});
</script>
