<template>
  <div class="orm-container">
    <div class="orm-row">
      <h1 class="orm-headline__h1">
        {{ i18n("customer/reservationtype/" + reservation.status) }}
      </h1>
    </div>
    <div class="orm-row">
      <div class="orm-col-xs-12 orm-col-lg-6 orm-mb-16">
        <div class="orm-row orm-mr-md-16">
          <div class="orm-col-xs-12">
            <h3 class="orm-headline__h3 orm-headline__h3__modal">
              {{ i18n("customer/booking_list/vehicle") }}
            </h3>
          </div>
          <div class="orm-col-xs-12 orm-col-sm-6">
            <Slider
              v-if="reservation.planned_group.images"
              :images="reservation.planned_group.images"
            />
            <div class="orm-carequip__centered">
              <div class="orm-carequip">
                <p
                  class="orm-equip__txt__icons orm-mt-8"
                  v-if="reservation.planned_group.features"
                >
                  <EquipIcon :data="reservation.planned_group.features" />
                </p>
              </div>
            </div>
          </div>
          <div class="orm-col-xs-12 orm-col-sm-6">
            <h2 class="orm-headline__h2">
              {{
                stripHTML(
                  reservation.planned_group[env.VUE_APP_RESERVATION_CARGROUP]
                )
              }}
            </h2>
            <p class="orm-headline__h3__subheadline orm-relative">
              {{
                stripHTML(
                  reservation.planned_group[env.VUE_APP_RESERVATION_CARCATEGORY]
                )
              }}
            </p>
            <p class="orm-mb-8">
              {{ i18n("customer/booking_list/license") }}:
              <span class="orm-license_plate">{{
                reservation.vehicle.license_plate
              }}</span>
            </p>
            <p class="orm-mt-0">
              {{ i18n("customer/booking_list/sprit") }}:
              <strong>{{ reservation.vehicle.fuel_type }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="orm-col-xs-12 orm-col-lg-6">
        <div class="orm-border-left-bookings">
          <div class="orm-row">
            <div class="orm-col-xs-12">
              <h3 class="orm-headline__h3 orm-headline__h3__modal">
                {{ i18n("customer/booking_list/datetime") }}
              </h3>
              <DateTimeTable :reservation="reservation" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="orm-row">
      <div class="orm-col-xs-12 orm-col-lg-6">
        <Tarif :reservation="reservation" />
      </div>
      <div class="orm-col-xs-12 orm-col-lg-6">
        <div class="orm-border-left-bookings">
          <div class="orm-row">
            <div class="orm-col-xs-12">
              <Insurance :reservation="reservation" />
            </div>
          </div>
          <div class="orm-row">
            <div class="orm-col-xs-6">
              <p class="orm-mt-0">
                {{ i18n("customer/booking_list/detail/contact_text") }}
              </p>
            </div>
            <div class="orm-col-xs-6">
              <button
                class="orm-button__cta outline small"
                @click="openContact()"
              >
                {{ i18n("customer/booking_list/detail/button_contact") }}
              </button>
              <p>
                <i class="far fa-phone"></i>
                {{
                  reservation.startstation.phone ||
                    i18n("customer/booking_list/detail/contact_number")
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import DateTimeTable from "@/components/modals/reservation_details/datetimetable.vue";
import EquipIcon from "@/components/reservation/equip-icon.vue";
import Slider from "@/components/ui/image-slider.vue";
import Tarif from "@/components/modals/reservation_details/tarif.vue";
import Insurance from "@/components/modals/reservation_details/insurance.vue";
import stripHTML from "@/mixins/mixins";
import i18n from "@/mixins/mixins";
export default defineComponent({
  name: "contract_co",
  mixins: [i18n, stripHTML],
  components: { EquipIcon, Slider, DateTimeTable, Tarif, Insurance },
  emits: ["openContact"],
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      env: process.env,
    };
  },
  methods: {
    openContact() {
      this.$emit("openContact");
    },
  },
});
</script>
