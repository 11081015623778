<template>
  <h3 class="orm-headline__h3">
    {{
      new Date(reservation.startdate).toLocaleDateString("de-DE", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })
    }}
    -
    {{
      new Date(reservation.planned_enddate).toLocaleDateString("de-DE", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })
    }}
  </h3>
  <div class="orm-row">
    <div class="orm-col-xs-12 orm-col-sm-6 orm-mb-16">
      <div class="">
        <div
          class="orm-bookinglist-title orm-bookinglist-title--pickupdelevery"
        >
          <strong v-if="reservation.delivery_street">{{
            i18n("step2/deliverypickup/delivery")
          }}</strong>
          <strong v-else>{{ i18n("general/pickup") }}</strong>
        </div>
        <div class="orm-reservationFinal__flex">
          <span class="orm-pr-16"><i class="fal fa-clock"></i></span>
          <div>
            <strong>
              {{ formatTime(reservation.startdate) }}
              {{ i18n("general/hour") }}</strong
            >
          </div>
        </div>
        <div class="orm-reservationFinal__flex orm-mb-8">
          <span class="orm-pr-16" style="width:34px"
            ><i class="fas fa-map-marker-alt"></i
          ></span>
          <div>
            <div>
              <strong v-if="reservation.delivery_street">
                {{ reservation.delivery_street }}</strong
              >
              <strong v-else> {{ reservation.startstation.name }}</strong>
            </div>
            <small
              v-if="reservation.delivery_zipcode && reservation.delivery_city"
            >
              {{
                reservation.delivery_zipcode + " " + reservation.delivery_city
              }}</small
            >
            <small v-else> {{ reservation.startstation.street }}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="orm-col-xs-12 orm-col-sm-6  orm-mb-16">
      <div class="">
        <div
          class="orm-bookinglist-title orm-bookinglist-title--pickupdelevery"
        >
          <strong v-if="reservation.pickup_street">{{
            i18n("step2/deliverypickup/pickup")
          }}</strong>
          <strong v-else>{{ i18n("general/delivery") }}</strong>
        </div>
        <div class="orm-reservationFinal__flex">
          <span class="orm-pr-16"><i class="fal fa-clock"></i></span>
          <div>
            <strong>
              {{ formatTime(reservation.planned_enddate) }}
              {{ i18n("general/hour") }}</strong
            >
          </div>
        </div>
        <div class="orm-reservationFinal__flex orm-mb-8">
          <span class="orm-pr-16" style="width:34px"
            ><i class="fas fa-map-marker-alt"></i
          ></span>
          <div>
            <div>
              <strong v-if="reservation.pickup_street">
                {{ reservation.pickup_street }}</strong
              >
              <strong v-else> {{ reservation.planned_endstation.name }}</strong>
            </div>
            <small v-if="reservation.pickup_zipcode && reservation.pickup_city">
              {{
                reservation.pickup_zipcode + " " + reservation.pickup_city
              }}</small
            >
            <small v-else> {{ reservation.planned_endstation.street }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { formateDateShort, formatTime } from "@/constants/formater";
import stripHTML from "@/mixins/mixins";
import i18n from "@/mixins/mixins";

export default defineComponent({
  mixins: [i18n, stripHTML],
  setup() {
    return {
      formateDateShort,
      formatTime,
    };
  },
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
});
</script>
