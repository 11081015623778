import slugify from "slugify";
import store from "@/store";

const monthNames = [
  "Jan",
  "Feb",
  "Mär",
  "Apr",
  "Mai",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Okt",
  "Nov",
  "Dez",
];

const weekday = new Array(7);
weekday[0] = "So";
weekday[1] = "Mo";
weekday[2] = "Di";
weekday[3] = "Mi";
weekday[4] = "Do";
weekday[5] = "Fr";
weekday[6] = "Sa";

const formateDatetime = (datetime) => {
  const date = new Date(datetime);
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = date.getFullYear();
  const hh = date.getHours();
  const ii = String(date.getMinutes()).padStart(2, "0");
  const s = String(date.getSeconds()).padStart(2, "0");
  return yyyy + "-" + mm + "-" + dd + "T" + hh + ":" + ii + ":" + s;
  // 2020-11-28T08:00:00
};

const formateDate = (dateInput) => {
  const date = new Date(dateInput);

  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = date.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

const formateDateShort = (date) => {
  if (date == null) {
    return " please select";
  }
  const newdate = new Date(date);
  const DD = weekday[newdate.getDay()];
  const dd = String(newdate.getDate()).padStart(2, "0");
  return DD + ", " + dd + ". " + monthNames[newdate.getMonth()];
};

const formatDayOfWeek = (date) => {
  const newdate = new Date(date);
  return newdate.getDay();
};

const addZero = (i) => {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
};

const formatHour = (date) => {
  const hour = addZero(date.getHours());
  const minute = addZero(date.getMinutes());
  return hour + ":" + minute;
};
const formatTime = (date) => {
  const newdate = new Date(date);

  const hour = addZero(newdate.getUTCHours());
  const minute = addZero(newdate.getUTCMinutes());
  return hour + ":" + minute;
};

const formateTimeArray = (time) => {
  const newTime = new Date(time);
  const dateArray = [];
  dateArray.push(addZero(newTime.getHours()));
  dateArray.push(addZero(newTime.getMinutes()));
  return dateArray.join(":");
};

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};
const getDistanceFromLatLonInKm = (lat, lng, posUser) => {
  // console.log(posUser);
  const lat1 = lat;
  const lng1 = lng;
  const lat2 = posUser.lat;
  const lng2 = posUser.lng;

  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLng = deg2rad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return Math.round((d + Number.EPSILON) * 100) / 100;
};

const slugMe = (string) => {
  return slugify(string, {
    replacement: "-", // replace spaces with replacement character, defaults to `-`
    remove: undefined, // remove characters that match regex, defaults to `undefined`
    lower: true, // convert to lower case, defaults to `false`
    strict: false, // strip special characters except replacement, defaults to `false`
    locale: "de", // language code of the locale to use
  });
};

const convertCurrency = (price) => {
  let locale = store.getters["general/GET_LANGUAGE"];
  const lang = {
    DEFAULT: "de-DE",
    DE: "de-DE",
    JP: "ja-JP",
    US: "en-US",
    FR: "fr-FR",
    CA: "fr-CA",
    CH: "fr-CH",
    GB: "en-GB",
  };

  const currencies = {
    DEFAULT: "EUR",
    DE: "EUR",
    JP: "JPY",
    US: "USD",
    FR: "EUR",
    CA: "CAD",
    CH: "CHF",
    GB: "GBP",
  };
  if (lang[locale] == undefined) locale = "DE";
  const priceInt = parseFloat(price);

  return priceInt.toLocaleString(lang[locale], {
    style: "currency",
    currency: currencies[locale],
  });
};

const i18n = (id) => {
  return store.getters["general/GET_TRANSLATION"](id);
};

const concatDateTimeZone = (date, time) => {
  let startTimeDate = "";
  let endTimeDate = "";
  let st = "";
  const timeNow = formatHour(new Date());

  if (date.start) {
    if (time.start) {
      st = time.start + ":00";
    } else {
      st = timeNow + ":00";
    }
    startTimeDate = date.start + "T" + st;
  } else {
    startTimeDate = formateDatetime(Date.now());
  }
  if (date.end) {
    // console.log('time', time.end);
    if (time.end) {
      st = time.end + ":00";
    } else {
      st = timeNow + ":00";
    }
    endTimeDate = date.end + "T" + st;
  } else {
    endTimeDate = formateDatetime(Date.now());
  }
  return [startTimeDate, endTimeDate];
};

export {
  formateDate,
  formateDatetime,
  formatDayOfWeek,
  formateTimeArray,
  addZero,
  formateDateShort,
  formatHour,
  formatTime,
  getDistanceFromLatLonInKm,
  slugMe,
  convertCurrency,
  i18n,
  concatDateTimeZone,
};
