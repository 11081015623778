<template>
  <span class="orm-bookings__dwn_links" @click="openContact()">
    <i class="fal fa-phone-office"></i> {{ i18n("contact/title") }}
  </span>
</template>
<script>
// import { defineComponent } from "vue";
import i18n from "@/mixins/mixins";
export default {
  name: "Dwonloadlinks",
  mixins: [i18n],
  emits: ["openContact"],
  methods: {
    openContact() {
      this.$emit("openContact");
    },
  },
};
</script>
