<template>
  <teleport v-if="widget" :to="'#' + dest">
    <div class="orm-carcategoryslider orm-container orm-py-56">
      <div class="orm-row orm-mb-32">
        <div class="orm-col-xs-12 text-center">
          <h2
            class="orm-headline__h1"
            v-html="i18n('modules/carcategory/title')"
          ></h2>
        </div>
      </div>
      <Slider
        v-if="carGroup"
        :carcategory="carGroup"
        :show-category="showCategory"
        :sttype="sttype"
      />

      <div v-else>
        <div class="orm-preload-page orm-preload-page-static">
          <i class="fa fa-spinner-third orm-rotate360"></i>
        </div>
      </div></div
  ></teleport>
</template>

<script>
import { defineComponent, toRef } from "vue";
import store from "@/store";
import Slider from "@/components/ui/slider/carcategory-slider.vue";
import i18n from "@/mixins/mixins";
import stripHTML from "@/mixins/mixins";

export default defineComponent({
  name: "CarCategorySlider",
  components: { Slider },
  mixins: [i18n, stripHTML],
  setup(props) {
    const dest = toRef(props, "teleport").value;
    let widget = false;
    const myEle = document.getElementById(dest);
    if (myEle !== null) {
      widget = true;
    }

    return {
      widget,
      dest,
      // offers,
    };
  },
  data() {
    return {
      carGroup: null,
      offers: null,
      demo: process.env.VUE_APP_DEMO,
      carcategory: [],
      sttype: "ALL",
    };
  },
  props: {
    type: {
      type: Number,
      required: false,
      default: 1,
    },
    showCategory: {
      type: Boolean,
      default: false,
    },
    teleport: {
      type: String,
      default: "crentCarCategorySlider",
    },
    sort: {
      type: String,
      default: "none",
    },
  },
  async created() {
    const carcategoryslider = document.getElementById(this.teleport);
    if (carcategoryslider == null) {
      return;
    }

    this.sttype = document.getElementById(
      "crentReservation"
    ).dataset.stationtype;
    await store.dispatch("cargroup/SET_CARGROUPS_ALL");
    const cars = await store.getters["cargroup/GET_GLOBAL_CARGROUPS"];
    const stationsFilter = await store.getters[
      "stations/GET_STATIONS_FILTERED"
    ];

    // console.log(cars);
    this.carGroup = JSON.parse(JSON.stringify(cars));
    /// FILTER CAR CATEGORIES - SHOW ONLY CATEGROIES NOT ALL CARGROUPS
    if (this.showCategory) {
      this.carGroup.forEach((element) => {
        element["vehicle_category"].forEach((vehiclecategory) => {
          if (!this.search(vehiclecategory.id, this.carcategory)) {
            const enabled = element.enabled_stations;
            for (const key in enabled) {
              if (Object.hasOwnProperty.call(enabled, key)) {
                const enabledkey = enabled[key];
                const check = stationsFilter[this.sttype].find(
                  (x) => x.id == enabledkey
                );
                if (check) {
                  const newElement = JSON.parse(JSON.stringify(element));
                  newElement["category"] = vehiclecategory.id;
                  this.carcategory.push(newElement);
                  return;
                }
              }
            }
          }
        });
      });

      this.carGroup = this.carcategory;
    }

    this.carGroup.forEach((element) => {
      element["text_web"] = this.stripHTML(element["text_web"]).trim();
    });
    // console.log("this.carGroup", this.carGroup);
    const myEle = document.getElementById(this.teleport);
    let sortOption = this.sort;
    if (myEle !== null) {
      sortOption = myEle.dataset.sort;
    }

    switch (sortOption) {
      case "random":
        this.shuffle(this.carGroup);
        break;
      case "asc":
        this.ascsort(this.carGroup);
        break;
      default:
        break;
    }
  },
  methods: {
    ascsort(cars) {
      if (this.showCategory) {
        cars.sort(function(a, b) {
          if (a.category < b.category) {
            return -1;
          }
          if (a.category > b.category) {
            return 1;
          }
          return 0;
        });
      } else {
        cars.sort(function(a, b) {
          if (a.text_web < b.text_web) {
            return -1;
          }
          if (a.text_web > b.text_web) {
            return 1;
          }
          return 0;
        });
      }
    },
    shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    },
    search(nameKey, myArray) {
      for (let i = 0; i < myArray.length; i++) {
        if (myArray[i].category === nameKey) {
          return myArray[i];
        }
      }
    },
  },
});
</script>
