<template
  ><teleport v-if="widget" to="#crentReservationHeader">
    <div class="orm-container-fluid orm-reservationHeader">
      <div class="orm-container">
        <div class="orm-row top-xs middle-sm">
          <div
            class="orm-col-xs-8 orm-col-md-3 first-xs"
            v-if="showLogo === 'true'"
          >
            <div class="orm-reservationHeader__logo">
              <router-link
                v-if="env.VUE_APP_STANDALONE == 'true'"
                :to="{ name: 'Home' }"
                title="Home"
              >
                <div
                  class="orm-reservationHeader__logo_image"
                  :style="{
                    backgroundImage: 'url(' + logo + ')',
                    height: logoheight + 'px',
                  }"
                ></div>
              </router-link>
              <a v-else :href="env.VUE_APP_BASE_URL"
                ><div
                  class="orm-reservationHeader__logo_image"
                  :style="{
                    backgroundImage: 'url(' + logo + ')',
                    height: logoheight + 'px',
                  }"
                ></div
              ></a>
            </div>
          </div>
          <div
            class="orm-col-xs-12 orm-col-md-7 orm-pb-16 orm-pb-md-0"
            v-if="step !== 5"
          >
            <div class="orm-reservationHeader__indicator orm-flexcontainer">
              <div
                class="orm-indicator orm-indicator__step1"
                :class="{ active: step == 1, checked: step >= 2 }"
              >
                <router-link
                  v-if="step >= 2"
                  :to="{ name: 'Reservation_Step1' }"
                >
                  <p v-html="i18n('step1/title')"></p
                ></router-link>
                <p v-else v-html="i18n('step1/title')"></p>
              </div>
              <div
                class="orm-indicator orm-indicator__step2"
                :class="{ active: step == 2, checked: step >= 3 }"
              >
                <router-link
                  v-if="step >= 3"
                  :to="{ name: 'Reservation_Step2' }"
                >
                  <p v-html="i18n('step2/title')"></p
                ></router-link>
                <p v-else v-html="i18n('step2/title')"></p>
              </div>
              <div
                class="orm-indicator orm-indicator__step3"
                :class="{ active: step == 3, checked: step >= 4 }"
              >
                <router-link
                  v-if="step >= 4"
                  :to="{ name: 'Reservation_Step3' }"
                >
                  <p v-html="i18n('step3/title')"></p
                ></router-link>
                <p v-else v-html="i18n('step3/title')"></p>
              </div>
            </div>
          </div>
          <div
            v-if="store.getters['ORMgeneral/GET_MODULE']('ORM_LOGIN') === 'J'"
            class="orm-col-xs-4 first-xs last-md text-right middle-self-xs"
            :class="step !== 5 ? 'orm-col-md-2' : 'orm-col-md-9'"
          >
            <CustomerWidget :teleport="false" />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script>
import { defineComponent } from "vue";
import store from "@/store";
import i18n from "@/mixins/mixins";
import CustomerWidget from "@/components/widgets/CustomerWidget.vue";

export default defineComponent({
  name: "reservationheader",
  props: {
    step: Number,
  },
  mixins: [i18n],
  components: { CustomerWidget },
  setup() {
    let widget = false;
    const myEle = document.getElementById("crentReservationHeader");
    const logoheight = process.env.VUE_APP_CUSTOMER_LOGO_HEIGHT_RESERVATION;
    const env = process.env;

    let showLogo = true;
    if (myEle !== null) {
      widget = true;
      showLogo = myEle.dataset.logo;
    }

    const clientdata = store.getters["general/GET_CLIENT_DATA"];
    let logo;
    // eslint-disable-next-line
    if (clientdata["reservationheader-logo"]) {
      if (clientdata["reservationheader-logo"].search("http") != "-1") {
        // eslint-disable-next-line
        logo = clientdata["reservationheader-logo"];
      } else {
        // eslint-disable-next-line
        logo =
          process.env.VUE_APP_BASE_URL + clientdata["reservationheader-logo"];
      }
    }

    return {
      store,
      widget,
      logoheight,
      showLogo,
      env,
      logo,
    };
  },
});
</script>
