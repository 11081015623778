<template>
  <div v-click-outside="closeEvent">
    <div
      v-if="selectedHour"
      class="orm-timepicker__input"
      @click="openSelect()"
    >
      <span v-if="endtime">{{
        store.getters["userSession/GET_RESERVATIONTIME_END"]
      }}</span>
      <span v-else>{{
        store.getters["userSession/GET_RESERVATIONTIME_START"]
      }}</span>
      {{ i18n("general/hour") }} <i class="fal fa-clock"></i>
    </div>
    <div v-else class="orm-timepicker__input" @click="openSelect()">
      hh:mm {{ i18n("general/hour") }}
    </div>
    <div class="orm-timepicker__options" v-if="selectopen">
      <div
        class="orm-timpicker__up"
        @mousedown="moveUp()"
        @mouseup="moveUp('clear')"
        @touchstart="moveUp()"
        @touchend="moveUp('clear')"
      >
        <i class="fa fa-sort-up"></i>
      </div>
      <div class="orm-timepicker__options_inner">
        <div
          class="orm-timepicker__option"
          v-for="time in timesArray"
          :key="time"
          :selected="time == selectedHour"
          :class="[
            time == selectedHour ? 'orm-timepicker__option--selected' : '',
          ]"
          @click="selectTime(time)"
        >
          <div v-if="time == selectedHour"></div>

          {{ time }}
        </div>
      </div>
      <div
        class="orm-timpicker__down"
        @mousedown="moveDown()"
        @mouseup="moveDown('clear')"
        @touchstart="moveDown()"
        @touchend="moveDown('clear')"
      >
        <i class="fa fa-sort-down"></i>
      </div>
    </div>
    <span v-if="mapModule" class="mapModule__datetimepicker__icon"
      ><i class="fal fa-clock"></i
    ></span>
    <span v-if="infobar" class="infobar__datetimepicker__icon"
      ><i class="fal fa-clock"></i
    ></span>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import store from "@/store";
import i18n from "@/mixins/mixins";

export default defineComponent({
  name: "timepicker",
  props: {
    endtime: Boolean,
    selected: String,
    mapModule: Boolean,
    infobar: Boolean,
  },
  mixins: [i18n],
  setup() {
    return {
      store,
    };
  },
  data() {
    return {
      selectedHour: String,
      selectopen: false,
      scrollUp: false,
      scrollDown: false,
      timesArray: [],
    };
  },
  async mounted() {
    this.initial();
    store.watch(
      (state, getters) => getters["stations/GET_OPENINGTIMES"],
      () => {
        this.initial();
      }
    );
    // store.watch(
    //   (getters) => getters["userSession/GET_STATION"],
    //   () => {
    //     console.log("watch GET_STATION");
    //     this.initial();
    //   }
    // );
  },
  methods: {
    async initial() {
      const openeningTimes = await store.getters["stations/GET_OPENINGTIMES"];
      if (this.endtime == true) {
        this.timesArray = openeningTimes.end_time_config;
        this.selectedHour = await store.getters[
          "userSession/GET_RESERVATIONTIME_END"
        ];
      } else {
        this.timesArray = openeningTimes.start_time_config;
        this.selectedHour = await store.getters[
          "userSession/GET_RESERVATIONTIME_START"
        ];
      }
    },
    async selectTime(time) {
      const lasttime = this.selectedHour;
      if (this.endtime == false) {
        store.commit("userSession/MUTATE_TIME_START", time);
      } else {
        store.commit("userSession/MUTATE_TIME_END", time);
      }
      this.selectedHour = time;
      this.selectopen = false;
      const datestart = await store.getters[
        "userSession/GET_RESERVATIONDATE_START"
      ];
      const dateend = await store.getters[
        "userSession/GET_RESERVATIONDATE_End"
      ];
      const start = await store.getters[
        "userSession/GET_RESERVATIONTIME_START"
      ];

      const end = await store.getters["userSession/GET_RESERVATIONTIME_END"];
      if (
        start >= end ||
        (end <= start && datestart == dateend) ||
        lasttime > time
      ) {
        store.dispatch("stations/SET_OPENINGTIMES");
      }
    },
    openSelect() {
      this.selectopen = !this.selectopen;
      if (!this.selectopen) return;
      setTimeout(() => {
        const innerEL = this.$el.children[1].children[1];
        const el = document.querySelector(
          ".orm-timepicker__option[selected='true']"
        );
        if (el) innerEL.scrollTop = el.offsetTop - innerEL.offsetTop;
        // console.log('timepicker__option',el);
      }, 50);
    },
    moveUp(type) {
      if (type == "clear") {
        clearInterval(this.scrollUp);
        return;
      }
      if (!this.$el) return false;
      const el = this.$el.children[1].children[1];
      el.scrollTop -= 32;

      this.scrollUp = setInterval(() => {
        el.scrollTop -= 32;
      }, 150);
    },
    moveDown(type) {
      if (type == "clear") {
        clearInterval(this.scrollDown);
        return;
      }
      if (!this.$el) return false;
      const el = this.$el.children[1].children[1];
      el.scrollTop += 32;
      this.scrollDown = setInterval(() => {
        el.scrollTop += 32;
      }, 150);
    },
    closeEvent() {
      this.selectopen = false;
    },
  },
  watch: {
    selected: function() {
      this.initial();
    },
  },
});
</script>
