<template>
  <div
    class="orm-slider"
    :style="{ paddingTop: calcRatio(ratio) + '%' }"
    v-if="images.length > 0"
  >
    <transition-group name="fade" tag="div" class="orm-slider__inner">
      <!-- <div v-for="i in [currentIndex]" :key="i">
        <div
          class="orm-slider__slide"
          :style="{ backgroundImage: 'url(' + currentImg + ')' }"
        />
      </div> -->
      <div v-for="(item, index) in images" :key="item">
        <div
          class="orm-slider__slide orm-slider__slide--hide"
          :style="{ backgroundImage: 'url(' + encodeURI(item.image) + ')' }"
          :class="{ show: currentIndex == index }"
        />
      </div>
    </transition-group>
    <div class="orm-slider__controll" v-if="images.length > 1 && controlls">
      <div v-if="arrows">
        <div
          class="orm-slider__controll_arrows orm-slider__controll_arrows--left"
          @click="prev"
        >
          <i class="fa fa-chevron-circle-left"></i>
        </div>
        <div
          class="orm-slider__controll_arrows orm-slider__controll_arrows--right"
          @click="next"
        >
          <i class="fa fa-chevron-circle-right"></i>
        </div>
      </div>
      <div class="orm-slider__controll__dots" v-if="dots">
        <div
          v-for="(i, index) in images"
          :key="i"
          class="orm-slider__controll__dot"
          :class="{
            'orm-slider__controll__dot--active': currentIndex == index,
          }"
          @click="showItem(index)"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Slider",
  props: {
    images: Array,
    ratio: {
      type: String,
      required: false,
      default: "4/3",
    },
    controlls: {
      type: Boolean,
      required: false,
      default: true,
    },
    dots: {
      type: Boolean,
      required: false,
      default: true,
    },
    arrows: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: true,
    },
    intervall: {
      type: Number,
      required: false,
      default: 7000,
    },
    lightbox: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      timer: null,
      currentIndex: 0,
      items: Number,
    };
  },
  mounted() {
    this.items = this.images.length - 1;
    if (this.images.length > 1 && this.autoplay) this.startSlide();

    this.$el.addEventListener("touchstart", this.startTouch, false);
    this.$el.addEventListener("touchmove", this.moveTouch, false);
    this.$el.addEventListener("mousedown", this.startTouch, false);
    this.$el.addEventListener("mouseup", this.moveTouch, false);
  },

  unmounted() {
    this.$el.removeEventListener("touchstart", this.startTouch, false);
    this.$el.removeEventListener("touchmove", this.moveTouch, false);
    this.$el.removeEventListener("mousedown", this.startTouch, false);
    this.$el.removeEventListener("mouseup", this.moveTouch, false);
  },

  methods: {
    startSlide() {
      this.timer = setInterval(this.next, this.intervall);
    },

    next() {
      this.currentIndex += 1;
      if (this.currentIndex > this.items) this.currentIndex = 0;
    },
    prev() {
      this.currentIndex -= 1;
      if (this.currentIndex < 0) this.currentIndex = this.items;
    },
    showItem(index) {
      this.currentIndex = index;
      if (this.images.length > 1 && this.autoplay) {
        clearInterval(this.timer);
        this.timer = setInterval(this.next, this.intervall);
      }
    },
    calcRatio(ratio) {
      const splitRatio = ratio.split("/");
      const calc = (splitRatio[1] / splitRatio[0]) * 100;
      return calc.toFixed(2);
    },
    startTouch(e) {
      if (e.type == "mousedown") {
        this.initialX = e.clientX;
        this.initialY = e.clientY;
      } else {
        this.initialX = e.touches[0].clientX;
        this.initialY = e.touches[0].clientY;
      }
    },

    moveTouch(e) {
      if (this.initialX === null) {
        return;
      }

      if (this.initialY === null) {
        return;
      }
      let currentX = 0;
      let currentY = 0;
      if (e.type == "mouseup") {
        currentX = e.clientX;
        currentY = e.clientY;
      } else {
        currentX = e.touches[0].clientX;
        currentY = e.touches[0].clientY;
      }

      const diffX = this.initialX - currentX;
      const diffY = this.initialY - currentY;

      if (Math.abs(diffX) > Math.abs(diffY)) {
        // sliding horizontally
        if (diffX > 0) {
          this.next();
        } else {
          this.prev();
        }
      } else {
        // sliding vertically
        if (diffY > 0) {
          // swiped up
          // console.log("swiped up");
        } else {
          // swiped down
          // console.log("swiped down");
        }
      }

      this.initialX = null;
      this.initialY = null;

      e.preventDefault();
    },
  },

  computed: {
    currentImg: function() {
      return encodeURI(
        this.images[Math.abs(this.currentIndex) % this.images.length].image
      );
    },
  },
};
</script>
