<template>
  <teleport v-if="widget" :to="'#' + teleport">
    <div class="orm-offersslider">
      <Slider
        v-if="offers && type == 1"
        :images="offers"
        :autoplay="true"
        :intervall="10000"
        ratio="16/8"
        :arrows="false"
        :controlls-fade="false"
      />

      <Slider
        v-if="offers && type == 2"
        :images="offers"
        :autoplay="false"
        :intervall="10000"
        ratio="16/8"
        :arrows="true"
        :controlls-fade="true"
      />

      <div
        class="orm-col-xs-12 text-center orm-my-16"
        v-if="offers && type == 1 && demo =='true'"
      >
        <h2 class="orm-headline__h4">
          Angebotsmodul: Auto-Loop, Steuerung über Punkte
        </h2>
      </div>

      <div
        class="orm-col-xs-12 text-center orm-my-16"
        v-if="offers && type == 2 && demo =='true'"
      >
        <h2 class="orm-headline__h4">
          Angebotsmodul: kein Auto-Loop, Steuerung über Pfeile und Punkte, Einblendung auf Mouseover
        </h2>
      </div>
    </div>
  </teleport>
</template>

<script>
import { defineComponent } from "vue";
import store from "@/store";
import Slider from "@/components/ui/offers-slider.vue";

export default defineComponent({
  name: "OffersSlider",
  components: { Slider },
  data() {
    return {
      offers: null,
      demo: process.env.VUE_APP_DEMO,
    };
  },
  props: {
    type: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  async created() {
    if (store.getters["ORMgeneral/GET_MODULE"]("ORM_ANGEBOTE") == "J" && process.env.VUE_APP_OFFERS == "true") {
      await store.dispatch("offers/SET_OFFERS");
      this.offers = store.getters["offers/GET_OFFERS"];
    }
  },
  setup(props) {
    let teleport = "crentOffersSlider";
    if (props.type == 2) {
      teleport = "crentOffersSlider2";
    }
    let widget = false;
    const myEle = document.getElementById(teleport);

    if (myEle !== null) {
      widget = true;
    }

    return {
      widget,
      teleport,
      // offers,
    };
  },
});
</script>
