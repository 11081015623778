<template>
  <div class="orm-container orm-loginPage">
    <div class="orm-row center-xs orm-mt-64">
      <div class="orm-col-xs-12 orm-relative">
        <div class="orm-login-widget text-left">
          <div class="orm-reservationHeader__logo orm-pt-0">
            <router-link
              v-if="env.VUE_APP_STANDALONE == 'true'"
              :to="{ name: 'Home' }"
              title="Home"
            >
              <div
                class="orm-reservationHeader__logo_image"
                :style="{
                  backgroundImage: 'url(' + logo + ')',
                  height: logoheight + 'px',
                }"
              ></div>
            </router-link>
            <a v-else :href="env.VUE_APP_BASE_URL"
              ><div
                class="orm-reservationHeader__logo_image"
                :style="{
                  backgroundImage: 'url(' + logo + ')',
                  height: logoheight + 'px',
                }"
              ></div
            ></a>
          </div>
          <p class="orm-login-headline">
            {{ i18n("customer/login/new_pw") || "Neues Passwort" }}
          </p>
          <div v-if="!showSuccess">
            <div v-if="!$route.params.token">
              <FormInput
                v-model="email"
                type="text"
                my-id="email"
                :my-label="i18n('form/label/email')"
                :is-required="true"
                :error="validate.email"
              />
              <button
                class="orm-btn orm-login-widget-btnlistener"
                @click="requestPassword"
              >
                {{ i18n("customer/login/reset_pw") }}
              </button>
            </div>
            <div v-else>
              <FormPwd
                v-model="password"
                type="text"
                my-id="password"
                :my-label="i18n('form/label/password') || 'Passwort'"
                :is-required="true"
                :error="validate.password"
              />
              <button
                type=""
                @click="changePassword"
                class="orm-btn orm-login-widget-btnlistener"
              >
                {{ i18n("customer/login/submit_pw") || "Absenden" }}
              </button>
            </div>
          </div>

          <div v-else-if="error">
            <p>
              Der Token ist abgelaufen. Bitte Passwort-Anfrage erneut starten.
            </p>
            <FormInput
              v-model="email"
              type="text"
              my-id="email"
              :my-label="i18n('form/label/email')"
              :is-required="true"
              :error="validate.email"
            />
            <button
              class="orm-btn orm-login-widget-btnlistener"
              @click="requestPassword"
            >
              {{ i18n("customer/login/reset_pw") }}
            </button>
          </div>
          <div v-else>
            <p v-if="$route.params.token">
              {{
                i18n("customer/login/changedpw") ||
                  "Passwort erfolgreich geändert."
              }}
            </p>
            <p v-else>
              {{
                i18n("customer/login/sendpwdmail") ||
                  "E-Mail mit Link zum Passwort zurücksetzen wurde verschickt."
              }}
            </p>
            <button
              class="orm-btn orm-login-widget-btnlistener"
              @click="submit('Home')"
            >
              {{ i18n("error/default/buttonFirstLabel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import i18n from "@/mixins/mixins";
import FormInput from "@/components/ui/INPUTS/FormInput.vue"; // @ is an alias to /src
import FormPwd from "@/components/ui/INPUTS/FormPwd.vue"; // @ is an alias to /src

export default {
  name: "ForgotPassword",
  mixins: [i18n],
  components: { FormInput, FormPwd },
  setup() {
    const logoheight = process.env.VUE_APP_CUSTOMER_LOGO_HEIGHT_RESERVATION;
    const clientdata = store.getters["general/GET_CLIENT_DATA"];
    const env = process.env;
    let logo;
    // eslint-disable-next-line
    if (clientdata["reservationheader-logo"]) {
      if (clientdata["reservationheader-logo"].search("http") != "-1") {
        // eslint-disable-next-line
        logo = clientdata["reservationheader-logo"];
      } else {
        // eslint-disable-next-line
        logo =
          process.env.VUE_APP_BASE_URL + clientdata["reservationheader-logo"];
      }
    }
    return { logo, logoheight, env };
  },
  data() {
    return {
      email: "",
      password: "",
      showSuccess: false,
      error: false,
      validate: {
        email: false,
        password: false,
      },
    };
  },
  async mounted() {
    if (store.getters["userSession/IS_LOGGEDIN"]) {
      if (process.env.VUE_APP_STANDALONE == "true") {
        this.$router.push({ name: "Bookings" });
      } else {
        window.location.href =
          process.env.VUE_APP_BASE_URL +
          "/" +
          process.env.VUE_APP_CUSTOMER_PATH +
          "bookings";
      }
    }
  },
  methods: {
    async requestPassword() {
      this.requestLoading = true;
      await store
        .dispatch("customer/FORGOTPASSWORD", {
          email: this.email,
          return_url: "customer/forgot/",
        })
        .then((result) => {
          if (result) {
            this.showSuccess = true;
            this.requestLoading = false;
          } else {
            // this.showSuccess = true;
            if (process.env.VUE_APP_STANDALONE == "true") {
              this.$router.push({ name: "Home" });
            } else {
              window.location.href = process.env.VUE_APP_BASE_URL;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async changePassword() {
      await store
        .dispatch("customer/ACTIVATE", {
          password: this.password,
          token: this.$route.params.token,
        })
        .then((result) => {
          if (result) {
            this.showSuccess = true;
            // if (process.env.VUE_APP_STANDALONE == "true") {
            //   this.$router.push({ name: "Login" });
            // } else {
            //   window.location.href =
            //     process.env.VUE_APP_BASE_URL +
            //     "/" +
            //     process.env.VUE_APP_CUSTOMER_PATH +
            //     "login";
            // }
          } else {
            this.showSuccess = true;
            this.error = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submit(route) {
      if (process.env.VUE_APP_STANDALONE == "true") {
        this.$router.push({ name: route });
      } else {
        window.location.href = process.env.VUE_APP_BASE_URL;
      }
    },
  },
};
</script>
