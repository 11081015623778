/* eslint-disable */ // eslint-disable-next-line
<template>
  <div class="orm-container-fluid" style="background-color: #f7f7f7">
    <div class="orm-container">
      <div class="orm-row">
        <div class="orm-col-xs-12 orm-py-16" style="background-color:white">
          <h1 class="orm-headline__h1">Form Styles</h1>
          <div class="orm-form__container">
            <!-- RADIO BUTTONS -->
            <label class="orm-radio">
              <span class="orm-radio__input">
                <input type="radio" name="radio" />
                <span class="orm-radio__control"></span>
              </span>
              <span class="orm-radio__label">Keine Versicherung</span>
            </label>
            <label class="orm-radio">
              <span class="orm-radio__input">
                <input type="radio" name="radio" />
                <span class="orm-radio__control"></span>
              </span>
              <span class="orm-radio__label"
                ><b>Nur Diebstahl & Glasbruch</b></span
              >
            </label>
            <label class="orm-radio orm-multisize">
              <span class="orm-radio__input">
                <input type="radio" name="radio" />
                <span class="orm-radio__control"></span>
              </span>
              <span class="orm-radio__label"
                ><b>Haftpflichtversicherung</b> (Haftung bis zum
                Fahrzeugwert)</span
              >
            </label>
            <label class="orm-radio orm-multisize">
              <span class="orm-radio__input">
                <input type="radio" name="radio" />
                <span class="orm-radio__control"></span>
              </span>
              <span class="orm-radio__label"
                ><b>Selbstbeteiligung in Höhe von 300€</b
                ><br />Haftungsreduzierung nach Art einer Vollkaskoversicherung
                mit einer Selbstbeteiligung in Höhe von 950€ pro
                Schadensfall.</span
              >
            </label>
            <label class="orm-radio orm-withInput">
              <span class="orm-radio__input">
                <input type="radio" name="radio" />
                <span class="orm-radio__control"></span>
              </span>
              <span class="orm-radio__label multisize"
                ><b>Benötigte Kilometer</b>
                <div class="orm-input orm-inline orm-withIcon">
                  <input
                    class="orm-input__control"
                    id="text-input-inline"
                    type="text"
                    placeholder="KM"
                    value="500 KM"
                  />
                  <i class="far fa-sync"></i>
                </div>
              </span>
            </label>

            <br />
            <!-- CHECKBOXES -->

            <label class="orm-checkbox">
              <span class="orm-checkbox__input">
                <input type="checkbox" name="checkbox" />
                <span class="orm-checkbox__control">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-width="4"
                      d="M1.73 12.91l6.37 6.37L22.79 4.59"
                    />
                  </svg>
                </span>
              </span>
              <span class="orm-checkbox__label">Kunden Account</span>
            </label>
            <label class="orm-checkbox">
              <span class="orm-checkbox__input">
                <input type="checkbox" name="checkbox" />
                <span class="orm-checkbox__control">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-width="4"
                      d="M1.73 12.91l6.37 6.37L22.79 4.59"
                    />
                  </svg>
                </span>
              </span>
              <span class="orm-checkbox__label"
                ><b>Kunden Account anlegen</b></span
              >
            </label>
            <label class="orm-checkbox orm-multisize">
              <span class="orm-checkbox__input">
                <input type="checkbox" name="checkbox" />
                <span class="orm-checkbox__control">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-width="4"
                      d="M1.73 12.91l6.37 6.37L22.79 4.59"
                    />
                  </svg>
                </span>
              </span>
              <span class="orm-checkbox__label"
                ><b>Kunden Account anlegen</b> (ohne Dateneingabe)</span
              >
            </label>
            <label class="orm-checkbox orm-multisize">
              <span class="orm-checkbox__input">
                <input type="checkbox" name="checkbox" />
                <span class="orm-checkbox__control">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-width="4"
                      d="M1.73 12.91l6.37 6.37L22.79 4.59"
                    />
                  </svg>
                </span>
              </span>
              <span class="orm-checkbox__label"
                ><b>Kunden Account anlegen</b><br />und zukünftig ohne
                Dateneingabe mieten*</span
              >
            </label>
            <label class="orm-checkbox orm-disabled">
              <span class="orm-checkbox__input">
                <input
                  type="checkbox"
                  name="disabled-checked"
                  disabled
                  checked
                />
                <span class="orm-checkbox__control">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-width="4"
                      d="M1.73 12.91l6.37 6.37L22.79 4.59"
                    />
                  </svg>
                </span>
              </span>
              <span class="orm-checkbox__label"
                >Checkbox - Checked & Disabled</span
              >
            </label>
            <label class="orm-checkbox orm-withSelect">
              <span class="orm-checkbox__input">
                <input type="checkbox" name="with-input" />
                <span class="orm-checkbox__control">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-width="4"
                      d="M1.73 12.91l6.37 6.37L22.79 4.59"
                    />
                  </svg>
                </span>
              </span>
              <span class="orm-checkbox__label orm-select">
                <div class="orm-select__control orm-inline">
                  <select id="standard-select">
                    <option value="Option 1" selected>Option 1</option>
                    <option value="Option 2">Option 2</option>
                    <option value="Option 3">Option 3</option>
                    <option value="Option 4">Option 4</option>
                    <option value="Option 5">Option 5</option>
                    <option value="Option length"
                      >Option that has too long of a value to fit</option
                    >
                  </select>
                </div>
                <b>Kindersitz (Miete)</b>Preis pro Stück 11,90 €
              </span>
            </label>
            <br />

            <!-- Selects -->
            <div class="orm-select">
              <label class="orm-form__label" for="standard-select"
                >Standard Select</label
              >
              <div class="orm-select__control">
                <select id="standard-select">
                  <option value="Option 1" selected>Option 1</option>
                  <option value="Option 2">Option 2</option>
                  <option value="Option 3">Option 3</option>
                  <option value="Option 4">Option 4</option>
                  <option value="Option 5">Option 5</option>
                  <option value="Option length"
                    >Option that has too long of a value to fit</option
                  >
                </select>
              </div>
            </div>
            <div class="orm-select orm-disabled">
              <label class="orm-form__label" for="standard-select"
                >Disabled Select</label
              >
              <div class="orm-select__control">
                <select id="standard-select" disabled>
                  <option value="Option 1">Option 1</option>
                  <option value="Option 2" selected>Option 2</option>
                  <option value="Option 3">Option 3</option>
                  <option value="Option 4">Option 4</option>
                  <option value="Option 5">Option 5</option>
                  <option value="Option length"
                    >Option that has too long of a value to fit</option
                  >
                </select>
              </div>
            </div>

            <div class="orm-select orm-multiple">
              <label class="orm-form__label" for="multi-select"
                >Multiple Select</label
              >
              <div class="orm-select__control">
                <select id="multi-select" multiple>
                  <option value="Option 1">Option 1</option>
                  <option value="Option 2">Option 2</option>
                  <option value="Option 3">Option 3</option>
                  <option value="Option 4">Option 4</option>
                  <option value="Option 5">Option 5</option>
                  <option value="Option length"
                    >Option that has too long of a value to fit</option
                  >
                </select>
              </div>
            </div>
            <br />
            <!-- text inputs -->
            <div class="orm-input">
              <label class="orm-form__label" for="text-input">Text Input</label>
              <input
                class="orm-input__control"
                id="text-input"
                type="text"
                placeholder="Placeholder Text"
                value="Hello"
              />
            </div>
            <div class="orm-input orm-withIcon">
              <input
                class="orm-input__control"
                id="text-input-icon"
                type="text"
                placeholder="Placeholder Text"
                value="Text with Icon"
              />
              <i class="far fa-search"></i>
            </div>
            <div class="orm-input">
              <label class="orm-form__label" for="textarea">Textarea</label>
              <textarea
                class="orm-input__control"
                id="textarea"
                placeholder="Placeholder Text"
              ></textarea>
            </div>
            <div class="orm-input">
              <label class="orm-form__label" for="text-input-readonly"
                >Text Input</label
              >
              <input
                class="orm-input__control"
                id="text-input-readonly"
                type="text"
                value="Readonly Textfield"
                readonly
              />
            </div>
            <div class="orm-input">
              <label class="orm-form__label" for="textarea-readonly"
                >Textarea</label
              >
              <textarea
                class="orm-input__control"
                id="textarea-readonly"
                placeholder="Placeholder Text"
                value="Bemerkung"
                readonly
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

