<template>
  <label
    class="orm-checkbox"
    :class="{
      'orm-fontbase14': myFontbase == '14',
      'orm-multisize': myMultisize,
      'orm-disabled': isReadonly,
    }"
  >
    <span class="orm-checkbox__input">
      <input
        type="checkbox"
        :name="myName"
        :disabled="isReadonly || (conditional && modelValue)"
        :required="isRequired"
        :checked="modelValue ? true : false"
        @input="$emit('update:modelValue', $event.target.checked)"
      />
      <span
        class="orm-checkbox__control"
        :class="{ 'orm-checkbox__control--error': error }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          aria-hidden="true"
          focusable="false"
        >
          <path
            fill="none"
            stroke="currentColor"
            stroke-width="4"
            d="M1.73 12.91l6.37 6.37L22.79 4.59"
          />
        </svg>
      </span>
    </span>
    <span v-if="myMultisize" class="orm-checkbox__label"
      ><b
        >{{ myLabel }}<span v-if="isRequired">*</span>&nbsp;<br
          v-if="myMultiline"/></b
      >{{ mySmall }}</span
    >
    <span v-else class="orm-checkbox__label">
      <span v-html="myLabel"></span><span v-if="isRequired">*</span>
    </span>
  </label>
</template>
<script>
export default {
  props: {
    myFontbase: {
      type: String,
      required: false,
      default: "",
    },
    conditional: { required: false },
    myMultisize: {
      type: Boolean,
      required: false,
      default: false,
    },
    myMultiline: {
      type: Boolean,
      required: false,
      default: false,
    },
    myName: {
      required: true,
    },
    myLabel: {
      type: String,
      required: true,
    },
    mySmall: {
      type: String,
      required: false,
    },
    modelValue: {
      type: Object,
      required: false,
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
};
</script>
