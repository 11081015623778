<template>
  <teleport v-if="widget" to="#crentHeader">
    <div class="orm-header">
      <div class="orm-container">
        <div class="orm-row middle-xs">
          <div class="orm-col-xs-6 orm-col-sm-6">
            <div class="orm-reservationHeader__logo">
              <router-link
                v-if="env.VUE_APP_STANDALONE == 'true'"
                :to="{ name: 'Home' }"
                title="Home"
              >
                <img
                  :src="logo"
                  :style="{
                    maxHeight: logoheight + 'px',
                  }"
                />
              </router-link>
              <a v-else :href="store.getters['general/GET_APPBASE']"
                ><img
                  :src="logo"
                  :style="{
                    maxHeight: logoheight + 'px',
                  }"
              /></a>
            </div>
          </div>
          <div
            class="orm-col-xs-6 orm-col-sm-6 orm-flex end-sm text-right middle-md"
          >
            <span class="orm-login-widget--typo3">
              <CustomerWidget :teleport="false"
            /></span>
            <span class="orm-burger" onclick="">
              <i class="far fa-bars"></i>
            </span>

            <nav class="orm-headerNavigation text-left first-md">
              <ul>
                <li v-if="env.VUE_APP_STATIONPAGE === 'true'">
                  <router-link :to="{ name: 'stations' }">
                    {{ i18n("general/stations") || "Stationen" }}
                  </router-link>
                </li>
                <li v-if="env.VUE_APP_VEHICLEPAGE === 'true'">
                  <router-link :to="{ name: 'fahrzeuge' }">{{
                    i18n("general/vehicles")
                  }}</router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { defineComponent } from "vue";
import store from "@/store";
import i18n from "@/mixins/mixins";
import CustomerWidget from "@/components/widgets/CustomerWidget.vue"; // @ is an alias to /src

// import dropdown from "@/components/ui/dropdown.vue"; // @ is an alias to /src

export default defineComponent({
  name: "Footer",
  components: { CustomerWidget },
  mixins: [i18n],
  setup() {
    let widget = false;
    let loginicontext = true;
    const myEle = document.getElementById("crentHeader");
    if (myEle !== null) {
      loginicontext = myEle.dataset.loginicontext === "false" ? false : true;
      widget = true;
    }
    const clientdata = store.getters["general/GET_CLIENT_DATA"];
    // console.log(clientdata);
    let logo;
    // eslint-disable-next-line
    if (clientdata["reservationheader-logo"]) {
      if (clientdata["reservationheader-logo"].search("http") != "-1") {
        // eslint-disable-next-line
        logo = clientdata["reservationheader-logo"];
      } else {
        // eslint-disable-next-line
        logo =
          process.env.VUE_APP_BASE_URL + clientdata["reservationheader-logo"];
      }
    }

    const stations = store.getters["stations/GET_STATIONS"];
    const logoheight = process.env.VUE_APP_CUSTOMER_LOGO_HEIGHT_HOME;
    const env = process.env;
    // const stationsCS = [];
    // for (const station in stations) {
    //   if (stations[station].is_sharing_station == true) {
    //     stationsCS.push(stations[station]);
    //   }
    // }
    // console.log(store.state.stations);

    return {
      widget,
      store,
      stations,
      logoheight,
      loginicontext,
      env,
      logo,
      // stationsCS,
    };
  },
});
</script>
