<template>
  <div class="orm-container orm-loginPage">
    <div class="orm-row center-xs orm-mt-64">
      <div class="orm-col-xs-12 orm-relative">
        <Login @get-login="login" :show-logo="true" />
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import Login from "@/components/customer/login.vue"; // @ is an alias to /src

export default {
  name: "LoginView",
  components: { Login },

  async mounted() {
    const isLoggedin = await store.getters["userSession/IS_LOGGEDIN"];
    if (isLoggedin) {
      if (process.env.VUE_APP_STANDALONE == "true") {
        this.$router.push({ name: "Bookings" });
      } else {
        window.location.href =
          process.env.VUE_APP_BASE_URL + "customer/bookings";
      }
    }
  },
  methods: {
    login() {
      if (process.env.VUE_APP_STANDALONE == "true") {
        this.$router.push({ name: "Bookings" });
      } else {
        window.location.href =
          process.env.VUE_APP_BASE_URL + "customer/bookings";
      }
    },
  },
};
</script>
