<template>
  <CarListSmall
    v-if="!availabilityPlan && filter && !vehiclesList"
    :data="data"
    :filter="filter"
    @update:error="showError"
    @openContact="openContact"
  />

  <CarListAvaiabilites
    v-if="availabilityPlan && filter && !vehiclesList"
    :data="data"
    :filter="filter"
    @update:error="showError"
    @openContact="openContact"
  />

  <CarListSearch
    v-if="filter && vehiclesList"
    :data="data"
    :filter="filter"
    :stations="stations"
    @openContact="openContact"
  />
</template>

<script>
import { defineComponent } from "vue";
// import store from "@/store";
import CarListSmall from "@/components/cargroup/car-list-small.vue";
import CarListAvaiabilites from "@/components/cargroup/car-list-availabilities.vue";
import CarListSearch from "@/components/cargroup/car-list-search.vue";
import i18n from "@/mixins/mixins";

export default defineComponent({
  name: "car",
  components: { CarListSmall, CarListAvaiabilites, CarListSearch },
  mixins: [i18n],
  props: {
    availabilityPlan: { type: Boolean, default: false },
    data: Object,
    filter: Boolean,
    vehiclesList: { type: Boolean, default: false },
    stations: { type: Object },
  },
  data() {
    return {
      error: {
        show: false,
        data: {
          header: this.i18n("error/tocarselect/header"),
          content: this.i18n("error/tocarselect/content"),
          buttonFirst: "Home",
          buttonFirstLabel: this.i18n("error/tocarselect/buttonFirstLabel"),
        },
      },
    };
  },
  emits: ["openContact"],
  methods: {
    showError(ev) {
      console.log(ev);
      this.error.show = true;
      //this.error.data.content = ev.form.message;
    },
    openContact(car) {
      this.$emit("openContact", car);
    },
  },
});
</script>
