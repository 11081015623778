<template>
  <reservationheader :step="5" />
  <reservationinfobar step="success" />
  <div class="orm-background--grey orm-py-16">
    <div class="orm-container">
      <div class="orm-background--white orm-py-30 orm-px-30">
        <div class="orm-row">
          <div class="orm-col-xs-12 orm-mb-16">
            <h1 class="orm-headline__h2--alt" v-if="!hasConcardis">
              {{ i18n("success/headline") }}
            </h1>
            <h1 class="orm-headline__h2--alt" v-else>
              {{ i18n("success/headline_online_booking") }}
            </h1>
            <h2 class="orm-headline__h4" v-html="i18n('success/text')"></h2>
          </div>
        </div>
        <div class="orm-row">
          <div class="orm-col-xs-12 orm-col-md-6">
            <div class="orm-row" v-if="data.startstation">
              <div class="orm-col-xs-12 orm-col-sm-6 orm-mb-16">
                <div
                  class="orm-borderRadius__small orm-reservationFinal__datebox orm-py-16 orm-px-16"
                >
                  <div class="orm-mb-16">
                    <strong v-if="data.delivery_street">{{
                      i18n("step2/deliverypickup/delivery")
                    }}</strong>
                    <strong v-else>{{ i18n("general/pickup") }}</strong>
                  </div>
                  <div class="orm-reservationFinal__flex orm-mb-8">
                    <span class="orm-pr-16"
                      ><i class="fas fa-map-marker-alt"></i
                    ></span>
                    <div>
                      <div>
                        <strong v-if="data.delivery_street">
                          {{ data.delivery_street }}</strong
                        >
                        <strong v-else> {{ data.startstation.name }}</strong>
                      </div>
                      <small v-if="data.delivery_zipcode && data.delivery_city">
                        {{
                          data.delivery_zipcode + " " + data.delivery_city
                        }}</small
                      >
                      <small v-else> {{ data.startstation.street }}</small>
                    </div>
                  </div>
                  <div class="orm-reservationFinal__flex orm-mb-8">
                    <span class="orm-pr-16"
                      ><i class="fal fa-calendar-alt"></i
                    ></span>
                    <div>
                      <strong> {{ formateDateShort(data.startdate) }}</strong>
                    </div>
                  </div>
                  <div class="orm-reservationFinal__flex">
                    <span class="orm-pr-16"><i class="fal fa-clock"></i></span>
                    <div>
                      <strong
                        >{{ formatTime(data.startdate) }}
                        {{ i18n("general/hour") }}</strong
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="orm-col-xs-12 orm-col-sm-6 orm-mb-16">
                <div
                  class="orm-borderRadius__small orm-reservationFinal__datebox orm-py-16 orm-px-16"
                >
                  <div class="orm-mb-16">
                    <strong v-if="data.pickup_street">{{
                      i18n("step2/deliverypickup/pickup")
                    }}</strong>
                    <strong v-else>{{ i18n("general/delivery") }}</strong>
                  </div>
                  <div class="orm-reservationFinal__flex orm-mb-8">
                    <span class="orm-pr-16"
                      ><i class="fas fa-map-marker-alt"></i
                    ></span>
                    <div>
                      <div>
                        <strong v-if="data.pickup_street">
                          {{ data.pickup_street }}</strong
                        >
                        <strong v-else>
                          {{ data.planned_endstation.name }}</strong
                        >
                      </div>
                      <small v-if="data.pickup_zipcode && data.pickup_city">
                        {{
                          data.pickup_zipcode + " " + data.pickup_city
                        }}</small
                      >
                      <small v-else>
                        {{ data.planned_endstation.street }}</small
                      >
                    </div>
                  </div>
                  <div class="orm-reservationFinal__flex orm-mb-8">
                    <span class="orm-pr-16"
                      ><i class="fal fa-calendar-alt"></i
                    ></span>
                    <div>
                      <strong>
                        {{ formateDateShort(data.planned_enddate) }}</strong
                      >
                    </div>
                  </div>
                  <div class="orm-reservationFinal__flex">
                    <span class="orm-pr-16"><i class="fal fa-clock"></i></span>
                    <div>
                      <strong>
                        {{ formatTime(data.planned_enddate) }}
                        {{ i18n("general/hour") }}</strong
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="orm-col-xs-12 orm-col-md-6 orm-mb-64 orm-pl-md-30">
            <div class="borderRadiusTBRL overflowHidden">
              <gMap
                :mapConfig="mapConfig"
                :apiKey="apiKey"
                :height="250"
                v-if="cookiefound.maps && data.startstation && apiKey"
                v-slot="{ google, map, bounds }"
              >
                <gMapMarker
                  v-if="data.startstation != 0"
                  :marker="data.startstation"
                  :google="google"
                  :map="map"
                  :bounds="bounds"
                  :key="data.startstation"
                  :noselect="true"
                />
              </gMap>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>

<script>
import { defineComponent, reactive } from "vue";

import { mapSettings } from "@/constants/mapSettings";

import Footer from "@/components/widgets/Footer.vue"; // @ is an alias to /src
import reservationheader from "@/components/reservation/header.vue"; // @ is an alias to /src
import store from "@/store";
import reservationinfobar from "@/components/reservation/infobar_reservation.vue"; // @ is an alias to /src
import gMap from "@/components/maps/gMap.vue";
import gMapMarker from "@/components/maps/gMapMarker.vue";
import { formateDateShort, formatTime } from "@/constants/formater";
import i18n from "@/mixins/mixins";

export default defineComponent({
  name: "Reservation/Final",
  components: {
    Footer,
    reservationinfobar,
    reservationheader,
    gMap,
    gMapMarker,
  },
  mixins: [i18n],
  setup() {
    const mapConfig = {
      ...mapSettings,
      center: { lat: 52.29240999999999, lng: 8.920219999999972 },
    };
    const cookiefound = reactive({ maps: false });
    if (process.env.VUE_APP_COOKIECONSENT === "true") {
      const COOKIE = Symbol("Cookie");
      let lastCookies = document.cookie;

      const getCookie = (cname) => {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(lastCookies);
        const ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == " ") {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return JSON.parse(c.substring(name.length, c.length));
          }
        }
        return false;
      };

      const checkMapCookie = () => {
        if (process.env.VUE_APP_COOKIECONSENTARRAY === "true") {
          const cookie = getCookie(process.env.VUE_APP_COOKIECONSENTCOOKIENAME)
            .options;
          if (cookie) {
            cookiefound.maps = cookie.find(
              (element) =>
                element === process.env.VUE_APP_COOKIECONSENTCOOKIEMAPSNAME
            );
          }
        } else {
          if (
            getCookie(process.env.VUE_APP_COOKIECONSENTCOOKIEMAPSNAME) ==
            (true || 1)
          ) {
            cookiefound.maps = true;
          } else {
            cookiefound.maps = false;
          }
        }
      };

      Object.defineProperty(
        Document.prototype,
        COOKIE,
        Object.getOwnPropertyDescriptor(Document.prototype, "cookie")
      );

      Object.defineProperty(Document.prototype, "cookie", {
        enumerable: true,
        configurable: true,
        get() {
          return this[COOKIE];
        },
        set(value) {
          this[COOKIE] = value;

          if (value === lastCookies) {
            return;
          }
          lastCookies = value;
          checkMapCookie();
        },
      });
      checkMapCookie();
    } else {
      cookiefound.maps = true;
    }
    return {
      mapConfig,
      formateDateShort,
      formatTime,
      cookiefound,
    };
  },
  data() {
    return {
      data: Object,
      session: Object,
      startstation: Object,
      endstation: Object,
      apiKey: null,
      bounds: [],
      hasConcardis: false,
    };
  },

  async mounted() {
    const clientdata = await store.getters["general/GET_CLIENT_DATA"];
    this.apiKey = clientdata["maps_apikey"];
    this.data = await store.getters["reservation/GET_RESERVATION_SUCCESS"];
    store.dispatch("reservation/DELETE_TARIF");
    store.dispatch("payment/DELETE_CONARDIS");
    // console.log(this.data);

    if (this.data == null) {
      this.$router.push({ name: "Home" });
    }
    const object = this.data["invoice_positions"];

    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const element = object[key];
        if (element.rate == process.env.VUE_APP_PAYMENT_ONLINE) {
          this.hasConcardis = true;
        }
      }
    }
    // console.log(store.getters["reservation/GET_RESERVATION"]);
    // this.data = this.$route.params.data;
  },
});
</script>
