const env = process.env;
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";

import Home from "../views/Home.vue";

// import Station from "../views/Station.vue";
// import Vehicles from "../views/Vehicles.vue";
import Reservation from "../views/Reservation.vue";
import Step1 from "../views/Reservation/Step1.vue";
import Step2 from "../views/Reservation/Step2.vue";
import Step3 from "../views/Reservation/Step3.vue";
import Step4 from "../views/Reservation/Step4.vue";
import Final from "../views/Reservation/Final.vue";
import PageNotFound from "../views/PageNotFound.vue";

//Customer
// import Register from "../views/Customer/Register.vue";
import Activate from "../views/Customer/Activate.vue";
import ActivatePassword from "../views/Customer/ActivatePassword.vue";
import Login from "../views/Customer/Login.vue";
import Bookings from "../views/Customer/Bookings.vue";
import MyData from "../views/Customer/MyData.vue";
import Contact from "../views/Customer/Contact.vue";
import ForgotPassword from "../views/Customer/ForgotPassword.vue";

import Formstyles from "../views/Formstyles.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/" + env.VUE_APP_STATIONS_PATH,
    name: "stations",
    component: () => {
      if (process.env.VUE_APP_STATIONPAGE === "false") {
        return import("../views/Home.vue");
      } else {
        return import(
          "../views/Stations.vue" /* webpackChunkName: 'station.chunk' */
        );
      }
    },
  },
  {
    path: "/" + env.VUE_APP_STATIONS_PATH + ":name",
    name: "station",
    component: () => {
      if (process.env.VUE_APP_STATIONPAGE === "false") {
        return import("../views/Home.vue");
      } else {
        return import(
          "../views/Station.vue" /* webpackChunkName: 'station.chunk' */
        );
      }
    },
  },
  {
    path: "/" + env.VUE_APP_VEHICLES_PATH,
    name: "fahrzeuge",
    component: () => {
      if (process.env.VUE_APP_VEHICLEPAGE === "false") {
        return import("../views/Home.vue");
      } else {
        return import(
          "../views/Vehicles.vue" /* webpackChunkName: 'vehicles.chunk' */
        );
      }
    },
  },
  {
    path: "/" + env.VUE_APP_VEHICLES_PATH + ":name",
    name: "fahrzeug",
    component: () => {
      if (process.env.VUE_APP_VEHICLEPAGE === "false") {
        return import("../views/Home.vue");
      } else {
        return import(
          "../views/Vehicles.vue" /* webpackChunkName: 'vehicles.chunk' */
        );
      }
    },
  },
  {
    path: "/" + env.VUE_APP_RESERVATION_PATH,
    name: "Reservation",
    component: Reservation,
  },
  {
    path:
      "/" + env.VUE_APP_RESERVATION_PATH + env.VUE_APP_RESERVATION_PATH_STEP1,
    name: "Reservation_Step1",
    component: Step1,
  },
  {
    path:
      "/" +
      env.VUE_APP_RESERVATION_PATH +
      env.VUE_APP_RESERVATION_PATH_STEP1 +
      "/:name",
    name: "Reservation_Step1_Selected",
    component: Step1,
  },
  {
    path:
      "/" + env.VUE_APP_RESERVATION_PATH + env.VUE_APP_RESERVATION_PATH_STEP2,
    name: "Reservation_Step2",
    component: Step2,
  },
  {
    path:
      "/" + env.VUE_APP_RESERVATION_PATH + env.VUE_APP_RESERVATION_PATH_STEP3,
    name: "Reservation_Step3",
    component: Step3,
  },
  {
    path:
      "/" + env.VUE_APP_RESERVATION_PATH + env.VUE_APP_RESERVATION_PATH_STEP4,
    name: "Reservation_Step4",
    component: Step4,
  },
  {
    path:
      "/" + env.VUE_APP_RESERVATION_PATH + env.VUE_APP_RESERVATION_PATH_FINAL,
    name: "Reservation_Final",
    component: Final,
  },
  {
    path: "/formstyles",
    name: "Formstyles",
    component: Formstyles,
  },
  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH,
    name: "Customer",
    component: Bookings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH + "login",
    component: Login,
    name: "Login",
    meta: { requiresAuth: false },
  },
  // {
  //   path: "/" + env.VUE_APP_CUSTOMER_PATH + "register",
  //   component: Register,
  //   name: "Register",
  //   meta: { requiresAuth: false },
  // },
  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH + "activate/" + ":token",
    component: Activate,
    name: "Activate",
    meta: { requiresAuth: false },
  },
  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH + "activatepassword/" + ":token",
    component: ActivatePassword,
    name: "ActivatePassword",
    meta: { requiresAuth: false },
  },
  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH + "forgot",
    component: ForgotPassword,
    name: "ForgotPassword",
    meta: { requiresAuth: false },
  },
  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH + "forgot/" + ":token",
    component: ForgotPassword,
    name: "ForgotPasswordTOKEN",
    meta: { requiresAuth: false },
  },

  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH + "mydata",
    component: MyData,
    name: "MyData",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH + "bookings",
    component: Bookings,
    name: "Bookings",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH + "contact",
    component: Contact,
    name: "Contact",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: () => {
      if (process.env.VUE_APP_STANDALONE === "false") {
        return import("../views/Home.vue");
      } else {
        return PageNotFound;
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});
// eslint-disable-next-line
router.beforeEach(async (to, from) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth) {
    const isLoggin = await store.getters["userSession/IS_LOGGEDIN"];
    if (!isLoggin) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      return {
        path: "/",
        // save the location we were at to come back later
        // query: { redirect: to.fullPath },
      };
    } else {
      const expiresAt = JSON.parse(isLoggin.expire);
      const routerAuthCheck = new Date().getTime() < expiresAt;
      if (!routerAuthCheck) {
        store.dispatch("userSession/LOGOUT");
      }
    }
  }
});

export default router;
