<template>
  <teleport v-if="widget" to="#crentLocationMap ">
    <div
      class="orm-py-16 orm-pb-60 orm-container-fluid orm-background__image"
      v-if="clientdata"
      :style="{
        backgroundImage: 'url(' + clientdata['locationmap_background'] + ')',
      }"
    >
      <div class="orm-container">
        <div class="orm-row center-xs">
          <div
            class="orm-searchbar__datepicker orm-col-xs-12 orm-col-sm-11 orm-col-md-11 orm-col-lg-11"
          >
            <h2
              class="orm-locationMap__title"
              :class="
                headlinemode == 'dark' ? 'orm-locationMap__title--dark' : ''
              "
            >
              <span v-html="i18n('modules/locationmap/title')"></span>
            </h2>
            <div
              class="orm-searchbar__padding orm-searchbar__padding--map overflowHidden"
              v-if="ormload"
            >
              <gMap
                :mapConfig="mapConfig"
                :apiKey="apiKey"
                :height="200"
                v-slot="{ google, map, bounds }"
                v-if="cookiefound.maps"
                class="orm-searchlocmap orm-mb-16"
              >
                <div v-for="marker in stations" :key="marker.station">
                  <gMapMarker
                    v-if="marker.latitude != 0 && marker.latitude != null"
                    :marker="marker"
                    :google="google"
                    :map="map"
                    :bounds="bounds"
                  />
                </div>
                <gMapUser :google="google" :map="map" :bounds="bounds" />
              </gMap>
              <div class="orm-row center-lg">
                <div class="orm-col-xs-12 orm-col-md-12 orm-col-lg-3">
                  <div class="orm-row">
                    <FormSelect
                      v-if="
                        sttype == 'ALL' &&
                          stations &&
                          tabbar &&
                          tabbar.length > 1
                      "
                      :data="tabbar"
                      :selected="stationtype"
                      v-model="stationtype"
                      @update:modelValue="setStationType(stationtype)"
                      class="orm-col-xs-12 orm-col-lg-12"
                    ></FormSelect>
                    <stationpicker
                      :data="stations"
                      type="start"
                      class="orm-col-xs-12  orm-col-lg-12"
                    />
                    <stationpicker
                      v-if="
                        store.getters['ORMgeneral/GET_PARAMETER'](
                          'BINET_ALLOW_ONEWAY'
                        ) == 1
                      "
                      :data="stations"
                      viewType="mapModule"
                      type="end"
                      class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-12"
                    />
                  </div>
                </div>
                <div
                  class="orm-col-xs-12 orm-col-lg-7 orm-datepicker--mobileView"
                  style="position:relative"
                >
                  <datepicker />
                </div>
                <div
                  class="text-right orm-col-xs-12 orm-col-sm-6 orm-col-sm-offset-6 orm-col-lg-2 orm-col-lg-offset-0"
                >
                  <button
                    v-if="!loading"
                    @click="getCars('/reservation/step1')"
                    class="orm-btn orm-btn--large orm-btn--searchbar"
                    :class="[station ? '' : 'disable']"
                  >
                    {{ i18n("general/search") }}
                  </button>
                  <button
                    class="orm-btn orm-btn--large orm-btn--searchbar disable"
                    v-else
                  >
                    <i class="fa fa-spinner-third orm-rotate360"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { mapSettings } from "@/constants/mapSettings";

import gMap from "@/components/maps/gMap.vue";
import gMapMarker from "@/components/maps/gMapMarker.vue";
import gMapUser from "@/components/maps/gMapUser.vue";

import datepicker from "@/components/ui/datepicker.vue"; // @ is an alias to /src
import stationpicker from "@/components/ui/stationpicker.vue"; // @ is an alias to /src
import FormSelect from "@/components/ui/INPUTS/FormSelectSimpleArray"; // @ is an alias to /src
import store from "@/store";
import i18n from "@/mixins/mixins";

// import routes from "@/router/index";

export default defineComponent({
  name: "SearchBar",
  components: {
    datepicker,
    stationpicker,
    gMap,
    gMapMarker,
    gMapUser,
    FormSelect,
  },
  mixins: [i18n],
  setup() {
    let widget = false;
    const myEle = document.getElementById("crentSearchBar");
    let sttype = "ALL";
    let headlinemode = "dark";
    if (myEle !== null) {
      widget = true;
      headlinemode = myEle.dataset.headline;
    }

    sttype = document.getElementById("crentReservation").dataset.stationtype;

    if (
      !store.getters["userSession/GET_STATIONTYPE"] ||
      (sttype !== "ALL" &&
        sttype !== !store.getters["userSession/GET_STATIONTYPE"])
    ) {
      // console.log("patch", sttype);
      store.dispatch("userSession/SET_STATIONTYPE", sttype);
      store.dispatch(
        "userSession/SET_STATION",
        store.getters["stations/GET_STATIONS_FILTERED"][sttype][0].id
      );
    }
    const cookiefound = reactive({ maps: true });
    const mapConfig = {
      ...mapSettings,
      center: { lat: 52.29240999999999, lng: 8.920219999999972 },
    };
    const clientdata = store.getters["general/GET_CLIENT_DATA"];

    const apiKey = clientdata["maps_apikey"];

    return {
      widget,
      store,
      mapConfig,
      cookiefound,
      apiKey,
      sttype,
      headlinemode,
    };
  },
  props: {
    origin: {
      type: String,
      default: "home",
      required: false,
    },
  },
  data() {
    return {
      station: Object,
      loading: false,
      ormload: false,
      clientdata: null,
      backgroundImage: "",
      stations: {},
      stationsFilter: null,
      stationtype: null,
      tabbar: [],
    };
  },
  async mounted() {
    this.ormload = await store.getters["general/GET_ORMLOADED"];
    this.stations = await store.getters["stations/GET_STATIONS"];
    this.station = await store.getters["userSession/GET_STATION"];
    this.clientdata = await store.getters["general/GET_CLIENT_DATA"];
    // eslint-disable-next-line
    if (this.clientdata["searchbar_background"]) {
      // eslint-disable-next-line
      this.backgroundImage = this.clientdata["searchbar_background"];
    }
    this.stationsFilter = await store.getters["stations/GET_STATIONS_FILTERED"];
    this.stationtype = await store.getters["userSession/GET_STATIONTYPE"];
    this.stations = this.stationsFilter[this.stationtype];

    for (const station in this.stationsFilter) {
      if (station !== "ALL") this.tabbar.push(station);
    }

    store.watch(
      // eslint-disable-next-line
      (state, getters) => getters["general/GET_ORMLOADED"],
      (val) => {
        this.ormload = val;
        this.stations = store.getters["stations/GET_STATIONS"];
        this.station = store.getters["userSession/GET_STATION"];
      }
    );

    store.watch(
      // eslint-disable-next-line
      (state, getters) => getters["userSession/GET_STATION"],
      (val) => {
        this.station = store.getters["stations/GET_STATION_BY_ID"](val);
      }
    );
  },
  methods: {
    async getCars() {
      const times = await store.getters["stations/GET_OPENINGTIMES"];

      if (times) {
        this.loading = true;
        if (process.env.VUE_APP_STANDALONE == "true") {
          this.$router.push({ name: "Reservation_Step1" });
        } else {
          const baseUrl = window.location.origin;
          window.location.href =
            baseUrl +
            process.env.VUE_APP_BASE_URL +
            process.env.VUE_APP_RESERVATION_PATH +
            process.env.VUE_APP_RESERVATION_PATH_STEP1;
        }
      }
    },
    async setStationType(type) {
      this.stationtype = type;
      await store.dispatch("userSession/SET_STATIONTYPE", type).then(() => {
        store.dispatch(
          "userSession/SET_STATION",
          this.stationsFilter[type][0].id
        );
        store.dispatch("userSession/SET_STATION_END", "startend");

        this.stations = this.stationsFilter[type];
      });
    },
  },
});
</script>
