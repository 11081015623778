<template>
  <div>
    <Header />
    <div class="orm-container-fluid orm-reservationInfobar">
      <div class="orm-container">
        <div class="orm-row center-xl left-lg">
          <div class="orm-col-xs-12 orm-col-md-8 orm-col-lg-11 orm-padding20">
            <p style="margin-top:20px;">
              {{ i18n("customer/navigation/contact") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";

import Header from "@/components/widgets/Header.vue"; // @ is an alias to /src
export default {
  name: "Contact",
  components: { Header },
  methods: {
    async getLoginState() {
      const isLoggedin = await store.getters["userSession/IS_LOGGEDIN"];
      if (!isLoggedin) {
        if (process.env.VUE_APP_STANDALONE == "true") {
          this.$router.push({ name: "Home" });
        } else {
          window.location.href = process.env.VUE_APP_BASE_URL;
        }
      }
    },
  },
};
</script>
