<template>
  <teleport v-if="widget" :to="teleport">
    <div
      class="orm-py-56 orm-py-sm-70 orm-container-fluid orm-background__image"
      v-if="clientdata"
      :style="{
        backgroundImage: 'url(' + backgroundImage + ')',
      }"
    >
      <div class="orm-container">
        <div class="orm-row center-xs">
          <div class="orm-col-xs-12 text-center">
            <h2
              class="orm-searchbar__title orm-headline__h1 orm-pb-sm-128"
              :class="
                headlinemode == 'dark' ? 'orm-searchbar__title--dark' : ''
              "
            >
              <span v-html="i18n('modules/searchbar/title')"></span>
            </h2>
          </div>
        </div>
        <div class="orm-row center-xs">
          <div
            class="orm-carsearchfilter__types orm-searchbar__tabs orm-col-xs-12 orm-col-sm-11 orm-col-md-11 orm-col-lg-11 hide-mobile-only"
          >
            <div
              class="orm-carsearchfilter__typescontainer orm-flex"
              v-if="sttype == 'ALL' && tabbar.length > 1"
            >
              <div
                class="orm-carsearchfilter__typesitem"
                :class="
                  station === stationtype ||
                  (stationtype === 'ALL' && station === 'RENT')
                    ? 'active'
                    : ''
                "
                @click="setStationType(station)"
                v-for="station in tabbar"
                :key="station"
              >
                {{ i18n("general/stationtype/" + station) }}
              </div>
            </div>
          </div>
          <div
            class="orm-searchbar__datepicker orm-col-xs-12 orm-col-sm-11 orm-col-md-11 orm-col-lg-11"
            :class="
              sttype == 'ALL' && tabbar.length > 1
                ? 'orm-searchbar__datepicker_' + sttype
                : ''
            "
          >
            <div class="orm-searchbar__padding" v-if="ormload">
              <div class="orm-row center-lg">
                <FormSelect
                  v-if="
                    sttype == 'ALL' &&
                      tabbar &&
                      stationsFilter &&
                      tabbar.length > 1
                  "
                  :data="tabbar"
                  :selected="stationtype"
                  v-model="stationtype"
                  @update:modelValue="setStationType(stationtype)"
                  class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-12 show-mobile-only"
                ></FormSelect>
                <div class="orm-col-xs-12 orm-col-md-12 orm-col-lg-3">
                  <div class="orm-searchbar__headline">
                    <strong>{{ i18n("general/station") }}</strong>
                  </div>
                  <div class="orm-row">
                    <stationpicker
                      :data="stations"
                      type="start"
                      class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-12"
                    />
                    <stationpicker
                      v-if="
                        store.getters['ORMgeneral/GET_PARAMETER'](
                          'BINET_ALLOW_ONEWAY'
                        ) == 1
                      "
                      :data="stations"
                      type="end"
                      class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-12"
                    />
                  </div>
                </div>
                <div
                  class="orm-col-xs-12 orm-col-lg-7"
                  style="position:relative"
                >
                  <datepicker />
                </div>
                <div
                  class="text-right orm-col-xs-12 orm-col-sm-6 orm-col-sm-offset-6 orm-col-lg-2 orm-col-lg-offset-0"
                >
                  <div class="orm-searchbar__headline">
                    <strong>&nbsp;</strong>
                  </div>
                  <button
                    v-if="!loading"
                    @click="getCars('/reservation/step1')"
                    class="orm-btn orm-btn--large orm-btn--searchbar"
                    :class="[station ? '' : 'disable']"
                  >
                    {{ i18n("general/search") }}
                  </button>
                  <button
                    class="orm-btn orm-btn--large orm-btn--searchbar disable"
                    v-else
                  >
                    <i class="fa fa-spinner-third orm-rotate360"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { defineComponent } from "vue";
import FormSelect from "@/components/ui/INPUTS/FormSelectSimpleArray"; // @ is an alias to /src
import datepicker from "@/components/ui/datepicker.vue"; // @ is an alias to /src
import stationpicker from "@/components/ui/stationpicker.vue"; // @ is an alias to /src
import store from "@/store";
import i18n from "@/mixins/mixins";

// import routes from "@/router/index";

export default defineComponent({
  name: "SearchBar",
  components: { datepicker, stationpicker, FormSelect },
  mixins: [i18n],
  setup() {
    let widget = false;
    const myEle = document.getElementById("crentSearchBar");

    let headlinemode = "dark";
    let teleport;
    let sttype = "ALL";
    if (myEle !== null) {
      sttype = document.getElementById("crentReservation").dataset.stationtype;

      if (
        !store.getters["userSession/GET_STATIONTYPE"] ||
        (sttype !== "ALL" &&
          sttype !== !store.getters["userSession/GET_STATIONTYPE"])
      ) {
        // console.log("patch", sttype);
        store.dispatch("userSession/SET_STATIONTYPE", sttype);
        store.dispatch(
          "userSession/SET_STATION",
          store.getters["stations/GET_STATIONS_FILTERED"][sttype][0].id
        );
      }

      widget = true;
      headlinemode = myEle.dataset.headline;
      teleport = "#crentSearchBar";
    }
    // if (myEle !== null) {
    //   myEle.forEach((element) => {
    //     sttype = element.dataset.stationtype;
    //     if (element.dataset.stationtype) {
    //       if (element.dataset.stationtype == "ALL") {
    //         store.dispatch("userSession/SET_STATIONTYPE", "RENT");
    //       } else {
    //         store.dispatch(
    //           "userSession/SET_STATIONTYPE",
    //           element.dataset.stationtype
    //         );
    //       }
    //       widget = true;
    //       headlinemode = element.dataset.headline;
    //       teleport =
    //         "[data-searchbar-stationtype=" + element.dataset.stationtype + "]";
    //     }
    //   });
    // }

    return {
      widget,
      store,
      headlinemode,
      teleport,
      sttype,
    };
  },
  props: {
    type: { required: false, default: "ALL" },
    origin: {
      type: String,
      default: "home",
      required: false,
    },
  },
  data() {
    return {
      station: Object,
      loading: false,
      ormload: false,
      clientdata: null,
      backgroundImage: "",
      stations: {},
      stationsFilter: {},
      stationtype: null,
      tabbar: [],
    };
  },
  async mounted() {
    this.ormload = await store.getters["general/GET_ORMLOADED"];
    this.stations = await store.getters["stations/GET_STATIONS"];
    this.stationsFilter = await store.getters["stations/GET_STATIONS_FILTERED"];
    this.stationtype = await store.getters["userSession/GET_STATIONTYPE"];

    for (const station in this.stationsFilter) {
      if (station !== "ALL") this.tabbar.push(station);
    }

    if (this.stationtype === "ALL") this.stationtype = "RENT";

    this.stations = this.stationsFilter[this.stationtype];
    this.station = await store.getters["userSession/GET_STATION"];
    // if (!this.station) this.station = this.stations[0].id;
    this.clientdata = await store.getters["general/GET_CLIENT_DATA"];
    // eslint-disable-next-line
    if (this.clientdata["searchbar_background"]) {
      if (this.clientdata["searchbar_background"].search("http") != "-1") {
        // eslint-disable-next-line
        this.backgroundImage = this.clientdata["searchbar_background"];
      } else {
        // eslint-disable-next-line
        this.backgroundImage =
          process.env.VUE_APP_BASE_URL +
          this.clientdata["searchbar_background"];
      }
    }
    store.watch(
      // eslint-disable-next-line
      (state, getters) => getters["general/GET_ORMLOADED"],
      (val) => {
        this.ormload = val;
        this.stations = store.getters["stations/GET_STATIONS"];
      }
    );

    store.watch(
      // eslint-disable-next-line
      (state, getters) => getters["userSession/GET_STATION"],
      (val) => {
        this.station = store.getters["stations/GET_STATION_BY_ID"](val);
      }
    );
    store.watch(
      // eslint-disable-next-line
      (state, getters) => getters["userSession/GET_STATIONTYPE"],
      (val) => {
        this.stationtype = val;
        this.stations = this.stationsFilter[this.stationtype];
      }
    );
  },
  methods: {
    async getCars() {
      const times = await store.getters["stations/GET_OPENINGTIMES"];

      if (times) {
        this.loading = true;
        if (process.env.VUE_APP_STANDALONE == "true") {
          this.$router.push({ name: "Reservation_Step1" });
        } else {
          const baseUrl = window.location.origin;
          window.location.href =
            baseUrl +
            process.env.VUE_APP_BASE_URL +
            process.env.VUE_APP_RESERVATION_PATH +
            process.env.VUE_APP_RESERVATION_PATH_STEP1;
        }
      }
    },
    async setStationType(type) {
      this.stationtype = type;
      await store.dispatch("userSession/SET_STATIONTYPE", type).then(() => {
        store.dispatch(
          "userSession/SET_STATION",
          this.stationsFilter[type][0].id
        );
        store.dispatch("userSession/SET_STATION_END", "startend");

        this.stations = this.stationsFilter[type];
      });
    },
  },
});
</script>
