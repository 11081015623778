<template>
  <Header />
  <div>
    <div class="orm-container-fluid orm-reservationInfobar">
      <div class="orm-container">
        <div class="orm-row center-xl left-lg">
          <div class="orm-col-xs-12 orm-col-md-8 orm-col-lg-11 orm-padding20">
            <div v-if="form.customer" class="orm-pt-8">
              <span
                class="orm-customer-icon--circle orm-customer-icon--circlewhite"
                ><span
                  >{{ form.customer.firstname.substring(0, 1)
                  }}{{ form.customer.lastname.substring(0, 1) }}</span
                ></span
              >
              <div class="orm-customer-name">
                {{ form.customer.firstname }} <br />{{ form.customer.lastname }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class=" orm-reservationRatesExtras orm-background--grey orm-pt-70 orm-pb-56"
    >
      <div class="orm-container">
        <div class="orm-row">
          <div class="orm-col-xs-12 orm-mb-16">
            <div
              class="orm-background--white orm-reservation__data__container__inner"
            >
              <div class="orm-row">
                <div class="orm-col-xs-12">
                  <h2 class="orm-headline__h1">
                    {{ i18n("customer/mydata/title") || "Meine Daten" }}
                  </h2>
                  <div v-if="form.customer">
                    <div class="orm-row  orm-my-16  end-xs">
                      <div class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-4">
                        <h2 class="orm-headline__h3">
                          {{ i18n("form/personaldata") }}
                        </h2>
                        <div class="orm-form__block orm-form__container">
                          <form-input
                            my-id="formSalutation"
                            :my-label="i18n('form/label/salutation')"
                            :is-readonly="true"
                            :model-value="
                              store.getters['customer/GET_SALUTATION'](
                                form.customer.salutation_id
                              )
                            "
                          ></form-input>
                          <form-input
                            my-id="formName"
                            :my-label="i18n('form/label/name') || 'Name'"
                            :is-readonly="true"
                            v-model="form.customer.name1"
                          ></form-input>
                          <!-- <form-input
                            my-id="formFirstname"
                            :my-label="i18n('form/label/firstname')"
                            :is-readonly="true"
                            v-model="form.customer.firstname"
                          ></form-input>
                          <form-input
                            my-id="formLastname"
                            :my-label="i18n('form/label/lastname')"
                            :is-readonly="true"
                            v-model="form.customer.lastname"
                          ></form-input> -->
                          <form-input
                            my-id="formbirthdate"
                            :my-label="
                              i18n('form/label/birthdate') || 'Geburtsdatum'
                            "
                            :is-readonly="true"
                            v-model="form.customer.birth_date"
                          ></form-input>
                        </div>
                      </div>
                      <div class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-4">
                        <h2 class="orm-headline__h3">
                          {{ i18n("form/address") }}
                        </h2>
                        <div
                          class="orm-form__block orm-form__container orm-relative"
                        >
                          <div class="orm-border-left-mydata"></div>
                          <form-input
                            my-id="formStreet"
                            :my-label="i18n('form/label/street')"
                            :is-readonly="true"
                            v-model="form.customer.street"
                          ></form-input>
                          <form-input
                            my-id="formZipcode"
                            :my-label="i18n('form/label/zipcode')"
                            :is-readonly="true"
                            v-model="form.customer.zipcode"
                          ></form-input>
                          <form-input
                            my-id="formCity"
                            :my-label="i18n('form/label/city')"
                            :is-readonly="true"
                            v-model="form.customer.city"
                          ></form-input>
                          <form-input
                            my-id="formCompany"
                            :my-label="i18n('form/label/company')"
                            :is-readonly="true"
                            v-model="form.customer.company_name"
                          ></form-input>
                        </div>
                      </div>
                      <div class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-4">
                        <h2 class="orm-headline__h3">
                          {{ i18n("form/contact") || "Kontaktdaten" }}
                        </h2>
                        <div
                          class="orm-form__block orm-form__container orm-relative"
                        >
                          <div class="orm-border-left-mydata"></div>
                          <form-input
                            my-id="formEmail"
                            :my-label="i18n('form/label/email')"
                            my-type="email"
                            :is-readonly="true"
                            v-model="form.customer.email"
                          ></form-input>

                          <div class="orm-input orm-relative">
                            <label class="orm-form__label" for="formPassword">{{
                              i18n("form/label/password") || "Passwort"
                            }}</label>
                            <div
                              class="orm-input__control orm__edit_input"
                              id="formPassword"
                              type="text"
                              placeholder="..."
                              @click="changepwd = !changepwd"
                            >
                              ...
                            </div>
                            <i
                              class="far fa-edit orm__edit_icon"
                              @click="changepwd = !changepwd"
                            ></i>
                            <div
                              class="orm-login-widget text-left"
                              style="width:100%"
                              v-if="changepwd"
                              v-click-outside="handleClick"
                            >
                              <div v-if="!success">
                                <p class="orm-login-headline">
                                  {{
                                    i18n("customer/mydata/change_pw") ||
                                      "Passwort ändern"
                                  }}
                                </p>
                                <form-pwd
                                  my-id="password"
                                  :my-label="
                                    i18n('customer/mydata/old_pwd') ||
                                      'Altes Passwort'
                                  "
                                  :is-required="true"
                                  v-model="password.old"
                                ></form-pwd>
                                <form-pwd
                                  my-id="password"
                                  :my-label="
                                    i18n('customer/mydata/new_pwd') ||
                                      'Neues Passwort'
                                  "
                                  :is-required="true"
                                  v-model="password.new"
                                ></form-pwd>
                                <div v-if="errors" class="orm-login-error">
                                  <small v-for="error in errors" :key="error">{{
                                    error
                                  }}</small>
                                </div>
                                <div>
                                  <button
                                    class="orm-btn orm-login-widget-btnlistener"
                                    @click="submitnewpwd"
                                  >
                                    {{ i18n("general/edit") }}
                                  </button>
                                </div>
                              </div>
                              <div v-else>
                                <p class="orm-login-headline">
                                  {{
                                    i18n("customer/mydata/change_pw") ||
                                      "Passwort ändern"
                                  }}
                                </p>
                                <p>
                                  {{
                                    i18n("customer/login/changedpw") ||
                                      "Passwort erfolgreich geändert."
                                  }}
                                </p>
                                <button
                                  class="orm-btn orm-login-widget-btnlistener"
                                  @click="
                                    changepwd = false;
                                    success = false;
                                  "
                                >
                                  {{ i18n("general/close") || "Schließen" }}
                                </button>
                              </div>
                            </div>
                          </div>
                          <form-input
                            my-id="formPhone"
                            :my-label="i18n('form/label/phone')"
                            my-type="tel"
                            :is-readonly="true"
                            v-model="form.customer.phone_number"
                          ></form-input>
                          <form-input
                            my-id="formMobile"
                            :my-label="i18n('form/label/mobile') || 'Mobile'"
                            my-type="tel"
                            :is-readonly="true"
                            v-model="form.customer.mobile_phone"
                          ></form-input>
                        </div>
                      </div>
                      <div class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-4">
                        <div class="orm-form__block orm-form__container">
                          <p class="orm-pt-36">
                            {{
                              i18n("customer/mydata/contact_message") ||
                                "Möchten Sie Ihre persönlichen Daten ändern oder Ihren Account löschen?"
                            }}
                          </p>
                          <button
                            @click="showContact = !showContact"
                            class="orm-button__cta outline small"
                          >
                            {{
                              i18n("customer/mydata/button_contact") ||
                                "Kontaktieren Sie uns"
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ContactModal
    v-if="showContact"
    type="general"
    @dismiss="showContact = !showContact"
  />
</template>
<script>
import store from "@/store";
import Header from "@/components/widgets/Header.vue"; // @ is an alias to /src
import FormInput from "@/components/ui/INPUTS/FormInput.vue";
import FormPwd from "@/components/ui/INPUTS/FormPwd.vue"; // @ is an alias to /src
import ContactModal from "@/components/modals/modal-contact.vue";
import i18n from "@/mixins/mixins";
export default {
  name: "MyData",
  mixins: [i18n],
  components: { Header, FormInput, FormPwd, ContactModal },
  data() {
    return {
      form: Object,
      isLoggedin: true,
      changepwd: false,
      showContact: false,
      password: {
        old: "",
        new: "",
      },
      errors: [],
      success: false,
    };
  },
  setup() {
    return {
      store,
    };
  },
  async mounted() {
    this.form = await store.getters["customer/GET_CUSTOMER"];

    this.form.customer["birth_date"] = new Date(
      this.form.customer["birth_date"]
    ).toLocaleDateString("de-DE", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  },
  methods: {
    async getLoginState() {
      const isLoggedin = await store.getters["userSession/IS_LOGGEDIN"];
      if (!isLoggedin) {
        if (process.env.VUE_APP_STANDALONE == "true") {
          this.$router.push({ name: "Home" });
        } else {
          window.location.href = process.env.VUE_APP_BASE_URL;
        }
      }
    },
    handleClick(ev) {
      // console.log(ev.target.classList);
      if (
        !ev.target.classList.contains("orm-login-widget-btnlistener") &&
        !ev.target.classList.contains("orm__edit_icon") &&
        !ev.target.classList.contains("orm__edit_input")
      ) {
        this.changepwd = false;
      }
    },
    submitnewpwd() {
      if (this.password.new.length == 0 || this.password.old.length == 0) {
        this.errors = ["Bitte Passwort eingeben"];
        return;
      }
      store
        .dispatch("customer/CHANGEPASSWORD", {
          current_password: this.password.old,
          new_password: this.password.new,
        })
        .then((result) => {
          console.log("result", result);
          if (result) {
            this.success = true;
            this.errors = [];
            this.password.old = "";
            this.password.new = "";
          } else {
            const message =
              this.i18n("customer/login/error_changepw") ||
              "Aktuelles Passwort falsch.";
            this.errors.push(message);
          }
        });
    },
  },
};
</script>
