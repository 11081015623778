import apiCall from "../../api/crentAPI";

// const ENV =
//   process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "development"
//     ? "ORM_" + process.env.VUE_APP_CUSTOMER_NAME + "_"
//     : "ORM_";

const state = () => ({
  offers: [],
});

const getters = {
  GET_OFFERS: (state) => state.offers || null,
};

const actions = {
  async SET_OFFERS({ commit }) {
    const payload = "";
    const result = await apiCall.get(
      process.env.VUE_APP_GRAV_ENDPOINT + "/offers.json",
      payload,
      (data) => {
        commit("MUTATE_OFFERS", data);
      }
    );

    return result;
  },
};

// mutations
const mutations = {
  MUTATE_OFFERS(state, data) {
    state.offers = JSON.parse(JSON.stringify(data));
    return data;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
