<template>
  <div class="orm-container-fluid orm-reservationInfobar">
    <div class="orm-container">
      <div class="orm-row center-xl left-lg">
        <div class="orm-col-xs-12 orm-col-md-8 orm-col-lg-11 orm-padding20">
          <div class="orm-row">
            <stationpicker
              class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-3"
              :data="stations"
              type="start"
            />
            <stationpicker
              class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-3 text-center-lg middle-self-lg"
              :data="stations"
              type="end"
              v-if="
                store.getters['ORMgeneral/GET_PARAMETER'](
                  'BINET_ALLOW_ONEWAY'
                ) == 1
              "
            />
            <div class="orm-relative orm-col-xs-12 orm-col-lg-6">
              <datepicker :infobar="true" viewType="infobar" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import store from "@/store";
import datepicker from "@/components/ui/datepicker.vue"; // @ is an alias to /src

import stationpicker from "@/components/ui/stationpicker.vue"; // @ is an alias to /src

export default defineComponent({
  name: "reservationheader",
  components: { stationpicker, datepicker },
  setup() {
    let stations = store.getters["stations/GET_STATIONS"];
    const filtered = store.getters["stations/GET_STATIONS_FILTERED"];
    const type = store.getters["userSession/GET_STATIONTYPE"];
    if (type) {
      stations = filtered[type];
    }

    return {
      stations,
      store,
    };
  },
  data() {
    return {
      stationSelected: store.getters["userSession/GET_STATION"],
    };
  },
  props: {
    data: Object,
    type: String,
    step: Number,
  },
});
</script>
