<template>
  <div v-click-outside="closeEvent">
    <div
      class="orm-button__dropdown"
      :class="{ closed: !closed }"
      @click="closed = !closed"
    >
      <span v-if="title">{{ title }}</span
      ><span v-else>{{ i18n("general/vehicleType") }}</span>
      <i
        class="fa orm-pl-8"
        :class="{ 'fa-caret-up': !closed, 'fa-caret-down': closed }"
      ></i>
      <div class="orm-filter__labels" v-if="!hideSelection">
        <span
          v-for="label in labelGroup"
          :key="label"
          class="orm-filter__label"
        >
          {{
            filterType === "reservation"
              ? this.i18n("customer/reservationtype_short/" + label) || label
              : label
          }}</span
        >
      </div>
      <div class="orm-filter__dropdown" :class="{ closed: closed }">
        <div @change="setFilter('all')" class="orm-filter__dropdown__item">
          <form-checkbox
            my-fontbase="14"
            my-name="all"
            :my-label="i18n('general/all')"
            v-model="selectedAll"
            :is-readonly="selectedAll"
          ></form-checkbox>
        </div>
        <div
          v-for="group in groupFilter"
          :key="group"
          @change="setFilter(group)"
          class="orm-filter__dropdown__item"
        >
          <form-checkbox
            my-fontbase="14"
            :my-name="group"
            :my-label="
              filterType === 'reservation'
                ? this.i18n('customer/reservationtype_short/' + group) || group
                : group
            "
            v-model="selectedGroup[group]"
          ></form-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import FormCheckbox from "@/components/ui/INPUTS/FormCheckbox.vue";
import store from "@/store";
import i18n from "@/mixins/mixins";

export default defineComponent({
  name: "filterSelect",
  mixins: [i18n],
  props: {
    groupFilter: Array,
    preselect: Object,
    hideSelection: {
      default: false,
      type: Boolean,
    },
    title: String,
    filterType: {
      default: "carcategory",
      type: String,
    },
  },
  emits: ["update:dataPool"],
  components: {
    FormCheckbox,
  },
  data() {
    return {
      selectedAll: true,
      selectedGroup: {},
      closed: true,
      labelGroup: [this.i18n("general/all")],
    };
  },
  mounted() {
    this.selectedGroup = JSON.parse(
      JSON.stringify(store.getters["userSession/GET_CARCATEGORY"])
    );
    // if(this.){
    // const status = this.i18n("customer/reservationtype_short/" + key);
    // }

    if (Object.keys(this.selectedGroup).length) {
      this.selectedAll = false;
      this.labelGroup = [];
      for (const key in this.selectedGroup) {
        if (this.selectedGroup[key] == true) {
          this.labelGroup.push(key);
        }
      }
    }
  },
  methods: {
    setFilter(group) {
      if (group == "all") {
        this.selectedGroup = {};
        this.labelGroup = [this.i18n("general/all")];
        this.selectedAll = true;
      } else {
        let reset = true;
        this.labelGroup = [];
        for (const key in this.selectedGroup) {
          if (this.selectedGroup[key] == true) {
            reset = false;
            this.labelGroup.push(key);
          }
        }
        if (reset == true) {
          this.selectedAll = true;
          this.selectedGroup = {};
          this.labelGroup = [this.i18n("general/all")];
        } else {
          this.selectedAll = false;
        }
      }
      if (this.filterType == "carcategory") {
        store.dispatch(
          "userSession/SET_CARCATEGORY",
          JSON.parse(JSON.stringify(this.selectedGroup))
        );
      }
      if (this.filterType == "equipment") {
        store.dispatch(
          "userSession/SET_EQUIPMENT",
          JSON.parse(JSON.stringify(this.selectedGroup))
        );
      }

      this.$emit("update:dataPool", {
        selectedAll: this.selectedAll,
        selectedGroup: this.selectedGroup,
      });
    },
    closeEvent() {
      this.closed = true;
    },
  },
});
</script>
