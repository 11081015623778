<template>
  <Header />
  <div>
    <div class="orm-container-fluid orm-reservationInfobar">
      <div class="orm-container">
        <div class="orm-row center-xl left-lg">
          <div class="orm-col-xs-12 orm-col-md-8 orm-col-lg-11 orm-padding20">
            <p style="margin-top:20px;">
              {{ i18n("customer/booking_list/title") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="orm-container-fluid orm-reservationCarselect">
      <div class="orm-container">
        <div class="orm-row">
          <div class="orm-carfilter__element orm-col-xs-12 orm-col-sm-12">
            <FilterSelect
              v-if="bookings"
              :title="i18n('customer/booking_list/show_bookings')"
              filter-type="reservation"
              :groupFilter="Object.keys(bookings)"
              @update:dataPool="updateFilter"
            />
          </div>
          <div
            class="orm-mb-16 orm-col-xs-12 orm-col-sm-12"
            v-for="(bookingtype, key) in bookings"
            :key="bookingtype"
          >
            <div v-if="visibletypes[key]">
              <div class="orm-bookings-type-label">
                {{ i18n("customer/reservationtype_short/" + key) || key }}
              </div>
              <div
                v-for="item in bookingtype"
                :key="item.id"
                :class="
                  'orm-row orm-bookings-type orm-bookings-type--' + item.status
                "
              >
                <div
                  class="orm-col-xs-12 orm-col-md-3 orm-col-lg-2 text-center"
                >
                  <Slider
                    ratio="16/7"
                    v-if="item.planned_group.images"
                    :images="item.planned_group.images"
                  />
                </div>
                <div class="orm-col-xs-12 orm-col-md-9 orm-col-lg-10">
                  <div class="orm-row orm-eq-h">
                    <div
                      class="orm-col-xs-7 orm-col-md-8 orm-col-lg-3 middle-self-xs orm-py-16 orm-py-lg-0"
                    >
                      <div class="orm-row">
                        <div class="orm-col-xs-6 orm-col-lg-5">
                          <p
                            class="orm-bookinglist-title"
                            v-if="!item.delivery_street"
                          >
                            {{ i18n("customer/booking_list/pickup") }}
                          </p>
                          <p class="orm-bookinglist-title" v-else>
                            {{
                              i18n(
                                "customer/booking_list/deliverypickup/delivery"
                              )
                            }}
                          </p>
                          {{
                            new Date(item.startdate).toLocaleDateString(
                              "de-DE",
                              {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              }
                            )
                          }}
                          <p class="orm-bookinglist-subline">
                            {{ formatTime(item.startdate) }}
                            {{ i18n("general/hour") }}
                          </p>
                        </div>
                        <div class="orm-col-xs-6 orm-col-lg-5">
                          <p
                            class="orm-bookinglist-title"
                            v-if="!item.delivery_street"
                          >
                            {{ i18n("customer/booking_list/delivery") }}
                          </p>
                          <p class="orm-bookinglist-title" v-else>
                            {{
                              i18n(
                                "customer/booking_list/deliverypickup/pickup"
                              )
                            }}
                          </p>
                          {{
                            new Date(item.planned_enddate).toLocaleDateString(
                              "de-DE",
                              {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              }
                            )
                          }}
                          <p class="orm-bookinglist-subline">
                            {{ formatTime(item.planned_enddate) }}
                            {{ i18n("general/hour") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="orm-col-xs-5 orm-col-md-4 orm-col-lg-2 middle-self-xs orm-py-16 orm-py-lg-0"
                    >
                      <p class="orm-bookinglist-title">
                        {{ i18n("customer/booking_list/station") }}
                      </p>
                      {{ item.startstation.name }}
                      <p class="orm-bookinglist-subline">
                        {{ item.startstation.street }}
                      </p>
                    </div>
                    <div
                      class="orm-col-xs-7 orm-col-md-4 orm-col-lg-3 middle-self-xs orm-pb-16 orm-pb-lg-0"
                    >
                      <div v-if="item.status === 'contract_co'" class="orm-row">
                        <div class="orm-col-xs-6">
                          <p class="orm-bookinglist-title">
                            {{ i18n("customer/booking_list/vehicle") }}
                          </p>
                          {{
                            stripHTML(
                              item.planned_group[
                                env.VUE_APP_RESERVATION_CARGROUP
                              ]
                            )
                          }}
                          <p class="orm-bookinglist-subline">
                            {{
                              stripHTML(
                                item.planned_group[
                                  env.VUE_APP_RESERVATION_CARCATEGORY
                                ]
                              )
                            }}
                          </p>
                        </div>
                        <div class="orm-col-xs-6 hide-mobile-only">
                          <p class="orm-bookinglist-title">
                            {{ i18n("customer/booking_list/license") }}
                          </p>
                          {{ item.vehicle.license_plate }}
                          <p class="orm-bookinglist-subline">
                            {{ i18n("customer/booking_list/sprit") }}:
                            {{ item.vehicle.fuel_type }}
                          </p>
                        </div>
                      </div>
                      <div v-else>
                        <p class="orm-bookinglist-title">
                          {{ i18n("customer/booking_list/vehicle") }}
                        </p>
                        {{
                          stripHTML(
                            item.planned_group[env.VUE_APP_RESERVATION_CARGROUP]
                          )
                        }}
                        <p class="orm-bookinglist-subline">
                          {{
                            stripHTML(
                              item.planned_group[
                                env.VUE_APP_RESERVATION_CARCATEGORY
                              ]
                            )
                          }}
                        </p>
                      </div>
                    </div>

                    <div
                      class="orm-col-xs-5 orm-col-md-4 orm-col-lg-2 middle-self-xs orm-pb-16 orm-pb-lg-0"
                    >
                      <p class="orm-bookinglist-title">
                        {{ i18n("customer/booking_list/bookingnr") }}
                      </p>
                      {{ item.id }}
                      <p class="orm-bookinglist-subline">
                        &nbsp;
                      </p>
                    </div>
                    <div
                      class="orm-col-xs-6 orm-mb-16 show-mobile-only"
                      v-if="item.status == 'contract_co'"
                    >
                      <p class="orm-bookinglist-title">
                        {{ i18n("customer/booking_list/license") }}
                      </p>
                      {{ item.vehicle.license_plate }}
                      <p
                        class="orm-bookinglist-subline"
                        v-if="item.status == 'contract_co'"
                      >
                        {{ i18n("customer/booking_list/sprit") }}:
                        {{ item.vehicle.fuel_type }}
                      </p>
                    </div>
                    <div
                      class="orm-col-xs-12 orm-mb-16  orm-mb-md-0 orm-col-md-4 orm-col-lg-2 middle-self-xs"
                    >
                      <button
                        @click="openContact(item)"
                        class="orm-button__cta outline small"
                      >
                        <i class="fa fa-eye"></i>
                        {{ i18n("customer/booking_list/button_show") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="orm-preload-page orm-preload-page-static orm-mt-64"
            v-if="loading"
          >
            <div class="orm-loader-spinner">
              <i class="fa fa-spinner-third orm-rotate360"></i>
            </div>
          </div>

          <div
            v-if="Object.keys(bookings).length === 0 && !loading"
            class="orm-col-xs-12 text-center"
          >
            <p>
              {{
                i18n("customer/booking_list/no_bookings") ||
                  "Keine Buchungen gefunden"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ReservationModal
    v-if="showContact"
    :reservation="reservation"
    @dismiss="showContact = false"
  />
</template>
<script>
import store from "@/store";
import Header from "@/components/widgets/Header.vue"; // @ is an alias to /src
import FilterSelect from "@/components/ui/filter-select.vue";
import ReservationModal from "@/components/modals/modal-reservation-detail.vue";
import Slider from "@/components/ui/image-slider.vue";
import i18n from "@/mixins/mixins";
import stripHTML from "@/mixins/mixins";
import { formateDate, formatTime } from "@/constants/formater";
export default {
  name: "Bookings",
  mixins: [i18n, stripHTML],
  components: { Header, FilterSelect, ReservationModal, Slider },
  setup() {
    return {
      formateDate,
      formatTime,
    };
  },
  data() {
    return {
      bookings: [],
      visibletypes: {},
      env: process.env,
      reservation: {},
      showContact: false,
      loading: true,
    };
  },
  async mounted() {
    await store.dispatch("customer/GET_BOOKINGS").then((result) => {
      for (const key in result) {
        if (Object.hasOwnProperty.call(result, key)) {
          if (result[key].length === 0) {
            delete result[key];
          } else {
            if (!this.visibletypes[key]) this.visibletypes[key] = {};
            this.visibletypes[key]["visible"] = true;
          }
        }
      }
      this.bookings = result;
      this.loading = false;
    });
  },
  methods: {
    async getLoginState() {
      const isLoggedin = await store.getters["userSession/IS_LOGGEDIN"];
      if (!isLoggedin) {
        if (process.env.VUE_APP_STANDALONE == "true") {
          this.$router.push({ name: "Home" });
        } else {
          window.location.href = process.env.VUE_APP_BASE_URL;
        }
      }
    },
    updateFilter(ev) {
      const visibletypes = this.visibletypes;
      for (const key in visibletypes) {
        if (ev.selectedAll) {
          visibletypes[key] = true;
        } else {
          visibletypes[key] = false;
        }
      }
      if (!ev.selectedAll) {
        for (const key in ev.selectedGroup) {
          this.visibletypes[key] = true;
        }
      }
    },
    openContact(reservation) {
      this.reservation = reservation;
      this.showContact = true;
    },
  },
};
</script>
