<template>
  <Header />
  <!-- <CustomerWidget /> -->
  <SearchBar type="ALL" />
  <!-- <SearchBar type="RENT" />
  <SearchBar type="SHARE" />
  <SearchBar type="ABO" />
  <SearchBar type="SPECIAL" /> -->
  <CarCategorySlider
    v-if="env.VUE_APP_CARCATEGORYSLIDER === 'true'"
    teleport="crentCarCategorySlider"
    :show-category="true"
  />
  <LocationMap
    v-if="env.VUE_APP_LOCATIONMAP === 'true' && checkSize('bigger', 768)"
  />
  <SearchMapModule
    v-if="env.VUE_APP_LOCATIONMAP === 'true' && checkSize('smaller', 768)"
  />
  <OffersSlider :type="1" />

  <CarFilter v-if="env.VUE_APP_CARFILTER === 'true'" />

  <!-- <OffersSlider :type="2" /> -->

  <Footer />
</template>

<script>
import { defineComponent } from "vue";

import OffersSlider from "@/components/widgets/OffersSlider.vue"; // @ is an alias to /src
import CarCategorySlider from "@/components/widgets/CarCategorySlider.vue"; // @ is an alias to /src
import LocationMap from "@/components/widgets/LocationMap.vue"; // @ is an alias to /src
import SearchMapModule from "@/components/widgets/SearchMapModule.vue"; // @ is an alias to /src
import SearchBar from "@/components/widgets/SearchBar.vue"; // @ is an alias to /src
import CarFilter from "@/components/widgets/CarFilter.vue"; // @ is an alias to /src
// import CustomerWidget from "@/components/widgets/CustomerWidget.vue"; // @ is an alias to /src

import Footer from "@/components/widgets/Footer.vue"; // @ is an alias to /src
import Header from "@/components/widgets/Header.vue"; // @ is an alias to /src

import store from "@/store";

//import store from "../store";

export default defineComponent({
  name: "Home",
  components: {
    OffersSlider,
    CarCategorySlider,
    CarFilter,
    LocationMap,
    SearchBar,
    Footer,
    Header,
    SearchMapModule,
  },
  data() {
    return {
      env: process.env,
      WinSize: 0,
    };
  },
  mounted() {
    store.dispatch("userSession/SET_CARCATEGORY", {});
    store.dispatch("userSession/SET_EQUIPMENT", {});
    store.dispatch("userSession/SET_CARTYPE", "");
    store.dispatch("reservation/DELETE_TARIF");
    store.dispatch("payment/DELETE_CONARDIS");
    store.dispatch("reservation/DELETE_SUCCESS_RESERVATION");
    this.winWidth();
    window.addEventListener("resize", this.winWidth);
  },
  methods: {
    winWidth() {
      this.WinSize = window.innerWidth;
    },
    checkSize(minmax, size) {
      const myEle = document.getElementById("crentLocationMap");
      if (!myEle) return false;
      if (minmax === "bigger" && this.WinSize >= size) {
        return true;
      }
      if (minmax === "smaller" && this.WinSize < size) {
        return true;
      }
    },
  },
});
</script>
