const ENV =
  process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "development"
    ? "ORM_" + process.env.VUE_APP_CUSTOMER_NAME + "_"
    : "ORM_";
import apiCall from "../../api/crentAPI";
import store from "@/store";

const logcss =
  "line-height: 30px; font-size: 12px; background-color: rgb(230, 7, 11); color: #fff; padding: 3px .5em; border-radius: .25em;";

function include(arr) {
  for (const val of arr) {
    if (val[1] === false) {
      return false;
    }
  }
  return true;
}

const state = () => ({
  general: {
    clientdata: {},
    language: window.sys_language || "DE",
    translation: {},
    APPloaded: false,
    ORMloaded: false,
    loading: { from: 0, to: 10 },
    appVersion: process.env.VERSION || 0,
  },
});

// getters
const getters = {
  GET_CLIENT_DATA: (state) => {
    const gapikey = store.getters["ORMgeneral/GET_PARAMETER"](
      "SGOOGLE_MAPS_APIKEY"
    );
    const data = JSON.parse(JSON.stringify(state.general.clientdata));
    if (gapikey) data.maps_apikey = gapikey;
    return data;
  },
  GET_LANGUAGE: (state) => state.general.language || [],
  GET_TRANSLATIONS: (state) => state.general.translation || [],
  GET_TRANSLATION: (state) => (id) => {
    const words = id.split("/");
    let translate = null;
    switch (words.length) {
      case 1:
        if (!(words[0] in state.general.translation)) {
          translate = null;
          break;
        }
        translate = state.general.translation[words[0]];
        break;
      case 2:
        if (!(words[0] in state.general.translation)) {
          translate = null;
          break;
        } else {
          if (typeof state.general.translation[words[0]] !== "object") {
            translate = null;
            break;
          }
          if (!(words[1] in state.general.translation[words[0]])) {
            translate = null;
            break;
          }
        }
        translate = state.general.translation[words[0]][words[1]];
        break;
      case 3:
        if (!(words[0] in state.general.translation)) {
          translate = null;
          break;
        } else {
          if (!(words[1] in state.general.translation[words[0]])) {
            translate = null;
            break;
          } else {
            if (
              typeof state.general.translation[words[0]][words[1]] !== "object"
            ) {
              translate = null;
              break;
            }
            if (!(words[2] in state.general.translation[words[0]][words[1]])) {
              translate = null;
              break;
            }
          }
        }
        translate = state.general.translation[words[0]][words[1]][words[2]];
        break;
      case 4:
        if (!(words[0] in state.general.translation)) {
          translate = null;
          break;
        } else {
          if (!(words[1] in state.general.translation[words[0]])) {
            translate = null;
            break;
          } else {
            if (!(words[2] in state.general.translation[words[0]][words[1]])) {
              translate = null;
              break;
            } else {
              if (
                typeof state.general.translation[words[0]][words[1]][
                  words[2]
                ] !== "object"
              ) {
                translate = null;
                break;
              }
              if (
                !(
                  words[3] in
                  state.general.translation[words[0]][words[1]][words[2]]
                )
              ) {
                translate = null;
                break;
              }
            }
          }
        }
        translate =
          state.general.translation[words[0]][words[1]][words[2]][words[3]];
        break;
      default:
        translate = null;
        break;
    }
    return translate;
  },
  GET_APPLOADED: (state) => state.general.APPloaded,
  GET_ORMLOADED: (state) => state.general.ORMloaded,
  GET_LOADING: (state) => state.general.loading,
  GET_APPVERSION: (state) => state.general.appVersion,
  GET_LOCAL_APPVERSION: () => localStorage.getItem(ENV + "appVersion") || "0",
};

// actions
const actions = {
  async initAPP({ dispatch, getters, commit }) {
    const appVersion = getters["GET_APPVERSION"];
    const localVersion = getters["GET_LOCAL_APPVERSION"];
    const token = await store.getters["userSession/GET_TOKEN"];

    if (appVersion !== localVersion) {
      await dispatch("CLEAR_STORAGE", appVersion);
    }

    const loading = await Promise.all([
      await store
        .dispatch("ORMgeneral/SET_API_URL")
        .then(commit("MUTATE_LOADING")),
      token.token ? await store.dispatch("customer/GET_USER_DATA") : false,
      await store
        .dispatch("general/SET_CLIENT_DATA")
        .then(commit("MUTATE_LOADING")),
      await store
        .dispatch("userSession/SET_LOCALSTORAGE")
        .then(commit("MUTATE_LOADING")),
      await store
        .dispatch("general/SET_TRANSLATION")
        .then(commit("MUTATE_LOADING")),
    ]).catch((err) => {
      console.log(err);
    });
    let response = loading;
    if (include(loading)) {
      commit("MUTATE_APPLOADED", true);
      if (process.env.NODE_ENV === "production") response = "loaded";
      console.log("%c" + "initAPP: " + response, logcss);
      return loading;
    } else {
      console.log("%c" + "initAPP: " + response, logcss);
      return false;
    }
  },
  async initORMCORE({ commit }) {
    const loading = await Promise.all([
      store.dispatch("stations/SET_ALLSTATIONS").then(commit("MUTATE_LOADING")),
      store
        .dispatch("stations/SET_OPENINGTIMES")
        .then(commit("MUTATE_LOADING")),
      store.dispatch("ORMgeneral/SET_MODULES").then(commit("MUTATE_LOADING")),
      store.dispatch("ORMgeneral/SET_PARAMETER").then(commit("MUTATE_LOADING")),
    ]).catch((err) => {
      console.log("error", err);
    });
    let response = loading;
    if (include(loading)) {
      commit("MUTATE_ORMLOADED", true);
      if (process.env.NODE_ENV === "production") response = "loaded";
      console.log("%c" + "initORMCORE: " + response, logcss);
      return loading;
    } else {
      console.log("%c" + "initORMCORE: " + response, logcss);
      return false;
    }
  },
  async initORMADDS({ commit }) {
    const loading = await Promise.all([
      await store
        .dispatch("customer/SET_COUNTRIES")
        .then(commit("MUTATE_LOADING")),
      await store
        .dispatch("customer/SET_SALUTATIONS")
        .then(commit("MUTATE_LOADING")),
    ]).catch((err) => {
      console.log(err);
    });
    let response = loading;
    if (include(loading)) {
      if (process.env.NODE_ENV === "production") response = "loaded";
      console.log("%c" + "initORMADDS: " + response, logcss);
      // commit("MUTATE_ORMLOADED", true);
      return loading;
    } else {
      console.log("%c" + "initORMADDS: " + response, logcss);
      return false;
    }
  },
  async SET_CLIENT_DATA({ commit, state }) {
    let response = "";
    await apiCall.getHeaders(
      process.env.VUE_APP_GRAV_ENDPOINT +
        "/" +
        state.general.language.toLowerCase() +
        "/data.json",
      "",
      (data) => {
        if (data.status == 200) {
          response = true;
          commit("MUTATE_CLIENTDATA", data.data);
        } else {
          response = false;
        }
      }
    );
    return ["general/SET_CLIENT_DATA", response];
  },
  async SET_TRANSLATION({ commit, state }) {
    // const translation = JSON.parse(sessionStorage.getItem(ENV + "translation"));

    // if (translation && Object.keys(translation).length > 0) {
    //   commit(
    //     "MUTATE_TRANSLATION",
    //     JSON.parse(sessionStorage.getItem(ENV + "translation"))
    //   );
    //   return ["SET_TRANSLATION", true];
    // }
    let response = "";
    await apiCall.getHeaders("/api/rent/translations", "", async (data) => {
      if (data.status == 200) {
        if (data.data === "Tag" || data.data.ORMLF.length == 0) {
          response = false;
        } else {
          response = true;
          commit("MUTATE_TRANSLATION", data.data.ORMLF);
        }
      } else {
        response = false;
      }
    });
    if (!response) {
      console.log("FALLBACK i18n json");
      await apiCall.getHeaders(
        process.env.VUE_APP_GRAV_ENDPOINT +
          "/" +
          state.general.language.toLowerCase() +
          "/i18n.json",
        "",
        (data) => {
          if (data.status == 200) {
            response = true;
            commit("MUTATE_TRANSLATION", data.data);
          } else {
            response = false;
          }
        }
      );
    }

    return ["general/SET_TRANSLATION", response];
  },
  CLEAR_STORAGE({ commit }, version) {
    store.dispatch("reservation/DELETE_TARIF");

    localStorage.removeItem(ENV + "userSession");
    localStorage.removeItem(ENV + "selectedStationCargroup");
    localStorage.removeItem(ENV + "stations");
    localStorage.removeItem(ENV + "groups");

    sessionStorage.removeItem(ENV + "groupimages");
    sessionStorage.removeItem(ENV + "reservationSuccess");
    sessionStorage.removeItem(ENV + "selectedSalutations");
    sessionStorage.removeItem(ENV + "selectedCustomer");
    sessionStorage.removeItem(ENV + "selectedPayment");

    commit("MUTATE_APPVERSION", version);
  },
};

// mutations
const mutations = {
  MUTATE_CLIENTDATA(state, data) {
    state.general.clientdata = JSON.parse(JSON.stringify(data));
    return data;
  },
  MUTATE_TRANSLATION(state, data) {
    state.general.translation = JSON.parse(JSON.stringify(data));
    // sessionStorage.setItem(ENV + "translation", JSON.stringify(data));
    return data;
  },
  MUTATE_APPLOADED(state, data) {
    state.general.APPloaded = JSON.parse(JSON.stringify(data));
  },
  MUTATE_ORMLOADED(state, data) {
    state.general.ORMloaded = JSON.parse(JSON.stringify(data));
  },
  MUTATE_LOADING(state) {
    state.general.loading.from++;
  },
  MUTATE_APPVERSION(state) {
    localStorage.setItem(ENV + "appVersion", state.general.appVersion);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
