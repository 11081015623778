import { defineComponent } from "vue";
import store from "../store";

export default defineComponent({
  methods: {
    i18n(id) {
      return store.getters["general/GET_TRANSLATION"](id);
    },
    stripHTML(value) {
      const div = document.createElement("div");
      div.innerHTML = value;
      const text = div.textContent || div.innerText || "";
      return text;
    },
  },
});
