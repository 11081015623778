<template>
  <div>
    <ErrorModal
      v-if="error.show"
      :data="error.data"
      :load-times="error.loadTimes"
      :reset-session="error.resetSession"
    />
    <ConcardisModal
      v-if="showConcardis"
      v-model="paymentData"
      @retry="submit()"
      @cancel="cancel()"
      @pay="pay()"
    />
    <reservationheader :step="4" />
    <reservationinfobar step="4" />
    <div class=" orm-reservationRatesExtras orm-background--grey orm-py-16">
      <div class="orm-container">
        <div class="orm-row">
          <div class="orm-col-xs-12 orm-col-md-6 orm-mb-16">
            <div
              class="orm-background--white orm-reservation__data__container__inner orm-h100"
              v-if="carID"
            >
              <div class="orm-row orm-h100">
                <div class="orm-col-xs-12">
                  <h2 class="orm-headline__h1">
                    {{ i18n("step4/reservationdata") }}
                  </h2>
                </div>
                <div class="orm-col-xs-12 orm-col-sm-6 orm-mb-16">
                  <div
                    class="orm-borderRadius__small orm-reservationFinal__datebox orm-py-16 orm-px-16"
                  >
                    <div class="orm-mb-16">
                      <strong>{{ i18n("general/pickup") }}</strong>
                    </div>
                    <div class="orm-reservationFinal__flex orm-mb-8">
                      <span class="orm-pr-16"
                        ><i class="fas fa-map-marker-alt"></i
                      ></span>
                      <div>
                        <div>
                          <strong> {{ startstation.name }}</strong>
                        </div>
                        <small>
                          {{ startstation.street }}, {{ startstation.zipcode }}
                          {{ startstation.city }}</small
                        >
                      </div>
                    </div>
                    <div class="orm-reservationFinal__flex orm-mb-8">
                      <span class="orm-pr-16"
                        ><i class="fal fa-calendar-alt"></i
                      ></span>
                      <div>
                        <strong> {{ formateDateShort(date.start) }}</strong>
                      </div>
                    </div>
                    <div class="orm-reservationFinal__flex">
                      <span class="orm-pr-16"
                        ><i class="fal fa-clock"></i
                      ></span>
                      <div>
                        <strong>
                          {{ time.start }} {{ i18n("general/hour") }}</strong
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="orm-col-xs-12 orm-col-sm-6  orm-mb-16">
                  <div
                    class="orm-borderRadius__small orm-reservationFinal__datebox orm-py-16 orm-px-16"
                  >
                    <div class="orm-mb-16">
                      <strong>{{ i18n("general/delivery") }}</strong>
                    </div>
                    <div class="orm-reservationFinal__flex orm-mb-8">
                      <span class="orm-pr-16"
                        ><i class="fas fa-map-marker-alt"></i
                      ></span>
                      <div>
                        <div>
                          <strong> {{ endstation.name }}</strong>
                        </div>
                        <small>
                          {{ endstation.street }}, {{ endstation.zipcode }}
                          {{ endstation.city }}</small
                        >
                      </div>
                    </div>
                    <div class="orm-reservationFinal__flex orm-mb-8">
                      <span class="orm-pr-16"
                        ><i class="fal fa-calendar-alt"></i
                      ></span>
                      <div>
                        <strong> {{ formateDateShort(date.end) }}</strong>
                      </div>
                    </div>
                    <div class="orm-reservationFinal__flex">
                      <span class="orm-pr-16"
                        ><i class="fal fa-clock"></i
                      ></span>
                      <div>
                        <strong>
                          {{ time.end }} {{ i18n("general/hour") }}</strong
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="orm-col-xs-12 orm-col-sm-7 bottom-self-xs"></div>
                <div
                  class="orm-col-xs-12 orm-col-sm-5 text-right bottom-self-xs"
                >
                  <button
                    @click="edit('1')"
                    class="orm-button__cta outline small"
                  >
                    {{ i18n("general/edit") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="orm-col-xs-12 orm-col-md-6 orm-pl-8 orm-mb-16">
            <div
              class="orm-background--white orm-reservation__data__container__inner orm-h100"
              v-if="carID"
            >
              <div class="orm-row orm-h100">
                <div class="orm-col-xs-12">
                  <h2 class="orm-headline__h1">{{ i18n("step4/vehicle") }}</h2>
                </div>
                <div class="orm-col-xs-12 orm-col-sm-7">
                  <div class="" v-if="images">
                    <Slider v-if="images" :images="images" />
                  </div>
                </div>
                <div
                  class="orm-col-xs-12 orm-col-sm-5 orm-border__left orm-pl-sm-16"
                  style="align-self:center;"
                >
                  <div class="orm-carequip">
                    <p
                      class="orm-equip__txt__icons"
                      v-if="selectedCargroupInfo"
                    >
                      <equipIcon
                        :key="selectedCargroupInfo"
                        :data="selectedCargroupInfo.features"
                        :shortlist="false"
                      />
                    </p>
                    <p
                      class="orm-equip__txt__freemiles"
                      v-if="price.length > 0"
                    >
                      <span v-if="price && !price[0].all_km_inclusive"
                        >{{ i18n("step4/inclusive") }} {{ price[0].km_free }}
                        {{ i18n("step4/freeKilometers") }}</span
                      >
                      <span v-else
                        >{{ i18n("step4/inclusive") }}
                        {{ i18n("step4/unlimited") }}
                        {{ i18n("step4/freeKilometers") }}</span
                      >
                    </p>
                  </div>
                </div>

                <div class="orm-col-xs-12 orm-col-sm-7 bottom-self-xs">
                  <div
                    v-if="selectedCargroupInfo"
                    class="orm-cargroup__container"
                  >
                    <h3 class="orm-headline__h2">
                      <div>
                        {{
                          stripHTML(
                            selectedCargroupInfo[
                              env.VUE_APP_RESERVATION_CARGROUP
                            ]
                          )
                        }}
                      </div>
                    </h3>
                    <p
                      class="orm-headline__h3__subheadline orm-mb-0 orm-relative"
                    >
                      {{
                        stripHTML(
                          selectedCargroupInfo[
                            env.VUE_APP_RESERVATION_CARCATEGORY
                          ]
                        )
                      }}
                      <i class="fal fa-question-circle orm-cargroup__tooltip"
                        ><span
                          class="orm-cargroup__tooltip_text orm-cargroup__tooltip-bottom"
                          >{{ i18n("cargroup/noguarantee") }}</span
                        ></i
                      >
                    </p>
                  </div>
                </div>
                <div
                  class="orm-col-xs-12 orm-col-sm-5 text-right bottom-self-xs"
                >
                  <button
                    @click="edit('1')"
                    class="orm-button__cta outline small"
                  >
                    {{ i18n("general/edit") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- TARIFE -->

        <div class="orm-row">
          <div class="orm-col-xs-12 orm-mb-16">
            <div
              class="orm-background--white orm-reservation__data__container__inner orm-h100"
              v-if="carID"
            >
              <div class="orm-row">
                <div class="orm-col-xs-12 orm-col-md-7 orm-list__element">
                  <h2
                    class="orm-headline__h1"
                    v-html="i18n('step4/tarifs')"
                  ></h2>
                  <div v-if="selectedCargroup">
                    <h3 class="orm-headline__h3 orm-mb-8">
                      {{ i18n("step4/tarif") }}
                    </h3>

                    <div
                      class="orm-list__bold orm-my-8"
                      v-for="tarif in selectedCargroup.main_rates"
                      :key="tarif"
                    >
                      <div class="orm-row">
                        <div class="orm-col-xs-8">
                          <span v-if="tarif.rate == 'KM'">
                            {{ tarif.amount }}&nbsp;{{ tarif.description }}
                            <span class="orm-small"
                              >({{ convertCurrency(tarif.long_price) }}/ KM
                              )</span
                            >
                            <div
                              class="orm-small"
                              v-if="tarif.description2"
                              v-html="tarif.description2"
                            ></div>
                          </span>
                          <span v-else
                            ><span v-if="tarif.amount >= 1"
                              >{{ tarif.amount }}x </span
                            >{{ tarif.description }}
                            <span class="orm-small"
                              >({{ convertCurrency(tarif.long_price) }}/{{
                                tarif.unit
                              }})</span
                            >
                          </span>
                        </div>
                        <div class="orm-col-xs-4 orm-price__right">
                          {{ convertCurrency(tarif.total_long_price) }}
                        </div>
                      </div>
                      <div v-if="tarif.position == 1" class="orm-row orm-my-8">
                        <div class="orm-col-xs-8">
                          <span v-if="!tarif.all_km_inclusive"
                            >{{ tarif.km_free }}
                            {{ i18n("step4/freeKilometers") }}
                          </span>
                          <span v-else>
                            {{ i18n("step4/unlimited") }}
                            {{ i18n("step4/freeKilometers") }}
                          </span>
                        </div>
                        <div class="orm-col-xs-4 orm-price__right">
                          {{ i18n("step4/inclusive_long") }}
                        </div>
                      </div>
                    </div>

                    <div v-if="insurances.length > 0">
                      <h3 class="orm-headline__h3 orm-mt-32 orm-mb-8">
                        {{ i18n("step4/insurances") }}
                      </h3>
                      <div
                        v-for="insurance in insurances"
                        :key="insurance"
                        class="orm-list__bold orm-my-8"
                      >
                        <div class="orm-row">
                          <div class="orm-col-xs-8">
                            {{ insurance.description }}<br />
                            <span class="orm-small"
                              >{{ insurance.description2 }} ({{
                                convertCurrency(insurance.long_price)
                              }}/{{ insurance.unit }})</span
                            >
                          </div>
                          <div class="orm-col-xs-4 orm-price__right">
                            {{ convertCurrency(insurance.total_long_price) }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="subrates.length > 0">
                      <h3 class="orm-headline__h3 orm-mt-32 orm-mb-8">
                        {{ i18n("step4/extras") }}
                      </h3>
                      <div
                        v-for="subrate in subrates"
                        :key="subrate"
                        class="orm-list__bold orm-my-8"
                      >
                        <div class="orm-row">
                          <div class="orm-col-xs-8">
                            <span v-if="subrate.amount >= 1"
                              >{{ subrate.amount }}x
                            </span>
                            {{ subrate.description }}
                            <span class="orm-small" v-if="subrate.unit !== 'X'"
                              >{{ subrate.description2 }} ({{
                                convertCurrency(subrate.long_price)
                              }}/{{ subrate.unit }})</span
                            >
                          </div>
                          <div class="orm-col-xs-4 orm-price__right">
                            {{ convertCurrency(subrate.total_long_price) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="deliveryrates.length > 0">
                      <h3 class="orm-headline__h3 orm-mt-32 orm-mb-8">
                        {{ i18n("step2/deliverypickup/title") }}
                      </h3>
                      <div
                        class="orm-list__bold orm-my-8"
                        v-for="rates in deliveryrates"
                        :key="rates"
                      >
                        <div class="orm-row">
                          <div class="orm-col-xs-8">
                            {{ rates.description }}: {{ rates.amount }}
                            {{ rates.unit }}
                          </div>
                          <div class="orm-col-xs-4 orm-price__right">
                            {{ convertCurrency(rates.total_long_price) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h3
                        class="orm-headline__h3 orm-mt-32 orm-mb-8"
                        v-if="voucherrates.length > 0"
                      >
                        {{ i18n("step4/voucher") }}
                      </h3>
                      <div class="orm-list__bold orm-my-8">
                        <div
                          v-for="voucher in voucherrates"
                          :key="voucher.id"
                          class="orm-row"
                        >
                          <div class="orm-col-xs-8">
                            {{ voucher.description }}<br /><span
                              class="orm-small"
                              >{{ voucher.description2 }}</span
                            >
                          </div>
                          <div class="orm-col-xs-4 orm-price__right">
                            {{ convertCurrency(voucher.total_long_price) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="orm-col-xs-12 orm-col-md-5 text-right bottom-self-xs"
                >
                  <div v-if="endpositions.length > 0">
                    <p class="orm-pricetag">
                      {{ i18n("general/total") }}:
                      <em>{{
                        convertCurrency(endpositions[2].total_price)
                      }}</em>
                    </p>
                    <p class="orm-smallprint">
                      {{ endpositions[1].description }}
                    </p>
                  </div>
                  <button
                    @click="edit('2')"
                    class="orm-button__cta outline small"
                  >
                    {{ i18n("general/edit") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Delivery -->
        <div
          class="orm-reservation__data__container__inner orm-my-16"
          v-if="
            carID &&
              hasDeliveryPickup &&
              (deliveryPickup.deliveryStreet || deliveryPickup.pickupStreet)
          "
        >
          <div class="orm-row orm-relative">
            <div class="orm-col-xs-12">
              <h2
                class="orm-headline__h1"
                v-html="i18n('step2/deliverypickup/title')"
              ></h2>
              <div class="orm-row">
                <div
                  class="orm-col-xs-12 orm-col-md-10 orm-mb-16"
                  v-if="deliveryPickup.deliveryStreet"
                >
                  <div class="orm-mb-8">
                    {{ i18n("step2/deliverypickup/delivery") }}
                  </div>
                  <div class="orm-form__container">
                    <form-input
                      my-id="deliveryStreet"
                      :my-label="i18n('step2/deliverypickup/deliveryStreet')"
                      :is-readonly="true"
                      v-model="deliveryPickup.deliveryStreet"
                    ></form-input>
                    <div class="orm-row">
                      <div class="orm-col-xs-12 orm-col-md-4">
                        <form-input
                          my-id="deliveryZipcode"
                          :my-label="
                            i18n('step2/deliverypickup/deliveryZipcode')
                          "
                          :is-readonly="true"
                          v-model="deliveryPickup.deliveryZipcode"
                        ></form-input>
                      </div>
                      <div class="orm-col-xs-12 orm-col-md-8">
                        <form-input
                          my-id="deliveryCity"
                          :my-label="i18n('step2/deliverypickup/deliveryCity')"
                          :is-readonly="true"
                          v-model="deliveryPickup.deliveryCity"
                        ></form-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="orm-col-xs-12 orm-col-md-10 orm-mb-16"
                  v-if="deliveryPickup.pickupStreet"
                >
                  <div class="orm-mb-8">
                    {{ i18n("step2/deliverypickup/pickup") }}
                  </div>
                  <div class="orm-form__container">
                    <form-input
                      my-id="pickupStreet"
                      :my-label="i18n('step2/deliverypickup/pickupStreet')"
                      v-model="deliveryPickup.pickupStreet"
                      :is-readonly="true"
                    ></form-input>
                    <div class="orm-row">
                      <div class="orm-col-xs-12 orm-col-md-4">
                        <form-input
                          my-id="pickupZipcode"
                          :my-label="i18n('step2/deliverypickup/pickupZipcode')"
                          :is-readonly="true"
                          v-model="deliveryPickup.pickupZipcode"
                        ></form-input>
                      </div>
                      <div class="orm-col-xs-12 orm-col-md-8">
                        <form-input
                          my-id="pickupCity"
                          :my-label="i18n('step2/deliverypickup/pickupCity')"
                          :is-readonly="true"
                          v-model="deliveryPickup.pickupCity"
                        ></form-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="orm-row">
          <div class="orm-col-xs-12 orm-mb-16">
            <div
              class="orm-background--white orm-reservation__data__container__inner"
              v-if="carID"
            >
              <div class="orm-row">
                <div class="orm-col-xs-12">
                  <h2 class="orm-headline__h1">{{ i18n("step4/formdata") }}</h2>
                  <div v-if="form.customer">
                    <div class="orm-row  orm-my-16">
                      <div class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-4">
                        <h2 class="orm-headline__h3">
                          {{ i18n("form/personaldata") }}
                        </h2>
                        <div
                          class="orm-form__block orm-form__container orm-pr-md-70"
                        >
                          <form-input
                            my-id="formSalutation"
                            :my-label="i18n('form/label/salutation')"
                            :is-readonly="true"
                            :model-value="
                              store.getters['customer/GET_SALUTATION'](
                                form.customer.salutation_id
                              )
                            "
                          ></form-input>
                          <form-input
                            my-id="formName"
                            :my-label="i18n('form/label/name') || 'Name'"
                            :is-readonly="true"
                            v-model="form.customer.name1"
                            v-if="isLoggedin"
                          ></form-input>
                          <form-input
                            my-id="formFirstname"
                            :my-label="i18n('form/label/firstname')"
                            :is-readonly="true"
                            v-model="form.customer.firstname"
                            v-if="!isLoggedin"
                          ></form-input>
                          <form-input
                            my-id="formLastname"
                            :my-label="i18n('form/label/lastname')"
                            :is-readonly="true"
                            v-model="form.customer.lastname"
                            v-if="!isLoggedin"
                          ></form-input>
                          <form-input
                            my-id="formEmail"
                            :my-label="i18n('form/label/email')"
                            my-type="email"
                            :is-readonly="true"
                            v-model="form.customer.email"
                          ></form-input>
                          <form-input
                            my-id="formPhone"
                            :my-label="i18n('form/label/phone')"
                            my-type="tel"
                            :is-readonly="true"
                            v-model="form.customer.phone_number"
                          ></form-input>
                          <form-input
                            my-id="formCompany"
                            :my-label="i18n('form/label/company')"
                            :is-readonly="true"
                            v-model="form.customer.company_name"
                          ></form-input>
                        </div>
                      </div>
                      <div class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-4">
                        <h2 class="orm-headline__h3">
                          {{ i18n("form/address") }}
                        </h2>
                        <div
                          class="orm-form__block orm-form__container orm-pr-md-70"
                        >
                          <form-input
                            my-id="formStreet"
                            :my-label="i18n('form/label/street')"
                            :is-readonly="true"
                            v-model="form.customer.street"
                          ></form-input>
                          <form-input
                            my-id="formZipcode"
                            :my-label="i18n('form/label/zipcode')"
                            :is-readonly="true"
                            v-model="form.customer.zipcode"
                          ></form-input>
                          <form-input
                            my-id="formCity"
                            :my-label="i18n('form/label/city')"
                            :is-readonly="true"
                            v-model="form.customer.city"
                          ></form-input>
                        </div>
                        <div v-if="!hasConcardis">
                          <h2 class="orm-headline__h3">
                            {{ i18n("form/payment") }}
                          </h2>
                          <div
                            class="orm-form__block orm-form__container orm-pr-md-70"
                          >
                            <form-input
                              my-id="formPayment"
                              :my-label="i18n('form/label/payment')"
                              :is-readonly="true"
                              :model-value="
                                store.getters['reservation/GET_PAYMENT_METHOD'](
                                  form.payment_method_id
                                )
                              "
                            ></form-input>
                          </div>
                        </div>
                      </div>
                      <div class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-4">
                        <h2 class="orm-headline__h3">
                          {{ i18n("form/message") }}
                        </h2>
                        <div
                          class="orm-form__block orm-form__container orm-pr-sm-70 orm-mb-0"
                        >
                          <form-textarea
                            my-id="formRemarks"
                            :my-label="i18n('form/label/remarks')"
                            my-rows="5"
                            :is-readonly="true"
                            v-model="form.remarks"
                          ></form-textarea>
                          <div
                            v-if="
                              store.getters['ORMgeneral/GET_MODULE'](
                                'ORM_LOGIN'
                              ) == 'J' && !isLoggedin
                            "
                          >
                            <h2 class="orm-headline__h3">
                              {{ i18n("form/account") }}
                            </h2>
                            <div class="orm-form__block ">
                              <form-checkbox
                                my-fontbase="14"
                                my-name="formCreateLogin"
                                :my-label="i18n('form/label/account')"
                                :is-readonly="true"
                                v-model="form.customer.create_login"
                              ></form-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="orm-row bottom-md">
                      <div class="orm-col-xs-12 orm-col-md-6"></div>
                      <div class="orm-col-xs-12 orm-col-md-6 text-right">
                        <button
                          @click="edit('3')"
                          class="orm-button__cta outline small"
                        >
                          {{ i18n("general/edit") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="orm-row">
          <div class="orm-col-xs-12 orm-mb-16">
            <div
              class="orm-background--white orm-reservation__data__container__inner"
              v-if="carID"
            >
              <div class="orm-row">
                <div class="orm-col-xs-12 orm-col-md-7">
                  <h2 class="orm-headline__h1">
                    {{ i18n("step4/completeReservation") }}
                  </h2>
                  <h3
                    class="orm-headline__h3 orm-mt-32 orm-mb-8"
                    v-if="i18n('step4/legalnotice')"
                  >
                    {{ i18n("step4/legalnotice") }}
                  </h3>
                  <p
                    v-if="i18n('step4/legalnotice')"
                    class="orm-legalNotice"
                    v-html="i18n('step4/noticetext')"
                  ></p>
                  <h3 class="orm-headline__h3 orm-mt-32 orm-mb-8">
                    {{ i18n("step4/consent") }}
                  </h3>
                  <div class="orm-my-16">
                    <form-checkbox
                      my-fontbase="14"
                      v-model="validate.contractCheck"
                      my-name="contractCheck"
                      :my-label="i18n('step4/agreementterms')"
                      :is-required="true"
                      :error="errors.contractCheck"
                    >
                    </form-checkbox>
                  </div>
                  <div class="orm-my-16">
                    <form-checkbox
                      my-fontbase="14"
                      v-model="validate.dsgvoCheck"
                      my-name="dsgvoCheck"
                      :my-label="i18n('step4/dsgvo')"
                      :is-required="true"
                      :error="errors.dsgvoCheck"
                    >
                    </form-checkbox>
                  </div>
                  <div class="orm-my-16">
                    <form-checkbox
                      v-if="form.customer"
                      my-fontbase="14"
                      v-model="form.customer.allow_marketing"
                      my-name="werbungCheck"
                      :my-label="i18n('step4/marketing')"
                    >
                    </form-checkbox>
                  </div>
                </div>
                <div
                  class="orm-col-xs-12 orm-col-md-5 text-right bottom-self-xs"
                >
                  <div v-if="endpositions.length > 0 && !isCalculating">
                    <p class="orm-pricetag">
                      {{ i18n("general/total") }}:
                      <em>{{
                        convertCurrency(endpositions[2].total_price)
                      }}</em>
                    </p>
                    <p class="orm-smallprint">
                      {{ endpositions[1].description }}
                    </p>

                    <button
                      @click="validateForm"
                      class="orm-button__cta wide orm__cta"
                      :disabled="isLoading"
                    >
                      <i
                        v-if="isLoading"
                        class="fa fa-spinner-third orm-rotate360"
                      ></i>
                      <span v-if="!hasConcardis || payOffline">{{
                        i18n("step4/reservate")
                      }}</span>
                      <span v-else>{{ i18n("step4/submit") }}</span>
                    </button>
                  </div>
                  <div v-else>
                    <div class="orm-loader-spinner">
                      <i
                        class="fa fa-spinner-third orm-rotate360 orm-rotate360--big"
                      ></i>
                    </div>
                  </div>
                  <div v-if="hasConcardis && paymenttypeoffline">
                    <div v-if="payOffline">
                      <span
                        class="orm-payswitch"
                        @click="
                          payOffline = !payOffline;
                          generateTarif('online');
                        "
                      >
                        <i class="fa fa-chevron-right"></i>
                        {{ i18n("step4/pay_online") }}
                        ( -
                        {{
                          convertCurrency(
                            paymenttypeoffline[env.VUE_APP_PAYMENT_OFFLINE]
                              .price
                          )
                        }}
                        )
                      </span>
                    </div>
                    <div v-else>
                      <span
                        class="orm-payswitch"
                        @click="
                          payOffline = !payOffline;
                          generateTarif('offline');
                        "
                      >
                        <i class="fa fa-chevron-right"></i>
                        {{ i18n("step4/pay_offline") }}
                        (
                        <span v-if="paymenttypeoffline"
                          >+
                          {{
                            convertCurrency(
                              paymenttypeoffline[env.VUE_APP_PAYMENT_OFFLINE]
                                .price
                            )
                          }}</span
                        >
                        )
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import reservationheader from "@/components/reservation/header.vue"; // @ is an alias to /src
import reservationinfobar from "@/components/reservation/infobar_reservation.vue"; // @ is an alias to /src
import equipIcon from "@/components/reservation/equip-icon.vue";
import Slider from "@/components/ui/image-slider.vue";
import { convertCurrency } from "@/constants/formater";

import FormInput from "@/components/ui/INPUTS/FormInput.vue"; // @ is an alias to /src
import FormTextarea from "@/components/ui/INPUTS/FormTextarea.vue";
import FormCheckbox from "@/components/ui/INPUTS/FormCheckbox.vue";

import Footer from "@/components/widgets/Footer.vue"; // @ is an alias to /src
import store from "@/store";
import ErrorModal from "@/components/modals/modal-error.vue";
import ConcardisModal from "@/components/modals/modal-concardis.vue";
import { formateDateShort, formatTime } from "@/constants/formater";
import i18n from "@/mixins/mixins";
import stripHTML from "@/mixins/mixins";

export default defineComponent({
  name: "ReservationStep4",
  components: {
    Footer,
    reservationheader,
    reservationinfobar,
    equipIcon,
    Slider,
    FormInput,
    FormTextarea,
    FormCheckbox,
    ErrorModal,
    ConcardisModal,
  },
  mixins: [i18n, stripHTML],
  setup() {
    return {
      store,
      formateDateShort,
      formatTime,
    };
  },
  data() {
    return {
      env: process.env,
      date: Object,
      time: Object,
      form: Object,
      images: null,
      carID: null,
      selectedCargroup: Object,
      selectedCargroupInfo: Object,
      session: Object,
      startstation: Object,
      endstation: Object,
      subrates: [],
      insurances: [],
      deliveryrates: [],
      endpositions: [],
      extras: [],
      voucherrates: Array,
      tarif: Object,
      tarifOffline: null,
      hasbilling: false,
      hasDeliveryPickup: false,
      deliveryPickup: Object,
      price: [],
      convertCurrency,
      isLoading: false,
      isCalculating: false,
      widgetRef: "null",
      showConcardis: false,
      hasConcardis: false,
      paymentData: {
        data: null,
        error: null,
        loading: false,
        processing: false,
      },
      paymentLoading: false,
      paymenttypeoffline: null,
      paymenttypeonline: null,
      payOffline: false,
      validate: {
        contractCheck: false,
        dsgvoCheck: false,
      },
      errors: {
        contractCheck: false,
        dsgvoCheck: false,
      },
      error: {
        show: false,
        loadTimes: true,
        resetSession: true,
        data: {
          header: this.i18n("error/default/header"),
          content: this.i18n("error/default/content"),
          buttonFirst: "Home",
          buttonFirstLabel: this.i18n("error/default/buttonFirstLabel"),
        },
      },
      isLoggedin: false,
    };
  },
  async beforeMount() {
    if (store.getters["ORMgeneral/GET_MODULE"]("ConCardis") == "J") {
      if (
        Object.keys(store.getters["reservation/GET_PAYMENTTYPE"])[0] ===
        process.env.VUE_APP_PAYMENT_OFFLINE
      ) {
        this.payOffline = true;
      }
      this.hasConcardis = true;
      const payengine = document.createElement("script");
      const concardisurl = store.getters["ORMgeneral/GET_PARAMETER"](
        "SCONCARDIS_VIRTUAL_TERMINAL_URL"
      );

      payengine.setAttribute(
        "src",
        concardisurl + "bridge/1.0/payengine.min.js"
      );
      payengine.async = true;
      document.head.appendChild(payengine);

      const payenginewidget = document.createElement("script");
      payenginewidget.setAttribute(
        "src",
        concardisurl + "widgetjs/payengine.widget.min.js"
      );
      payenginewidget.async = true;
      document.head.appendChild(payenginewidget);
    }
  },
  async created() {
    if (process.env.VUE_APP_DELIVERYPICKUP == "true") {
      this.hasDeliveryPickup = true;
    }
    this.selectedCargroup = await store.getters["reservation/GET_RESERVATION"];

    if (this.selectedCargroup == null) {
      this.error.show = true;
      return false;
    }
    const carID = await store.getters["reservation/GET_RESERVATION_CARGROUP"];
    this.carID = carID;

    if (carID == null) {
      return false;
    }
    this.paymenttypeoffline = await store.getters[
      "reservation/GET_PAYMENTTYPE_OFFLINE"
    ];
    this.paymenttypeonline = await store.getters[
      "reservation/GET_PAYMENTTYPE_ONLINE"
    ];
    const deliveryPickup = await store.getters[
      "reservation/GET_DELIVERY_PICKUP"
    ];
    this.deliveryPickup = JSON.parse(JSON.stringify(deliveryPickup));

    this.form = await store.getters["reservation/GET_CUSTOMER"];

    const station = await store.getters["reservation/GET_RESERVATION_STATION"];

    this.startstation = store.getters["stations/GET_STATION_BY_ID"](
      station.start
    );

    if (!station.end) {
      this.endstation = store.getters["stations/GET_STATION_BY_ID"](
        station.start
      );
    } else {
      this.endstation = store.getters["stations/GET_STATION_BY_ID"](
        station.end
      );
    }

    this.setTarif(this.selectedCargroup);

    this.date = store.getters["reservation/GET_RESERVATION_DATE"];
    this.time = store.getters["reservation/GET_RESERVATION_TIME"];

    this.selectedCargroupInfo = await store.getters[
      "cargroup/GET_CARGROUP_BY_ID"
    ](carID);

    this.images = await store.getters["cargroup/GET_GLOBAL_CARGROUPS_IMAGE"](
      carID
    );
    this.voucherrates = this.selectedCargroup.voucher_rates;

    const logincheck = await store.getters["userSession/IS_LOGGEDIN"];
    if (logincheck) this.isLoggedin = true;
  },
  methods: {
    setTarif(tarif) {
      this.insurances = tarif.insurance_rates;
      this.deliveryrates = tarif.delivery_pickup_rates;
      this.endpositions = tarif.end_positions;
      this.subrates = tarif.sub_rates;
      tarif.main_rates.forEach((element) => {
        if (element.main_rate) {
          this.price.push(element);
        }
      });
    },
    edit(route) {
      this.$router.push({ name: "Reservation_Step" + route });
    },
    validateForm() {
      if (this.validate.contractCheck == false) {
        this.errors.contractCheck = true;
      } else {
        this.errors.contractCheck = false;
      }
      if (this.validate.dsgvoCheck == false) {
        this.errors.dsgvoCheck = true;
      } else {
        this.errors.dsgvoCheck = false;
      }

      if (
        this.validate.contractCheck == true &&
        this.validate.dsgvoCheck == true
      ) {
        this.isLoading = true;

        if (!this.hasConcardis || this.payOffline) {
          this.reservate();
        } else {
          this.submit();
        }
      }
    },
    async cancel() {
      this.showConcardis = false;
      this.isLoading = false;
    },
    async calcPrice() {
      this.loadingPrice = true;
      this.isCalculating = true;

      // console.log(this.carID);
      this.selectedCargroup = await store
        .dispatch("reservation/CALCULATE_PRICE", {
          id: this.carID,
          extras: true,
        })
        .then((res) => {
          if (!res.errors) {
            store.dispatch("reservation/SET_RESERVATION_TARIFS", res);
            this.subrates = res.sub_rates;
            this.mainrates = JSON.parse(JSON.stringify(res.main_rates));
            this.deliveryrates = JSON.parse(
              JSON.stringify(res.delivery_pickup_rates)
            );
            this.endpositions = res.end_positions;
            this.mainrates.sort((a, b) => (a.position > b.position ? 1 : -1));
            this.isLoading = false;
            this.isCalculating = false;
            this.voucherrates = JSON.parse(JSON.stringify(res.voucher_rates));

            return res;
          } else {
            console.log("errors", res);
          }
        });
    },
    async generateTarif(type) {
      this.isLoading = true;
      let paytype = this.paymenttypeoffline;
      if (type == "online") {
        paytype = this.paymenttypeonline;
      }
      const rate = Object.keys(paytype)[0];
      const id = paytype[rate].id;
      const amount = paytype[rate].amount;

      store
        .dispatch("reservation/SET_PAYMENTTYPE", { rate, id, amount })
        .then((res) => {
          this.calcPrice();
          return res;
        });
    },
    pay() {
      this.paymentData.loading = true;
      // console.log( this.paymentData);
      const onPayCallback = (error, result) => {
        console.log("On pay callback", error, result);
      };
      /* eslint-disable */
      const onVallCallback = (error, result) => {
        if (!error) {
          this.paymentData.processing = true;
          window.PayEngineWidget.pay(this.widgetRef, onPayCallback);
        } else {
          this.paymentData.loading = false;
        }
      };
      /* eslint-enable */
      window.PayEngineWidget.validate(this.widgetRef, onVallCallback);
    },
    async submit() {
      this.paymentData.error = null;
      this.paymentData.loading = true;
      this.paymentData.processing = false;
      const merchantId = await store.dispatch(
        "payment/GET_CONCARDIS_MERCHANDID"
      );
      if (merchantId == "error") {
        this.error.show = true;
        this.error.data.content = this.i18n("error/offer/content");
        return false;
      }
      const initCallback = (error, iframeName) => {
        this.widgetRef = iframeName;
        this.paymentData.loading = false;
      };
      const resultCallback = (error, paymentInstrument) => {
        // console.log("Error/Result:", error, paymentInstrument);
        if (error) {
          console.log("CONCARDIS ERROR", error);
          store.dispatch("payment/DELETE_CONARDIS");
          this.paymentData.error = error;
          this.paymentData.loading = false;
        } else {
          if (paymentInstrument.code == 201) {
            // console.log(paymentInstrument.message);
            this.reservate();
          } else {
            console.log("CONCARDIS ERROR");
            this.showConcardis = false;
          }
        }
      };
      const optionalParameters = {
        language: "de",
        layout: "dropdown",
        customStyleId: null,
        hidePayButton: true,
        hideTitleIcons: true,
        skipFinalResultView: true,
        paymentDetails: {
          paypal: {
            returnUrl:
              window.location.origin +
              "/" +
              process.env.VUE_APP_RESERVATION_PATH +
              process.env.VUE_APP_RESERVATION_PATH_FINAL,
            cancelUrl: window.location.href,
          },
        },
        optionalFields: {},
      };
      optionalParameters.initCallback = initCallback;
      const order = await store.dispatch("payment/SET_ORDER");

      if (!order) {
        store.dispatch("payment/MAKE_ORDER").then((result) => {
          this.showConcardis = true;
          this.paymentData.data = result;
          setTimeout(() => {
            window.PayEngine.setPublishableKey(merchantId);
            window.PayEngineWidget.initAsInlineComponent(
              "inline-widget-container",
              merchantId,
              result.orderId,
              optionalParameters,
              resultCallback
            );
          }, 500);
        });
      } else {
        this.showConcardis = true;
        this.paymentData.data = order;
        setTimeout(() => {
          window.PayEngine.setPublishableKey(merchantId);
          window.PayEngineWidget.initAsInlineComponent(
            "inline-widget-container",
            merchantId,
            order.orderId,
            optionalParameters,
            resultCallback
          );
        }, 500);
      }
    },
    async reservate() {
      await store.dispatch(
        "reservation/SET_MARKETING",
        this.form.customer.allow_marketing
      );
      await store.dispatch("reservation/RESERVATE").then((result) => {
        if (
          result.status == 400 ||
          result.status == 500 ||
          result.status == 404
        ) {
          if (result.data.errorCode == "CREDENTIALS-EXIST") {
            this.error = {
              show: true,
              loadTimes: false,
              resetSession: false,
              data: {
                header: this.i18n("error/email/header"),
                content: "", //result.data.message,
                buttonFirst: "Reservation_Step3",
                buttonFirstLabel: this.i18n("error/email/buttonFirstLabel"),
                // buttonSecond: "CREDENTIALS-EXIST",
                // buttonSecondLabel: "ohne Kundenkonto anlegen reservieren",
              },
            };
          } else {
            this.error.show = true;
            this.error.data.content = this.i18n("error/offer/content");

            /// REFUND CONCARSID BOOKING IF RESERVATION ERROR
            if (this.hasConcardis) {
              store.dispatch("payment/REFUND_ORDER");
            }

            store.commit("reservation/MUTATE_DELETE_RESERVATION");
            this.error.data.content = result;
          }
        } else {
          this.$router.push({
            name: "Reservation_Final",
            params: { data: result.data },
          });
        }
      });
    },
  },
});
</script>
