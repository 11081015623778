<template>
  <div class="orm-locationMap__stationsList__container overflowHidden">
    <div class="orm-locationMap__stationsList orm-stationsList">
      <div
        v-for="station in stations"
        :key="station.id"
        class="orm-stationsList__station  orm-relative "
        :class="[
          store.getters['userSession/GET_STATION'] == station.id
            ? 'selected'
            : '',
          'orm-stationsList__station-' + station.id,
        ]"
        @click="
          store.getters['userSession/GET_STATION'] == station.id
            ? false
            : setStation(station.id)
        "
      >
        <div v-if="store.getters['userSession/GET_STATION'] == station.id">
          <div class="orm-station__container orm-row">
            <div class="orm-station__title orm-col-xs-12">
              <p>{{ i18n("general/station") }}</p>
              <!-- <div
                class="orm-station__distance"
                v-if="userSession.userLocation"
              >
                <small
                  >{{
                    getDistanceFromLatLonInKm(
                      station.latitude,
                      station.longitude,
                      userSession.userLocation
                    )
                  }}
                  {{ i18n("general/metric") }}</small
                >
              </div> -->
              <span class="orm-station__icons">
                <i
                  v-if="station.is_rent_station"
                  class="fas fa-car orm-car__rental"
                ></i>
                <i
                  v-if="station.is_sharing_station"
                  class="fas fa-car orm-car__share"
                ></i>
                <i
                  v-if="station.is_special"
                  class="fas fa-car orm-car__special"
                ></i>
                <i
                  v-if="
                    !station.is_sharing_station &&
                      !station.is_special &&
                      !station.is_rent_station
                  "
                  class="fas fa-car orm-car__abo"
                ></i>
              </span>
            </div>
            <div class="orm-col-xs-12">
              <stationpicker :data="stations" type="start" :readonly="true" />
              <stationpicker
                :data="stationsEnd"
                type="end"
                viewType="mapModule"
                v-if="
                  store.getters['ORMgeneral/GET_PARAMETER'](
                    'BINET_ALLOW_ONEWAY'
                  ) == 1
                "
              />
            </div>
          </div>

          <datepicker
            viewType="mapModule"
            vDatepickerTeleport="#v-range-selector-map"
          />

          <div class="orm-row">
            <div class="orm-col-xs-12">
              <div class="orm-stationsList__button">
                <button
                  v-if="!loading"
                  @click="getCars('/reservation/step1')"
                  class="orm-button__cta orm-btn__block"
                  :class="[station ? '' : 'disable']"
                >
                  {{ i18n("general/showOffers") }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="orm-station__container">
            <div class="orm-station__name__container">
              <span class="orm-station__name">
                {{ station.name }}
              </span>
              <div
                class="orm-station__distance"
                v-if="userSession.userLocation"
              >
                <small
                  >{{
                    getDistanceFromLatLonInKm(
                      station.latitude,
                      station.longitude,
                      userSession.userLocation
                    )
                  }}
                  km</small
                >
              </div>
              <span class="orm-station__icons">
                <i
                  v-if="station.is_rent_station"
                  class="fas fa-car orm-car__rental"
                ></i>
                <i
                  v-if="station.is_sharing_station"
                  class="fas fa-car orm-car__share"
                ></i>
                <i
                  v-if="station.is_special"
                  class="fas fa-car orm-car__special"
                ></i>
                <i
                  v-if="
                    !station.is_sharing_station &&
                      !station.is_special &&
                      !station.is_rent_station
                  "
                  class="fas fa-car orm-car__abo"
                ></i>
              </span>
            </div>

            <div class="orm-station__street">
              {{ station.street }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { getDistanceFromLatLonInKm } from "@/constants/formater";

import stationpicker from "@/components/ui/stationpicker.vue"; // @ is an alias to /src
import datepicker from "@/components/ui/datepicker.vue"; // @ is an alias to /src
import store from "@/store";
import i18n from "@/mixins/mixins";

export default defineComponent({
  name: "stationlist",
  components: { datepicker, stationpicker },
  mixins: [i18n],
  props: {
    stationtype: {
      default: "ALL",
      required: false,
    },
  },
  setup() {
    const userSession = store.state.userSession.LocalSession;
    // console.log("stations", stations);

    return {
      getDistanceFromLatLonInKm,
      userSession,
      store,
    };
  },
  data() {
    return {
      stations: Object,
      stationsEnd: Object,
      loading: false,
      watch: false,
    };
  },
  async mounted() {
    const sorted = await store.getters["stations/GET_STATIONS_SORTED"];
    this.stations = sorted[this.stationtype];
    this.stationsEnd = sorted[store.getters["userSession/GET_STATIONTYPE"]];
    // console.log(this.stationsEnd);
    this.watch = true;
    this.scrollList();

    store.watch(
      // eslint-disable-next-line
      (state, getters) =>
        getters["stations/GET_STATIONS_SORTED"][this.stationtype],
      () => {
        this.stations =
          store.getters["stations/GET_STATIONS_SORTED"][this.stationtype];
        this.scrollList();
      },
      { immediate: true }
    );

    store.watch(
      // eslint-disable-next-line
      (state, getters) => getters["userSession/GET_STATIONTYPE"],
      (val) => {
        this.stationsEnd = sorted[val];
      }
    );

    store.watch(
      // eslint-disable-next-line
      (state, getters) => getters["userSession/GET_STATION"],
      () => {
        this.scrollList();
      },
      { immediate: true }
    );
  },
  unmounted() {
    this.watch = false;
  },
  methods: {
    setStation(station) {
      store.dispatch("userSession/SET_STATION", station);
      store.dispatch("userSession/SET_STATION_END", "startend");
      this.scrollList();
    },
    async scrollList() {
      const newstation = await store.getters["userSession/GET_STATION"];

      const element = document.querySelectorAll(
        ".orm-stationsList__station-" + newstation
      );
      if (element.length == 0) return;
      const innerEL = this.$el.children[0];
      // console.log(element);
      // console.log(newstation);
      if (element && this.watch) {
        setTimeout(() => {
          innerEL.scrollTo({
            top: element[0].offsetTop - innerEL.offsetTop,
            behavior: "smooth",
          });
        }, 150);
      }
    },
    async getCars() {
      const times = await store.getters["stations/GET_OPENINGTIMES"];

      if (times) {
        this.loading = true;
        if (process.env.VUE_APP_STANDALONE == "true") {
          this.$router.push({ name: "Reservation_Step1" });
        } else {
          window.location.href =
            process.env.VUE_APP_BASE_URL +
            process.env.VUE_APP_RESERVATION_PATH +
            process.env.VUE_APP_RESERVATION_PATH_STEP1;
        }
      }
    },
  },
});
</script>
