<template>
  <div v-if="!marker"></div>
</template>

<script>
import {
  POINT_MARKER_ICON_RENTAL,
  POINT_MARKER_ICON_SHARING,
  POINT_MARKER_ICON_ABO,
  POINT_MARKER_ICON_SPECIAL,
  POINT_MARKER_ICON_BOTH,
  POINT_MARKER_ICON_ACTIVE,
} from "@/constants/mapSettings";
import store from "@/store";

export default {
  props: {
    google: {
      type: Object,
      required: false,
    },
    map: {
      type: Object,
      required: false,
    },
    marker: {
      type: Object,
      required: false,
    },
    bounds: {
      type: Object,
      required: false,
    },
    noselect: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      thisMarker: Object,
      position: Object,
    };
  },

  async created() {
    const { Marker } = window.google.maps;
    const station = await store.getters["userSession/GET_STATION"];

    this.position = {
      lat: this.marker.latitude,
      lng: this.marker.longitude,
    };
    this.bounds.extend(this.position);

    this.map.fitBounds(this.bounds);

    let markerIcon = POINT_MARKER_ICON_RENTAL;
    let markerZindex = 90;

    if (station == this.marker.id && !this.noselect) {
      markerIcon = POINT_MARKER_ICON_ACTIVE;
      markerZindex = 90;

      this.map.setCenter(this.position);
    } else {
      if (this.marker.is_rent_station && this.marker.is_sharing_station) {
        markerIcon = POINT_MARKER_ICON_BOTH;
      } else if (this.marker.is_sharing_station) {
        markerIcon = POINT_MARKER_ICON_SHARING;
      } else if (this.marker.is_special) {
        markerIcon = POINT_MARKER_ICON_SPECIAL;
      } else if (
        !this.marker.is_special &&
        !this.marker.is_sharing_station &&
        !this.marker.is_rent_station
      ) {
        markerIcon = POINT_MARKER_ICON_ABO;
      } else {
        markerIcon = POINT_MARKER_ICON_RENTAL;
      }
      if (this.noselect) this.map.setCenter(this.position);
      markerZindex = 11;
    }

    store.watch(
      (state, getters) => getters["userSession/GET_STATION"],
      (val) => {
        if (val == this.marker.id) {
          markerIcon = POINT_MARKER_ICON_ACTIVE;
          markerZindex = 90;
          this.map.setCenter(this.position);
        } else {
          if (this.marker.is_rent_station && this.marker.is_sharing_station) {
            markerIcon = POINT_MARKER_ICON_BOTH;
          } else if (this.marker.is_sharing_station) {
            markerIcon = POINT_MARKER_ICON_SHARING;
          } else if (this.marker.is_special) {
            markerIcon = POINT_MARKER_ICON_SPECIAL;
          } else if (
            !this.marker.is_special &&
            !this.marker.is_sharing_station &&
            !this.marker.is_rent_station
          ) {
            markerIcon = POINT_MARKER_ICON_ABO;
          } else {
            markerIcon = POINT_MARKER_ICON_RENTAL;
          }
          markerZindex = 11;
        }
        this.thisMarker.setZIndex(markerZindex);
        this.thisMarker.setIcon(markerIcon);
      }
    );

    this.thisMarker = new Marker({
      position: this.position,
      marker: this.marker,
      map: this.map,
      icon: markerIcon,
    });
    this.thisMarker.setZIndex(markerZindex);

    this.thisMarker.addListener("click", () => {
      if (this.noselect) return false;
      this.map.setCenter(this.position);
      this.thisMarker.setIcon(POINT_MARKER_ICON_ACTIVE);
      this.thisMarker.setZIndex(markerZindex);
      this.$emit("marker-click", this.marker);
      store.dispatch("userSession/SET_STATION", this.marker.id);
    });
  },
};
</script>
