<template>
  <div class="orm-mb-32 orm-col-xs-12 orm-background--cta2" v-if="filter">
    <div class="orm-row orm-py-24 orm-py-sm-40 orm-px-8 orm-px-sm-24 center-xs">
      <div
        class="orm-col-xs-12 orm-col-sm-12 orm-col-md-4 orm-mb-16 orm-border-right"
      >
        <div class="" v-if="images.length > 0">
          <Slider v-if="images" :images="images" />
        </div>
      </div>
      <div
        class="orm-cartitle orm-col-xs-12 orm-col-sm-12 orm-col-md-8 orm-col-lg-6 orm-px-8 orm-pl-md-32 orm-pr-md-24"
      >
        <h2 class="orm-headline__h2 orm-headline__h2--list">
          {{ stripHTML(data[env.VUE_APP_RESERVATION_CARGROUP]) }}
        </h2>
        <p class="orm-headline__h2__subheadline--list orm-pb-16 orm-relative">
          {{ stripHTML(data[env.VUE_APP_RESERVATION_CARCATEGORY]) }}
          <!-- <span v-html="data.text_web"></span> -->
          <i class="fal fa-question-circle orm-cargroup__tooltip"
            ><span
              class="orm-cargroup__tooltip_text orm-cargroup__tooltip-bottom--list"
              >{{ i18n("cargroup/noguarantee") }}</span
            ></i
          >
        </p>
        <div class="orm-carequip">
          <p class="orm-equip__txt__icons">
            <equipIcon :data="data.features" />
          </p>
          <p class="orm-equip__txt__freemiles" v-if="car.price">
            {{ car.price.description2 }}
          </p>
        </div>
        <div
          class="orm-cardescription"
          v-html="data[env.VUE_APP_RESERVATION_CARTEXT]"
        ></div>
      </div>
      <div
        class="orm-col-xs-12 orm-col-md-12 orm-col-lg-2 text-right orm-pr-8 orm-pt-16"
      >
        <div class="orm-carbutton--bottom">
          <button
            @click="showSearch = !showSearch"
            class="orm-button__cta outline wide"
          >
            {{ i18n("cargroup/select") }}
          </button>
        </div>
      </div>
    </div>
    <div class="orm-row" v-if="showSearch">
      <div
        class="orm-background--greytowhite orm-col-xs-12 orm-col-md-12 center-xs orm-py-30 orm-px-24"
      >
        <div class="orm-col-xs-12">
          <div class="orm-row">
            <div class="orm-col-xs-12 orm-col-md-12 orm-col-lg-3">
              <div class="orm-row">
                <div class="orm-col-xs-12">
                  <div class="orm-searchbar__headline">
                    <strong>{{ i18n("general/station") }}</strong>
                  </div>
                </div>
                <stationpicker
                  class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-12"
                  :data="stations"
                  :enabled-stations="car.enabled_stations"
                  v-model:selectedstation="selectedStation"
                  type="start"
                />
                <stationpicker
                  class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-12"
                  :data="stations"
                  :enabled-stations="car.enabled_stations"
                  type="end"
                />
              </div>
            </div>
            <div class="orm-col-xs-12 orm-col-lg-7" style="position:relative">
              <datepicker />
            </div>
            <div
              class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-2 orm-col-sm-offset-6 orm-col-lg-offset-0 text-right"
            >
              <div class="orm-searchbar__headline">
                <strong>&nbsp;</strong>
              </div>
              <button
                @click="getCars('/reservation/step1')"
                class="orm-btn orm-btn--large orm-btn--searchbar"
                :class="[station ? '' : 'disable']"
              >
                {{ i18n("general/showOffers") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import store from "@/store";
import equipIcon from "@/components/reservation/equip-icon.vue";
import datepicker from "@/components/ui/datepicker.vue"; // @ is an alias to /src
import stationpicker from "@/components/ui/stationpicker.vue"; // @ is an alias to /src
import Slider from "@/components/ui/image-slider.vue";
import i18n from "@/mixins/mixins";
import stripHTML from "@/mixins/mixins";

export default defineComponent({
  name: "car-list-search",
  components: {
    equipIcon,
    datepicker,
    stationpicker,
    Slider,
  },
  mixins: [i18n, stripHTML],
  data() {
    return {
      car: Object,
      images: [],
      showSearch: false,
      station: store.getters["userSession/GET_STATION"],
      selectedStation: null,
      env: process.env,
    };
  },
  props: {
    data: Object,
    filter: Boolean,
    stations: Object,
  },
  async mounted() {
    this.car = await this.data;

    const observer = new IntersectionObserver((entries) => {
      // Use `intersectionRatio` because of Edge 15's
      // lack of support for `isIntersecting`.
      // See: https://github.com/w3c/IntersectionObserver/issues/211
      if (entries[0].intersectionRatio <= 0) return;
      // Cleanup the observer when it's not
      // needed anymore.
      observer.unobserve(this.$el);
      // The `loadPrice()` resolves
      // to the result of a dynamic `import()`
      // which is passed to the `resolveComponent()`
      // function.
      this.lazyload();
    });
    // We observe the root `$el` of the
    // mounted loading component to detect
    // when it becomes visible.
    observer.observe(this.$el);

    store.watch(
      (state, getters) => getters["userSession/GET_STATION"],
      (val) => {
        this.station = store.getters["stations/GET_STATION_BY_ID"](val);
      }
    );
  },
  methods: {
    async lazyload() {
      // await store
      //   .dispatch("cargroup/SET_CARGROUP_IMAGE", this.car.id)
      //   .then((val) => {
      //     if (val == "error") {
      //       this.images = [];
      //     } else {
      //       this.images = val;
      //     }

      //     // console.log("this.car", this.car);
      //     // console.log("iamge-call", val);
      //   });
      this.images = this.car.images;
    },
    async getCars() {
      const times = await store.getters["stations/GET_OPENINGTIMES"];
      if (this.selectedStation) {
        store.dispatch("userSession/SET_STATION", this.selectedStation);
      }

      if (times) {
        store.dispatch("userSession/SET_CARGROUP", this.car.id).then(() => {
          store.dispatch("reservation/DELETE_TARIF");
          store.dispatch("stations/SET_OPENINGTIMES").then(() => {
            store
              .dispatch("reservation/CALCULATE_PRICE", {
                id: this.car.id,
                extras: false,
              })
              .then((tarif) => {
                // console.log(tarif);
                // console.log(tarif.main_rates.length);
                if (tarif.main_rates.length == 0) {
                  const cartype = {};
                  cartype[this.car["vehicle_category"][0]["id"]] = true;
                  store.dispatch("userSession/SET_CARCATEGORY", cartype);
                  if (process.env.VUE_APP_STANDALONE == "true") {
                    this.$router.push({ name: "Reservation_Step1" });
                  } else {
                    window.location.href =
                      process.env.VUE_APP_BASE_URL +
                      process.env.VUE_APP_RESERVATION_PATH +
                      process.env.VUE_APP_RESERVATION_PATH_STEP1;
                  }
                } else {
                  store
                    .dispatch("reservation/SET_RESERVATION_TARIFS", tarif)
                    .then(() => {
                      if (process.env.VUE_APP_STANDALONE == "true") {
                        this.$router.push({ name: "Reservation_Step2" });
                      } else {
                        window.location.href =
                          process.env.VUE_APP_BASE_URL +
                          process.env.VUE_APP_RESERVATION_PATH +
                          process.env.VUE_APP_RESERVATION_PATH_STEP2;
                      }
                    });
                }
              });
          });
        });
      }
    },
  },
});
</script>
