<template>
  <div v-if="!shortlist && data">
    <div
      class="orm-equip__icon orm-mb-16 orm-relative"
      v-for="feature in data"
      :key="feature"
    >
      <span
        v-if="
          icons[feature.feature.css_icon] && feature.feature.css_icon != null
        "
        :title="feature.feature.feature_name + ': ' + feature.value"
        @click="toggle(feature.id)"
        v-click-outside="handleTooltipClick"
      >
        <i v-if="feature.value == 'Manuell'" class="orm_icon_gear"></i>
        <i v-else class="" :class="icons[feature.feature.css_icon]"></i>
        {{ feature.value }}
      </span>
      <span v-else :title="feature.feature.feature_name + ': ' + feature.value"
        >{{ feature.feature.feature_name }}: {{ feature.value }}</span
      >
      <span
        class="orm-eqiupment__tooltip-bottom"
        v-if="toggleTooltip === feature.id"
        >{{ feature.feature.feature_name + ": " + feature.value }}</span
      >
    </div>
  </div>
  <div v-else-if="shortlist && data">
    <span
      class="orm-equip__icon  orm-relative"
      v-for="feature in data"
      :key="feature"
    >
      <span
        v-if="
          icons[feature.feature.css_icon] && feature.feature.css_icon != null
        "
        :title="
          feature.feature.feature_name +
            (feature.value ? ': ' + feature.value : '')
        "
        @click="toggle(feature.id)"
        v-click-outside="handleTooltipClick"
      >
        <i v-if="feature.value == 'Manuell'" class="orm_icon_gear"></i>
        <i v-else class="" :class="icons[feature.feature.css_icon]"></i>
        <span
          class="orm_icontext_additional"
          v-if="
            feature.feature.css_icon === 'carIconPerson' ||
              feature.feature.css_icon === 'carIconDoor' ||
              feature.feature.css_icon === 'carIconLuggage' ||
              feature.feature.css_icon === 'carIconPass'
          "
          >&nbsp;{{ feature.value }}</span
        >
      </span>
      <span v-else :title="feature.feature.feature_name + ': ' + feature.value"
        >{{ feature.feature.feature_name }}: {{ feature.value }}</span
      >
      <span
        class="orm-eqiupment__tooltip-bottom"
        v-if="toggleTooltip === feature.id"
        >{{ feature.feature.feature_name + ": " + feature.value }}</span
      >
    </span>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "equip-icon",
  data() {
    return {
      car: Object,
      images: String,
      icons: {
        carIconPerson: "orm_icon_sitzer",
        carIconDoor: "orm_icon_tuerer",
        carIconLuggage: "orm_icon_luggage",
        carIconGear: "orm_icon_automatik",
        carIconGearManual: "orm_icon_gear",
        carIconSnow: "orm_icon_klima",
        carIconTow: "orm_icon_ahk",
        carIconWeight: "orm_icon_weight",
        carIconBox: "orm_icon_lkwkoffer",
        carIconLabel: "orm_icon_co2",
        carIconNavi: "orm_icon_navi",
        carIconPass: "orm_icon_pass",
        null: "orm_icon_navi",
      },
      toggleTooltip: null,
      // title: {  Darf es so nicht gegeben, die Texte für diese icons sind pro geärt anders und kommen anders aus jeder orm schnittstelle
      //   carIconPerson: "Sitzplätze",
      //   carIconDoor: "Türen",
      //   carIconLuggage: "Gepäckstücke",
      //   carIconGear: "Getriebeart",
      //   carIconSnow: "Klimaanlage",
      //   carIconTow: "Anhängerkupplung",
      //   carIconWeight: "Zulässiges Anhängergewicht",
      //   carIconBox: "Abmaße",
      //   carIconLabel: "keine Ahnung",
      //   null: "Navi",
      // },
    };
  },
  props: {
    data: Object,
    shortlist: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    toggle(id) {
      if (this.toggleTooltip === id) {
        this.toggleTooltip = null;
      } else {
        this.toggleTooltip = id;
      }
    },
    handleTooltipClick(ev) {
      if (this.toggleTooltip) {
        if (
          !ev.target.classList.contains("orm_icontext_additional") &&
          !ev.target.parentNode.parentNode.classList.contains("orm-equip__icon")
        ) {
          this.toggleTooltip = null;
        }
      }
    },
  },
});
</script>
