<template>
  <Header />
  <div class="orm-background--cta1 orm-py-16">
    <div class="orm-container">
      <div class="orm-py-60">
        <div class="orm-row">
          <div class="orm-col-xs-12">
            <div class="orm-row">
              <div class="orm-col-xs-12">
                <div class="orm-background--white orm-py-16 orm-px-16">
                  <div class="orm-row">
                    <div class="orm-col-xs-12">
                      <h1 class="orm-headline__h1">
                        {{ i18n("404/headline") }}
                      </h1>
                    </div>
                    <div
                      class="orm-col-xs-5 orm-col-sm-2 orm-col-md-1 orm-fontSize-5vw"
                    >
                      <i class="fas fa-exclamation"></i>
                    </div>
                    <div
                      class="orm-col-xs-10 orm-col-sm-7"
                      v-html="i18n('404/text')"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>
<script>
import Footer from "@/components/widgets/Footer.vue"; // @ is an alias to /src
import Header from "@/components/widgets/Header.vue"; // @ is an alias to /src
import i18n from "@/mixins/mixins";

export default {
  components: { Footer, Header },
  mixins: [i18n],
  data() {
    return {};
  },
};
</script>
