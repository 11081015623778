<template>
  <div
    v-if="reservation.invoice_positions.insuranceRates.length > 0"
    class="orm-mb-32"
  >
    <h3 class="orm-headline__h3 orm-headline__h3__modal orm-mt-32 orm-mb-8">
      {{ i18n("customer/booking_list/insurances") }}
    </h3>
    <div
      v-for="insurance in reservation.invoice_positions.insuranceRates"
      :key="insurance"
      class="orm-list__bold orm-my-8"
    >
      <div class="orm-row">
        <div class="orm-col-xs-8">
          {{ insurance.description }}<br />
          <span class="orm-small"
            >{{ insurance.description2 }} ({{
              convertCurrency(insurance.long_price)
            }}/{{ insurance.unit }})</span
          >
        </div>
        <div class="orm-col-xs-4 orm-price__right">
          {{ convertCurrency(insurance.total_long_price) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { convertCurrency } from "@/constants/formater";
import stripHTML from "@/mixins/mixins";
import i18n from "@/mixins/mixins";
export default {
  name: "reservation_insurance",
  mixins: [i18n, stripHTML],
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      convertCurrency,
    };
  },
};
</script>
