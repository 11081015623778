<template>
  <teleport to="body">
    <div class="orm-html orm-contactmodal__bg">
      <div class="orm-contactmodal">
        <div class="orm-contactmodal__header">
          <div class="orm-contactmodal-user"></div>
          <button
            class="orm-btn--icon orm-login-widget-btnlistener"
            @click="closeModal()"
          >
            <i class="fa fa-times"></i> {{ i18n("general/close") }}
          </button>
        </div>
        <div
          class="orm-contactmodal__content"
          v-if="!contactsuccess && !contacterror"
        >
          <div class="orm-row">
            <div class="orm-col-xs-12">
              <h2 class="orm-headline__h1">
                {{ i18n("contact/title") }}
              </h2>
            </div>

            <div
              class="orm-col-xs-12 orm-col-md-4 orm-contactmodal-vline"
              v-if="stationSelected"
            >
              <div class="orm-contactmodal__adress">
                <i class="fa fa-map-marker-alt"></i>
                <p>
                  <span>{{ stationSelected.name }}</span
                  ><br />{{ stationSelected.street }},
                  {{ stationSelected.zipcode }}
                  {{ stationSelected.city }}
                </p>
              </div>
              <div
                class="orm-contactmodal__times"
                v-if="stationSelected.opening_date"
              >
                <i class="fal fa-clock"></i>
                <p>
                  <span>{{ stationSelected.opening_date }}</span
                  ><br />
                </p>
              </div>
              <div class="orm-contactmodal__phone" v-if="stationSelected.phone">
                <i class="fal fa-phone"></i>
                <p>
                  <span>{{ stationSelected.phone }}</span
                  ><br />
                </p>
              </div>
            </div>
            <div class="orm-col-xs-12 orm-col-md-6 orm-col-md-offset-1">
              <p class="orm-headline__h3 orm-headline__h3__modal">
                {{ i18n("contact/form/title") }}
              </p>
              <div v-if="contactcar" class="orm-contactmodal__car">
                <p>
                  {{ i18n("contact/form/cargroup") }}<br />
                  <b>
                    {{
                      stripHTML(contactcar[env.VUE_APP_RESERVATION_CARGROUP])
                    }},
                    {{
                      stripHTML(contactcar[env.VUE_APP_RESERVATION_CARCATEGORY])
                    }}</b
                  >
                </p>
              </div>
              <div v-else class="orm-contactmodal__select">
                <div class="orm-row" v-if="type !== 'general'">
                  <div class="orm-col-xs-12 orm-col-sm-6 orm-pr-lg-24">
                    <form-select
                      my-id="formReason"
                      :my-label="i18n('contact/form/label/reason')"
                      :my-options="reasonsList"
                      my-options-value="id"
                      my-options-text="reason"
                      v-model="reason_id"
                    ></form-select>
                  </div>
                </div>
              </div>
              <div class="orm-contactmodal__inputs">
                <div class="orm-row">
                  <div class="orm-col-xs-12">
                    <p class="orm-headline__h3__subheadline">
                      <b>{{ i18n("contact/form/contactdata") }}</b>
                    </p>
                  </div>
                  <div class="orm-col-xs-12 orm-col-sm-6 orm-pr-lg-24">
                    <form-input
                      v-if="!reservation"
                      my-id="formFirstname"
                      :my-label="i18n('contact/form/label/firstname')"
                      :is-required="true"
                      :error="validate.firstname"
                      v-model="form.firstname"
                    ></form-input>
                  </div>
                  <div class="orm-col-xs-12 orm-col-sm-6 orm-pl-lg-24">
                    <form-input
                      v-if="!reservation"
                      my-id="formLastname"
                      :my-label="i18n('contact/form/label/lastname')"
                      :is-required="true"
                      :error="validate.lastname"
                      v-model="form.lastname"
                    ></form-input>
                  </div>
                  <div class="orm-col-xs-12 orm-col-sm-6 orm-pr-lg-24">
                    <form-input
                      my-id="formEmail"
                      :my-label="i18n('contact/form/label/email')"
                      my-type="email"
                      :is-required="true"
                      :error="validate.email"
                      v-model="form.email"
                    ></form-input>
                  </div>
                  <div class="orm-col-xs-12 orm-col-sm-6 orm-pl-lg-24">
                    <form-input
                      my-id="formPhone"
                      :my-label="i18n('contact/form/label/phone')"
                      my-type="tel"
                      v-model="form.phone_number"
                    ></form-input>
                  </div>
                </div>
              </div>
              <div class="orm-contactmodal__message">
                <div class="orm-row">
                  <div class="orm-col-xs-12">
                    <form-input
                      v-if="reason_id == 1 && !contactcar"
                      my-id="formSubject"
                      :my-label="i18n('contact/form/label/subject')"
                      v-model="message_extras.subject"
                    ></form-input>
                    <div class="orm-col-xs-12" v-if="reason_id == 2">
                      <p class="orm-headline__h3__subheadline">
                        <b>{{ i18n("contact/form/newEnd") }}</b>
                      </p>
                    </div>
                    <form-input
                      v-if="reason_id == 2"
                      my-id="formDate"
                      :my-label="i18n('contact/form/label/date')"
                      my-type="date"
                      v-model="message_extras.date"
                    ></form-input>
                    <form-input
                      v-if="reason_id == 2"
                      my-id="formTime"
                      :my-label="i18n('contact/form/label/time')"
                      my-type="time"
                      v-model="message_extras.time"
                    ></form-input>
                    <form-input
                      v-if="reason_id == 3"
                      my-id="formKM"
                      :my-label="i18n('contact/form/label/km')"
                      v-model="message_extras.km"
                    ></form-input>
                    <form-input
                      v-if="reason_id == 4"
                      my-id="formCargroup"
                      :my-label="i18n('contact/form/label/cargroup')"
                      v-model="message_extras.cargroup"
                    ></form-input>

                    <form-textarea
                      my-id="formRemarks"
                      :my-label="i18n('contact/form/label/message')"
                      my-placeholder=""
                      my-rows="8"
                      v-model="form.message"
                    ></form-textarea>
                  </div>
                  <div class="orm-col-xs-12 orm-mb-16" style="text-align:end;">
                    <small v-html="dsgvo"></small>
                  </div>

                  <div class="orm-col-xs-12" style="text-align:end;">
                    <button class="orm-button__cta small" @click="sumbit()">
                      {{ i18n("contact/form/submit") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="orm-contactmodal__content"
          v-if="contactsuccess || contacterror"
        >
          <div class="orm-row">
            <div class="orm-col-xs-12">
              <h2 class="orm-headline__h1">
                {{ i18n("contact/title") }}
              </h2>
            </div>

            <div
              class="orm-col-xs-12 orm-col-md-4 orm-contactmodal-vline"
              v-if="stationSelected"
            >
              <div class="orm-contactmodal__adress">
                <i class="fa fa-map-marker-alt"></i>
                <p>
                  <span>{{ stationSelected.name }}</span
                  ><br />{{ stationSelected.street }},
                  {{ stationSelected.zipcode }}
                  {{ stationSelected.city }}
                </p>
              </div>
              <div
                class="orm-contactmodal__times"
                v-if="stationSelected.opening_date"
              >
                <i class="fal fa-clock"></i>
                <p>
                  <span>{{ stationSelected.opening_date }}</span
                  ><br />
                </p>
              </div>
              <div class="orm-contactmodal__phone" v-if="stationSelected.phone">
                <i class="fal fa-phone"></i>
                <p>
                  <span>{{ stationSelected.phone }}</span
                  ><br />
                </p>
              </div>
            </div>
            <div class="orm-col-xs-12 orm-col-md-6 orm-col-md-offset-1">
              <div v-if="contactsuccess">
                <p class="orm-headline__h3 orm-headline__h3__modal">
                  {{ i18n("contact/success/title") }}
                </p>
                <p>{{ i18n("contact/success/text") }}</p>
                <button class="orm-button__cta small" @click="closeModal()">
                  {{ i18n("general/close") }}
                </button>
              </div>
              <div v-if="contacterror">
                <p class="orm-headline__h3 orm-headline__h3__modal">
                  {{ i18n("contact/error/title") }}
                </p>
                <p>{{ i18n("contact/error/text") }}</p>
                <button class="orm-button__cta small" @click="closeModal()">
                  {{ i18n("general/close") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="orm-col-xs-12 orm-contactmodal__footer"></div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { defineComponent } from "vue";
import i18n from "@/mixins/mixins";
import store from "@/store";
import FormInput from "@/components/ui/INPUTS/FormInput.vue";
import FormTextarea from "@/components/ui/INPUTS/FormTextarea.vue";
import FormSelect from "@/components/ui/INPUTS/FormSelect.vue";
import { concatDateTimeZone } from "@/constants/formater";
import stripHTML from "@/mixins/mixins";

export default defineComponent({
  name: "modal-contact",
  emits: ["dismiss"],
  components: {
    FormInput,
    FormTextarea,
    FormSelect,
  },
  props: {
    contactcar: {
      type: Object,
      required: true,
    },
    type: {
      default: "carlist",
      required: false,
    },
    reservation: {
      required: false,
    },
  },
  mixins: [i18n, stripHTML],
  setup() {
    const clientdata = store.getters["general/GET_CLIENT_DATA"];

    return {
      clientdata,
    };
  },
  data() {
    return {
      env: process.env,
      contactsuccess: false,
      contacterror: false,
      /* eslint-disable */
      form: {
        salutation_id: "",
        title: "",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        street: "",
        zipcode: "",
        city: "",
        message: "",
        startstation_id: store.getters["userSession/GET_STATION"],
        planned_endstation_id: store.getters["userSession/GET_STATION_END"],
        planned_group_id: this.contactcar ? this.contactcar.id : "",
        startdate: "",
        planned_enddate: "",
      },
      reason_id: "1",
      message_extras: {
        subject: "",
        date: "",
        time: "",
        km: "",
        cargroup: "",
      },
      validate: {
        email: false,
        firstname: false,
        lastname: false,
      },
      errors: [],
      reasonsList: [
        {
          id: 1,
          reason: this.i18n("contact/form/reasonsList/1"),
        },
        {
          id: 2,
          reason: this.i18n("contact/form/reasonsList/2"),
        },
        {
          id: 3,
          reason: this.i18n("contact/form/reasonsList/3"),
        },
        {
          id: 4,
          reason: this.i18n("contact/form/reasonsList/4"),
        },
      ],
      stationSelected: null,
      stationSelectedEnd: store.getters["userSession/GET_STATION_END"],
      dsgvo: "",
    };
  },
  async mounted() {
    document.body.style.overflow = "hidden";
    const date = await store.getters["userSession/GET_RESERVATIONDATE"];
    const time = await store.getters["userSession/GET_RESERVATIONTIME"];

    const datetime = await concatDateTimeZone(date, time);
    this.stationSelected = this.reservation
      ? this.reservation.startstation
      : await store.getters["stations/GET_STATION_BY_ID"](
          store.getters["userSession/GET_STATION"]
        );
    this.form.startdate = datetime[0];
    this.form.planned_enddate = datetime[1];
    const dsgvotext = this.i18n("contact/form/dsgvo");
    this.dsgvo = dsgvotext.replace(
      "$dsgvo",
      store.getters["ORMgeneral/GET_PARAMETER"]("SINET_MAIL_PRIVACY_LINK")
    );

    const customer = await store.getters["customer/GET_CUSTOMER"];
    // console.log(customer);
    if (customer) {
      this.form.firstname = customer.customer.firstname;
      this.form.lastname = customer.customer.lastname;
      this.form.email = customer.customer.email;
      this.form.phone_number = customer.customer.phone_number;
    }

    if (this.reservation) {
      this.form.planned_group_id = this.reservation.planned_group.id;
      this.form.startstation_id = this.reservation.startstation.id;
      this.form.planned_endstation_id = this.reservation.planned_endstation.id;
      this.form.startdate = this.reservation.startdate;
      this.form.planned_enddate = this.reservation.planned_enddate;
    }

    // if (this.type == "contract_co") this.reason_id = 3;
  },
  unmounted() {
    if (this.type === "carlist" || this.type === "general") {
      document.body.style.overflow = "auto";
    }
  },
  methods: {
    closeModal() {
      this.$emit("dismiss");
    },
    async sumbit() {
      // console.log("submit");
      // console.log(this.reason_id);
      if (this.validateForm())
        if (!this.contactcar) {
          const details = this.switchMessage(this.reason_id);
          const resid = this.reservation
            ? this.i18n("customer/booking_list/bookingnr") +
              ": " +
              this.reservation.id +
              "\n | "
            : "";
          this.form.message =
            resid +
            this.i18n("contact/form/label/message") +
            " " +
            this.form.message +
            "\n | " +
            this.i18n("contact/form/label/reason") +
            "  " +
            this.reasonsList.find((x) => x.id == this.reason_id).reason +
            "\n | " +
            details;
        }
      // console.log(this.form);
      await store
        .dispatch("customer/SEND_CONTACT", this.form)
        .then((result) => {
          if (result.status == 201 || result.status == 200) {
            this.contactsuccess = true;
          } else {
            this.contacterror = true;
          }
        });
    },
    switchMessage(key) {
      console.log(key);
      switch (key) {
        case "1":
          return (
            this.i18n("contact/form/label/subject") +
            " " +
            this.message_extras.subject
          );
          break;
        case "2":
          return (
            this.i18n("contact/form/label/date") +
            " : " +
            this.message_extras.date +
            " | " +
            this.i18n("contact/form/label/time") +
            " : " +
            this.message_extras.time
          );
          break;
        case "3":
          return (
            this.i18n("contact/form/label/km") + " " + this.message_extras.km
          );
          break;
        case "4":
          return (
            this.i18n("contact/form/label/cargroup") +
            " " +
            this.message_extras.cargroup
          );
          break;
      }
    },
    validateForm() {
      this.errors = [];
      const pamenttype = store.getters["reservation/GET_PAYMENTTYPE"];

      for (const formfield in this.validate) {
        if (Object.hasOwnProperty.call(this.validate, formfield)) {
          let element = this.form[formfield];
          if (formfield == "payment_method_id") {
            element = this.form[formfield];
            if (store.getters["ORMgeneral/GET_MODULE"]("ConCardis") == "J") {
              if (
                !element &&
                Object.keys(store.getters["reservation/GET_PAYMENTTYPE"])[0] ===
                  process.env.VUE_APP_PAYMENT_OFFLINE
              ) {
                this.validate[formfield] = true;
                this.errors.push(formfield + " required.");
              } else {
                this.validate[formfield] = false;
              }
            } else {
              if (!element) {
                this.validate[formfield] = true;
                this.errors.push(formfield + " required.");
              } else {
                this.validate[formfield] = false;
              }
            }
          } else if (formfield == "email") {
            if (!this.validEmail(element)) {
              this.validate[formfield] = true;
              this.errors.push(formfield + " required.");
            } else {
              this.validate[formfield] = false;
            }
          } else if (!element) {
            this.validate[formfield] = true;
            this.errors.push(formfield + " required.");
          } else {
            this.validate[formfield] = false;
          }
        }
      }
      if (this.errors.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
});
</script>
