<template>
  <div v-click-outside="closeEvent">
    <div
      class="orm-stationselect text-left"
      :class="{ 'orm-stationselect--disabled': readonly }"
    >
      <div>
        <div
          class="orm-stationselect__select"
          @click="openEvent"
          v-if="selected"
        >
          <div>
            <strong
              class="orm-stationselect__select__textmiddle"
              :title="selected"
              >{{ i18n("general/stationtype/" + selected) }}</strong
            >
            <span class="orm-station__caret__icon"
              ><i class="fas fa-caret-down"></i
            ></span>
          </div>
        </div>
        <div v-else>
          <div
            class="orm-stationselect__select"
            @click="selectopen = !selectopen"
          >
            {{ i18n("general/chooseStation") }}
            <span class="orm-station__address__icon"
              ><i class="fas fa-map-marker-alt"></i
            ></span>
          </div>
        </div>
        <div class="orm-stationselect__options" v-if="selectopen">
          <div
            class="orm-stationselect__option"
            :class="{ selected: selected === station }"
            v-for="station in data"
            :selected="selected === station"
            :key="station"
            :value="station"
            @click="onChangeStation(station)"
          >
            <div>
              <strong
                class="orm-stationselect__select__textmiddle"
                :title="station"
                >{{ i18n("general/stationtype/" + station) }}</strong
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/mixins/mixins";
export default {
  mixins: [i18n],
  props: {
    data: Object,
    selected: String,
    type: String,
    readonly: { type: Boolean, default: false },
    viewType: String,
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      selectopen: false,
    };
  },
  emits: ["update:modelValue"],
  methods: {
    openEvent() {
      if (!this.readonly) {
        this.selectopen = !this.selectopen;
      }
    },
    closeEvent() {
      this.selectopen = false;
    },
    onChangeStation(station) {
      this.$emit("update:modelValue", station);
      this.selectopen = false;
    },
  },
};
</script>
