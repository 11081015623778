<template>
  <div
    class="orm-login-widget text-left"
    v-click-outside="handleClick"
    v-if="userdata"
  >
    <div class="orm-customer" v-if="userdata">
      <span class="orm-customer-icon--circle"
        ><span
          >{{ userdata.firstname.substring(0, 1)
          }}{{ userdata.lastname.substring(0, 1) }}</span
        ></span
      >
      <div class="orm-customer-name">
        {{ userdata.firstname }} <br />{{ userdata.lastname }}
      </div>
    </div>
    <div class="orm-customer-devider"></div>
    <div class="orm-customer-links">
      <a :href="env.VUE_APP_BASE_URL + env.VUE_APP_CUSTOMER_PATH + 'mydata'">{{
        i18n("customer/navigation/data")
      }}</a>

      <a
        :href="env.VUE_APP_BASE_URL + env.VUE_APP_CUSTOMER_PATH + 'bookings'"
        >{{ i18n("customer/navigation/bookings") }}</a
      >
      <a href="#" @click="this.$emit('showContact', false)">
        {{ i18n("customer/navigation/contact") }}
      </a>
      <!-- <router-link
        active-class="active"
        :to="{
          name: 'Contact',
        }"
        >{{ i18n("customer/navigation/contact") }}</router-link
      > -->
    </div>
    <div class="orm-customer-devider"></div>
    <button
      class="orm-btn--logout orm-login-widget-btnlistener"
      type=""
      @click="logout"
    >
      <i class="fa fa-sign-out"></i> {{ i18n("customer/login/button_logout") }}
    </button>
  </div>
</template>
<script>
import store from "@/store";
import i18n from "@/mixins/mixins";

export default {
  emits: ["getLogout", "showCustomerOverlay", "showContact"],
  mixins: [i18n],
  data() {
    return {
      env: process.env,
      userdata: null,
    };
  },
  async mounted() {
    setTimeout(async () => {
      this.userdata = await store.getters["customer/GET_CUSTOMER"].customer;
    }, 100);
  },
  methods: {
    logout() {
      store.dispatch("userSession/LOGOUT").then(() => {
        store.dispatch("customer/DELETE_LOGIN_CUSTOMER").then(() => {
          this.$emit("getLogout", true);
        });
      });
      window.location.href = process.env.VUE_APP_BASE_URL;
    },
    handleClick(ev) {
      console.log(ev.target.classList);
      if (!ev.target.classList.contains("orm-login-widget-btnlistener")) {
        // console.log(ev.target.classList);
        this.$emit("showCustomerOverlay", false);
      }
    },
  },
};
</script>
