<template>
  <div class="orm-row orm-mr-md-16">
    <div class="orm-col-xs-12">
      <h3 class="orm-headline__h3 orm-headline__h3__modal orm-mt-32 orm-mb-8">
        {{ i18n("step2/tarifs") }}
      </h3>

      <div
        class="orm-list__bold orm-my-8"
        v-for="tarif in reservation.invoice_positions.mainRates"
        :key="tarif"
      >
        <div class="orm-row">
          <div class="orm-col-xs-8">
            <span v-if="tarif.rate == 'KM'">
              {{ tarif.amount }}&nbsp;{{ tarif.description }}
              <span class="orm-small"
                >({{ convertCurrency(tarif.long_price) }}/ KM )</span
              >
              <div
                class="orm-small"
                v-if="tarif.description2"
                v-html="tarif.description2"
              ></div>
            </span>
            <span v-else
              ><span v-if="tarif.amount > 1">{{ tarif.amount }}x </span
              >{{ tarif.description }}
              <span class="orm-small"
                >({{ convertCurrency(tarif.long_price) }}/{{
                  tarif.unit
                }})</span
              >
            </span>
          </div>
          <div class="orm-col-xs-4 orm-price__right">
            {{ convertCurrency(tarif.total_long_price) }}
          </div>
        </div>
        <div v-if="tarif.position == 1" class="orm-row orm-my-8">
          <div class="orm-col-xs-8">
            <span v-if="!tarif.all_km_inclusive"
              >{{ tarif.km_free }}
              {{ i18n("step4/freeKilometers") }}
            </span>
            <span v-else>
              {{ i18n("step4/unlimited") }}
              {{ i18n("step4/freeKilometers") }}
            </span>
          </div>
          <div class="orm-col-xs-4 orm-price__right">
            {{ i18n("step4/inclusive_long") }}
          </div>
        </div>
      </div>

      <div v-if="reservation.invoice_positions.deliveryPickupRates.length > 0">
        <h3 class="orm-headline__h3 orm-headline__h3__modal orm-mt-32 orm-mb-8">
          {{ i18n("step2/deliverypickup/title") }}
        </h3>
        <div
          class="orm-list__bold orm-my-8"
          v-for="rates in reservation.invoice_positions.deliveryPickupRates"
          :key="rates"
        >
          <div class="orm-row">
            <div class="orm-col-xs-8">
              {{ rates.description }}: {{ rates.amount }}
              {{ rates.unit }}
            </div>
            <div class="orm-col-xs-4 orm-price__right">
              {{ convertCurrency(rates.total_long_price) }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="reservation.invoice_positions.voucherRates.length > 0">
        <h3 class="orm-headline__h3 orm-headline__h3__modal orm-mt-32 orm-mb-8">
          {{ i18n("step2/voucher/title") }}
        </h3>
        <div
          class="orm-list__bold orm-my-8"
          v-for="rates in reservation.invoice_positions.voucherRates"
          :key="rates"
        >
          <div class="orm-row">
            <div class="orm-col-xs-8">
              {{ rates.description }}: {{ rates.amount }}
              {{ rates.unit }}
              <br />
              <small>{{ rates.description2 }}</small>
            </div>
            <div class="orm-col-xs-4 orm-price__right">
              {{ convertCurrency(rates.total_long_price) }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="reservation.invoice_positions.subRates.length > 0">
        <h3 class="orm-headline__h3 orm-headline__h3__modal orm-mt-32 orm-mb-8">
          {{ i18n("step4/extras") }}
        </h3>
        <div
          v-for="subrate in reservation.invoice_positions.subRates"
          :key="subrate"
          class="orm-list__bold orm-my-8"
        >
          <div class="orm-row">
            <div class="orm-col-xs-8">
              <span v-if="subrate.amount > 1">{{ subrate.amount }}x </span>
              {{ subrate.description }}
              <span class="orm-small" v-if="subrate.unit !== 'X'"
                >{{ subrate.description2 }} ({{
                  convertCurrency(subrate.long_price)
                }}/{{ subrate.unit }})</span
              >
            </div>
            <div class="orm-col-xs-4 orm-price__right">
              {{ convertCurrency(subrate.total_long_price) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { convertCurrency } from "@/constants/formater";
import stripHTML from "@/mixins/mixins";
import i18n from "@/mixins/mixins";
export default {
  name: "reservation_tarifs",
  mixins: [i18n, stripHTML],
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      convertCurrency,
    };
  },
};
</script>
