<template>
  <div v-click-outside="closeEvent">
    <div
      class="orm-stationselect text-left"
      v-if="showSelect"
      :class="{ 'orm-stationselect--disabled': readonly }"
    >
      <div>
        <div
          class="orm-stationselect__select"
          @click="openEvent"
          v-if="stationSelectedObject"
        >
          <div>
            <strong :title="stationSelectedObject.name">{{
              stationSelectedObject.name
            }}</strong>
          </div>
          <small>{{ stationSelectedObject.street }}</small>
          <span class="orm-station__address__icon"
            ><i class="fas fa-map-marker-alt"></i
          ></span>
        </div>
        <div v-else>
          <div
            class="orm-stationselect__select"
            @click="selectopen = !selectopen"
          >
            {{ i18n("general/chooseStation") }}
            <span class="orm-station__address__icon"
              ><i class="fas fa-map-marker-alt"></i
            ></span>
          </div>
        </div>
        <div class="orm-stationselect__options" v-if="selectopen">
          <div
            class="orm-stationselect__option"
            v-if="type == 'end'"
            @click="onChangeStation('startend')"
          >
            {{ i18n("general/likeStartstation") }}
          </div>
          <div
            class="orm-stationselect__option"
            v-for="station in data"
            :selected="stationSelected == station.id"
            :key="station"
            :value="station.id"
            @click="onChangeStation(station.id)"
          >
            <div>
              <strong :title="station.name">{{ station.name }}</strong>
            </div>
            <small>{{ station.street }}</small>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="type == 'end' && !showSelect && !readonly"
      class="orm-stationselect__endstation__unselected"
      :class="{
        'orm-lmap': viewType == 'mapModule',
      }"
      @click="showSelect = true"
    >
      <div class="orm-fakecheck"></div>
      <p class="orm-stationselect__label">
        {{ i18n("general/returnStation") }}
      </p>
    </div>
  </div>
</template>

<script lang="">
import { defineComponent } from "vue";
import store from "@/store";
import { i18n } from "@/constants/formater";

export default defineComponent({
  name: "stationpicker",
  props: {
    data: Object,
    enabledStations: null,
    selectedstation: null,
    type: String,
    readonly: { type: Boolean, default: false },
    viewType: String,
  },
  emits: ["update:selectedstation"],
  data() {
    return {
      filteredStations: [],
      startstation: "",
      stationSelected: String,
      stationSelectedObject: Object,
      showSelect: false,
      selectopen: false,
    };
  },
  setup() {
    return {
      store,
      i18n,
    };
  },
  mounted() {
    if (this.data)
      this.filteredStations = JSON.parse(JSON.stringify(this.data));
    if (this.type == "start") {
      this.stationSelected = store.getters["userSession/GET_STATION"];

      this.stationSelectedObject = store.getters["stations/GET_STATION_BY_ID"](
        store.getters["userSession/GET_STATION"]
      );

      store.watch(
        () => store.getters["userSession/GET_STATION"],
        () => {
          // Callback here
          this.stationSelected = store.getters["userSession/GET_STATION"];
          this.stationSelectedObject = store.getters[
            "stations/GET_STATION_BY_ID"
          ](store.getters["userSession/GET_STATION"]);
        }
      );
      this.showSelect = true;
    } else {
      this.startstation = store.getters["userSession/GET_STATION"];
      this.stationSelected = store.getters["userSession/GET_STATION_END"];

      this.stationSelectedObject = store.getters["stations/GET_STATION_BY_ID"](
        store.getters["userSession/GET_STATION_END"]
      );
      store.watch(
        () => store.getters["userSession/GET_STATION"],
        () => {
          this.startstation = store.getters["userSession/GET_STATION"];
        }
      );
      store.watch(
        () => store.getters["userSession/GET_STATION_END"],
        () => {
          // Callback here

          this.stationSelected = store.getters["userSession/GET_STATION_END"];
          this.stationSelectedObject = store.getters[
            "stations/GET_STATION_BY_ID"
          ](store.getters["userSession/GET_STATION_END"]);

          if (this.startstation != this.stationSelected) {
            this.showSelect = true;
          } else {
            this.showSelect = false;
          }
        }
      );

      if (
        this.stationSelected !== undefined &&
        this.stationSelected !== store.getters["userSession/GET_STATION"]
      ) {
        this.showSelect = true;
      }
    }

    if (this.enabledStations) {
      this.filteredStations = [];
      this.data.forEach((element) => {
        this.isEnabled(element);
      });
      // console.log(this.stationSelected);
      if (
        !this.filteredStations.find(
          (x) => x.station_id === this.stationSelected
        )
      ) {
        this.stationSelected = this.filteredStations[0].id;
        store.dispatch("userSession/SET_STATION", this.filteredStations[0].id);
        this.stationSelectedObject = this.filteredStations[0];
        this.$emit("update:selectedstation", this.stationSelected);
      }
    }
  },
  methods: {
    isEnabled(station) {
      if (this.enabledStations.find((x) => x === station.id)) {
        this.filteredStations.push(station);
      }
    },
    openEvent() {
      if (!this.readonly) {
        this.selectopen = !this.selectopen;
      }
    },
    onChangeStation(event) {
      if (event == "startend") {
        this.showSelect = false;
      }
      this.selectopen = false;
      if (this.stationSelected == event) return;

      this.stationSelected = event;
      this.stationSelectedObject = store.getters["stations/GET_STATION_BY_ID"](
        event
      );

      if (this.type == "start") {
        store.dispatch("userSession/SET_STATION", event);
      } else {
        if (this.startstation == this.stationSelected) {
          store.dispatch("userSession/SET_STATION_END", "startend");
        } else {
          store.dispatch("userSession/SET_STATION_END", event);
        }
      }
      this.$emit("update:selectedstation", this.stationSelected);
    },
    closeEvent() {
      this.selectopen = false;
    },
  },
});
</script>
